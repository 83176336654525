:focus {
    outline: none;
}
input[type="time"]::-webkit-clear-button {
    display: none;
}
input[type="time"]::-ms-clear {
    display: none;
}

/* ----- ONE STYLE CLASS ----- */
.display-flex {
    display: flex;
}
.display-none {
    display: none;
}
.flex-1 {
    flex: 1;
}
.flex-0-2 {
    flex: 1;
}
.flex-0-3 {
    flex: 1;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-justify-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.user-select-none {
    user-select: none;
}

.z-index-auto {
    z-index: auto;
}

.z-index-10 {
    z-index: 10;
}
.height-100 {
    height: 100%;
}
.width-100 {
    width: 100%;
}
.height-250-px {
    max-height: 250px;
    overflow: auto;
}
.overflow-unset {
    overflow: unset;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.select-z-index {
    z-index: 120;
}
.flex-unset {
    -webkit-flex-wrap: unset;
    -ms-flex-wrap: unset;
    flex-wrap: unset;
}
.position-absolute {
    position: absolute;
}
.position-relative {
    position: relative;
}
.outline-none {
    outline: none
}
.word-break-all {
    word-break: break-all;
}
.white-space-pre {
    white-space: pre-line;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.width-250 {
    width: 250px;
}
.cursor-text {
    cursor: text!important;
}
.display-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#textarea-addons .emoji-mart-search {
    display: none;
}

.cards-node-masc .settings-item {
    position: static;
}

.sidebar-edit .settings-item {
    position: relative;
}

.editor-cont-wrap .settings-item {
    z-index: auto!important;
}

.control-disabled {
    cursor: default;
    color: #d5d5d5;
}
.control-disabled:hover {
    cursor: default;
    color: #d5d5d5;
}
.diagram-node-wrap .node-control-animation {
    z-index: 150;
}

.dn-content-item {
    position: static;
    word-break: break-all;
    white-space: pre-line;
}

.w-slider.in-diagram {
    position: static;
}
.w-slider.in-diagram.sidebar-edit {
    position: relative;
}

.w-slider-mask {
    position: static;
}
.sidebar-edit .w-slider-mask {
    position: relative;
}

.w-slide {
    position: static;
}
.sidebar-edit .w-slide {
    position: relative;
}
.drag-point-w {
    left: auto;
    top: 0;
    right: -20px;
    bottom: 0;
}

.cards-node-masc .drag-point-w {
    right: -42px;
}

.button-bot-builder-text-input {
    background: transparent;
    border: none;
    outline: none;
    text-align: center;
}

.ava-block {
    display: none;
    justify-content: flex-end;
    bottom: -5px;
    right: 0px;
    padding: 0px;
}

.ava-green {
    width: 10px;
    height: 10px;
    float:  none;
    border-radius: 20px;
    background-color: #38c75d;
    margin-top: 10px;
}

.ava-orange {
    margin-top: 10px;
    float:  none;
}

.ava-grey-right {
    width: 10px;
    height: 10px;
    float: right;
    border-radius: 20px;
    background-color: #999;
}

.error-info-client, .error-bot-message {
    display: flex;
    justify-content: space-between;
}
.error-message.align-right {
    display: flex;
    text-align: right;
    max-width: 110px;
}
.error-message.align-left-small {
    display: flex;
    max-width: 90px;
}
.error-message.margin-bottom {
    align-items: center;
    margin-bottom: 5px;
}

.error-message.margin-left {
    margin-left: 5px;
    line-height: 18px;
    max-width: none;
}

.cp-info-block.chat-container {
    height: 600px;
}

/* ----- AUTH -----*/
.forgot-link {
    margin-top: -5px;
    margin-bottom: 10px;
}
/* ----- CAMPAIGNS ----- */

.icon-image{
    margin-right: 10px;
    width: 40px;
    max-width: 40px;
    border-radius: 3px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.col-1 {
    margin-right: 15px;
}
.tab-margin {
    /*margin-right: 3px;*/
}
.links-campagins {
    margin-bottom: 10px;
}
.cp-name-div {
    width: 165px;
}
.cp-name-link {
    max-width: 165px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.hover-div {
    opacity: 0;
}
.bg-preview-wrap:hover .hover-div {
    opacity: 1;
}
.w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    margin: auto;
    /* width: 100%; */
    height: 100%;
    z-index: -100;
}
.diagram-node-wrap {
    background-image: linear-gradient(180deg, #00316b, #00316b), linear-gradient(225deg, #006ef1, #00316b);
}
.digram-node-header {
    background: transparent;
}
.diagram-node-content {
    position: static;
    background: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 10px 10px 2px;
}
.add-card-btn:hover .plus-line {
    background-color: rgb(55, 167, 229);
}
.dn-message-w {
    max-height: none;
}
.disable-button {
    pointer-events: none;
    opacity: 0.4
}
.video-in-node {
    height: 150px;
    width: 100%;
    background: #000;
}

/* ----- CREATE WEB CHAT ----- */
.diagram-node-wrap .node-control-animation,
.settings-item .node-control-animation,
.dn-media-w .node-control-animation {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform: translate3d(-10px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 0.3s ease;
}
.diagram-node-wrap:hover .node-control-animation,
.settings-item:hover .node-control-animation,
.dn-media-w:hover .node-control-animation {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.hover-popup-w {
    margin-top: 36px;
}
.diagram-node-content .hover-popup-animation {
    opacity: 0;
    -webkit-transform: translate3d(0px, 0px, 0px) scale3d(0.5, 0.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform: translate3d(0px, 0px, 0px) scale3d(0.5, 0.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 0.3s ease;
}
.diagram-node-content:hover .hover-popup-animation {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.toggle-w .tooltip-animation {
    opacity: 0;
    transition: opacity 0.1s ease;
}
.toggle-w:hover .tooltip-animation {
    opacity: 1;
    display: block;
    transition: opacity 0.1s ease;
}

.node-sidebar-close {
    transform: translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: all 0.3s ease;
}
.node-sidebar-open {
    transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)!important;
    transform-style: preserve-3d;
    transition: all 0.3s ease;
}

.nav-menu-overlay-close {
    max-height: 0px;
    height: 0px;
    transition: all 400ms ease 0s;
}
.nav-menu-overlay-open {
    height: 100vh;
    max-height: 100vh;
    transition: all 400ms ease 0s;
}

.nav-menu-close {
    transform: translateY(-301px);
    transition: transform 400ms ease 0s;
}
.nav-menu-open {
    transform: translateY(0px);
    transition: transform 400ms ease 0s;
}

.switch-handle-off {
    transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.switch-handle-on {
    transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

/* ----- MENU, HEADER ----- */

.full-background {
    background-color: #f8faff
}
.nav-item-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 240px;
}
.nav-list {
    display: block;
}
.link-animation {
    display: block;
}
@media (min-width: 991px) {
    .link-animation {
        -webkit-animation: show-tooltip 0.3s ease;
        animation: show-tooltip 0.3s ease;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        transform-style: preserve-3d;
        transition: all 150ms ease;
        display: none;
    }

    .nav-link.w-inline-block:hover .link-animation {
        display: block;
    }
}

@keyframes show-tooltip {
    0% {
        -webkit-transform: translate3d(0px, 5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform: translate3d(0px, 5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        opacity: 1;
    }
}


/* ----- CUSTOM SCROLL BAR -----*/
.hidden-scrollbar {
    overflow: hidden;
}
.hidden-scrollbar:hover {
    overflow-y: overlay;
}
*::-webkit-scrollbar-track
{
    background-color: transparent;
}

*::-webkit-scrollbar
{
    width: 6px;
    height: 6px;
    background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
    background-color: rgba(192, 197, 207, 0.67);
    border-radius: 3px;
}

.custom-textarea {
    resize: vertical;
}


/* ----- CUSTOM MAIN STYLE -----*/

.left-float-menu.w-tab-menu.top-0 {
    top: 0px;
}

/* ------ EDITOR -----*/
.chat-window-custom {
    height: 100%;
    overflow: unset;
}

.no-chats {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    font-style: italic;
    color: #9f9f9c;
}

.editor-wrap-custom {
    display: block;
    height: 100%;
    width: 100%;
    padding: 0;
    overflow: hidden;
    background-color: #525252;
    background-image: radial-gradient(#5b5b5b 10%, transparent 20%);
    background-position: 4px 3px, 13px 13px;
    background-size: 13px 13px;
}
.smooth-editor-draggable {
    text-rendering: optimizeLegibility;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased
}
.scrollable-editor-workspace {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /*height: fit-content;*/
    /*min-height: 100%;*/
    height: 20000px;
    /*width: fit-content;*/
    /*min-width: 100%;*/
    width: 20000px;
    display: block;
    /*padding: 15px;*/
    margin: 15px;
}

.scrollable-background-workspace {
    height: 100%;
    width: fit-content;
}

.block-node {
    width: fit-content;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
}

.svg-layout {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: 70px;
    padding-left: 50px;
}
.svg-delete-connector {
    top: 0;
    right: 0;
    bottom: 0
}

/* ----- LEADS ----- */

.active-page {
    color: #1d96f4;
}
.ul-container {
    margin: 0;
    list-style-type: none;
}
.ul-container a {
    outline: none;
}
/* ----- ----- */

.filter-drop.w-dropdown {
    z-index: auto;
}
.dropdown-list.w-dropdown-list {
    z-index: 900;
}
.switch-wrap.active-custom-switch {
    border-color: #34a250;
    background-color: #38c75d;
}

/* ----- CHATS ----- */

.operator-name {
    max-width: 140px;
    text-overflow: ellipsis;
    /* flex-wrap: wrap; */
    /* display: flex; */
    white-space: nowrap;
    overflow: hidden;
}
.chat-avatar-icon-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.reassign.operator-name {
    text-overflow: inherit;
    max-width: 240px;
}

.page-content.chats {
    background: #fff;
    height: 0%;
}
.chat-wrap.custom, .left-sidebar-w.custom {
    height: 100%;
}
.right-sidebar-w.custom {
    overflow: auto;
}
.chat-avatar-icon {
    opacity: 1;
}
.chat-avatar-animation {
    transition: opacity 0.6s ease;
}
.chat-avatar:hover .chat-avatar-icon {
    /*opacity: 0;*/
}
.chat-avatar:hover .chat-item-check{
    /*opacity: 1;*/
}
.chat-message-formatted {
    white-space: pre-line;
    word-break: break-word;
}
.chat-message-item img {
    max-width: 370px;
}
.video-in-message {
    height: 350px;
    width: 90%;
    background: #000;
}
.unread-message-container {
    height: 100%;
    display: flex;
    align-items: center;
}
.unread-message {
    background-color: #d5eaf7;
}
.unread-message-indicator {
    position: absolute;
    right: 15px;
    top: 28px;
    width: 10px;
    height: 10px;
    background: #83cbf3;
    border-radius: 5px;
}
.online-indicator {
    position: absolute;
    top: 17px;
    right: -10px;
    width: 10px;
    height: 10px;
    background: #31c921;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: white;
}
.online-indicator.chat-header {
    left: -12px;
    top: 5px;
}
.chat-last-message-preview.ellipsis {
    text-overflow: ellipsis;
    /* margin-right: 18px; */
    max-width: 192px;
    white-space: nowrap;
}
img.iframe-in-message {
    cursor: pointer;
}
.message-file-img,
.margin-attachment-bottom:not(:last-child) {
    margin-bottom: 7px;
}
.message-count {
    position: absolute;
    right: 12px;
    top: 28px;
    border-radius: 10px;
    background: #37a7e5;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: white;
    font-weight: 600;
}
.message-count.disable-notification {
    background: rgba(0, 0, 0, 0.35);
}
.disabled-check {
    background-color: #dadada;
}

.files-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
}
.files-list.no-bottom {
    padding-bottom: 0;
}
.file-row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.file-name {
    color: #6f6f6f;
    text-decoration: none;
    font-size: 12px;
}
.file-name:hover {
    text-decoration: underline;
    color: #404040;
}
.files-list .delete-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10px;
    height: 10px;
    margin-top: 2px;
    margin-right: 2px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
}
.files-list .close-line-1 {
    width: 10px;
    height: 2px;
    background-color: #ddd;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.files-list .close-line-1.rotate {
    position: relative;
    left: 0px;
    top: 2px;
    width: 10px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.gray-button {
    background: darkgray;
}

[contenteditable] {
    padding:12px;
}
[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: #888;
}

.no-messages-chat {
    display: flex;
    justify-content: center;
    font-style: italic;
    color: #999999;
}
.disabled-text {
    color: lightgray;
}
.disabled-text:hover {
    color: lightgray;
    cursor: auto;
}
.quick-replies-container {
    max-height: 300px;
    overflow: auto;
}
.bot-campaign-list {
    max-width: 100px;
    max-height: 150px;
    position: absolute;
    top: 0;
    left: 30px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15)
}
.bot-campaign-one {
    cursor: pointer;
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
}
.bot-campaign-one:hover {
    color: #009aff;
}
.margin-attachment-bottom:not(:last-child) {
    margin-bottom: 8px
}




.components-info-w.w--open {
    display: block;
}
.col-6 .settings-select.narrow-select, .col-12 .settings-select.narrow-select  {
    padding: 0;
}

@media (min-width: 1180px) {
    .cp-url {
        width: 190px;
        text-overflow: ellipsis;
    }
}

@media (min-width: 991px) {
    .cp-url {
        width: 160px;
        text-overflow: ellipsis;
    }
}

@media (max-width: 991px) {
    .chat-avatar-icon-img {
        width: 26px;
        height: 26px;
    }
    .avaibility-block2 .chat-avatar-icon-img {
        top: -1px;
        position: absolute;
    }
    .avaibility-block2 .notification-alarm {
        margin-top: 0px;
        height: 22px;
    }
    .main-wrapper {
        flex-direction: column;
        overflow: hidden;
    }
    .w--nav-overlay-open {
        display: block;
    }
    .page-container {
        width: 100%;
        overflow: auto;
    }
    .col-6.select-block {
        padding-right: 26px;
    }
    .chat-last-message-preview.ellipsis {
        max-width: 120px;
    }
    .avaibility-block2 {
        right: 5px;
        left: auto;
        top: 40px;
        bottom: auto;
    }
}


@media (max-width: 767px) {
    .switch-handle {
        width: 48%;
        height: auto;
    }
    .switch-handle-on {
        transform: translate3d(110%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

@media (max-width: 520px) {
    .chat-last-message-preview.ellipsis {
        max-width: 90px;
    }
}

/* ----- CUSTOM SELECT ----- */
.select-arrow-down {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #000;
}

.multi-dropdown-toggle {
    flex-wrap: wrap;
    height: auto;
    padding: 8px 18px 5px 15px;
}

.user-tag-w.additional-margin {
    margin-bottom: 3px;
}

/* ----- LOADER ----- */
.loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
}
.fade-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #0e0e0e73;
    z-index: 3000;
}
.loader-overlay.small {
    height: 100%;
}
.loader-overlay.micro-loader {
    height: 10%;
}
.loader-overlay.list-loader {
    height: 50vh;
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader.micro-loader {
    width: 2em;
    height: 2em;
}
.loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 134, 229, 0.07);
    border-right: 1.1em solid rgba(0, 134, 229, 0.07);
    border-bottom: 1.1em solid rgba(0, 134, 229, 0.07);
    border-left: 1.1em solid #009aff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
.loader.micro-loader {
    border-top: 0.2em solid rgba(0, 134, 229, 0.07);
    border-right: 0.2em solid rgba(0, 134, 229, 0.07);
    border-bottom: 0.2em solid rgba(0, 134, 229, 0.07);
    border-left: 0.2em solid #009aff;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* ----- BUTTON WITH SPINNER ----- */

.button-loader-wrap .default-spinner-color {
    color: #ffffff;
}

.button-loader-wrap .dark-spinner {
    color: #333333;
}

.btn-send-loader {
    position: relative;
    color: transparent!important;
    transition: none;
    cursor: auto;
    background-color: #38c75d;
}

.button-loader-wrap {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
.spinner-loader-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2px;
}
.button-loader-wrap .sk-fade-in {
    -webkit-animation: none;
    animation: none;
}
.button-loader-wrap .ball-clip-rotate > div {
    -webkit-animation: rotate-without-scale 0.75s 0s linear infinite;
    animation: rotate-without-scale 0.75s 0s linear infinite;
    width: 20px;
    height: 20px;
}

@-webkit-keyframes rotate-without-scale {
    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1);
    }
}

.success-message {
    margin-top: 10px;
    display: block;
    background-color: rgba(207, 246, 212, 0.9);
}

/* ----- MODAL ----- */
.popup-w.w--open, .popup-w-2.w--open {
    display: block;
    opacity: 1;
    outline: none;
}

.close-line-1.back-color {
    background-color: rgb(221, 221, 221);
}

.delete-btn:hover .close-line-1.back-color {
    background-color: rgb(153, 153, 153);
}

/* ----- DISABLABLE ----- */
.disablable-block {
    position: relative;
}
.disablable-block.block-disabled {
}
.disablable-block .overlay {
    z-index: 50;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.5;
    background-color: #fff;
}
.disablable-block.hide-block {
    display: none!important;
}

/* ---- SEARCH ----- */
.close-btn-search {
    justify-content: end;
    align-items: end;
    top: 16px;
}
.closed-search-form {
    position: relative;
}
.search-results {
    background-color: #e8f6ff;
}

.show-more-message {
    color: #739bdc;
    display: flex;
    justify-content: center;
    text-decoration: underline;
}
.search-select-message {
    -webkit-animation: select-message 2s linear;
    animation: select-message 2s linear;
}
.unread-message-divider {
    background: #c6e8ff;
    height: 0;
    padding: 0;
    border-radius: 4px;
}
.unread-message-to-user {
    background-color: #e8f6ff;
}

@-webkit-keyframes select-message {
    0% {
        background-color: #e8f6ff;
    }
    100% {
        background-color: transparent;
    }
}

/* ------ DATEPICKER ----- */
.react-datepicker__month-container {
    padding: 8px;
}
.react-datepicker__triangle {
    border-bottom-color: #fff!important;
}
.react-datepicker__header {
    background-color: #fff!important;
    border: 0!important;
    font-weight: 600;
    padding: 0!important;
}
.react-datepicker__month {
    margin: 0!important;
}
.react-datepicker__day-name, .react-datepicker__day {
    line-height: 1.8rem!important;
    margin: 0 3px!important;
    font-size: 15px!important;
    color: #45496e!important;
}
.react-datepicker__day--selected {
    color: #fff!important;
    background-color: #147cbd!important;
}
.custom-header {
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 3px;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #dbdcdd;
    color: #45496e;
}
.custom-header .arrow {
    border-left: 2px solid #5c7080;
    border-bottom: 2px solid #5c7080;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    height: 8px;
    width: 8px;
    border-radius: 3px;
    transform: rotate(45deg);
}
.custom-header .arrow.arrow-dec {
    transform: rotate(45deg);
}
.custom-header .arrow.arrow-inc {
    transform: rotate(225deg);
}
.custom-header .date-part {
    display: flex;
    align-items: center;
}
.custom-header .buttons-calendar-arrows {
    margin-left: 5px;
}
.custom-header .date-change-button {
    margin-left: 5px;
    height: 4px;
    width: 4px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-radius: 3px;
}
.custom-header .button-up {
    border-top: 5px solid transparent;
    border-bottom: 5px solid #5c7080;
    margin-bottom: 2px;
}
.custom-header .button-down {
    border-bottom: 5px solid transparent;
    border-top: 5px solid #5c7080;
}
.visitor-details-text.wrap {
    word-wrap: break-word;
}

/* ------ TAGS ----- */

/* Styles for React Tags*/
div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    border-radius: 2px;
    width: 100%;
    display: inline-block;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 13px;
    display: inline-block;
    padding: 3px;
    margin: 2px 2px;
    border-radius: 7px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* ------ WIDGET SETTINGS ----- */

.dropdown-arrow-up {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(180deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: transform 500ms;
}

.dropdown-arrow-down {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    transition: transform 500ms;
}

.display-settings {
    opacity: 1;
    display: block;
}

.hide-settings{
    opacity: 0;
    display: none;
}

.color-picker-container {
    position: absolute;
    left: 1px;
    top: 40px;
    z-index: 1000;
}
.no-outline:focus {
    outline: none;
}

.content-white-block.new-col-4 {
    top: 0;
    padding: 20px 0;
}

.content-white-block.new-col-4 .div-block-6 {
    padding-left: 20px;
    padding-right: 20px;
}
.chat-preview-w.big-width {
    margin: 0 auto;
    width: 395px;
}
.chat-preview-w.web-chat iframe {
    min-height: 621px;
}
.operator-online-ind {
    position: absolute;
    bottom: 0;
    right: 0;
}

/* ----- STYLE FOR REACT CSS TRANSITION GROUP ----- */
.tabs-on-appear {
    opacity: 0.01;
}
.tabs-on-appear.tabs-on-appear-active {
    opacity: 1;
    transition: opacity 300ms ease;
}

.tabs-on-enter {
    opacity: 0;
}

.tabs-on-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
}

.tabs-on-enter-done {
    opacity: 1;
}

.tabs-on-exit {
    opacity: 1;
}

.tabs-on-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
}

/* ----- BOT -----*/
.diagram-node-content {
    min-height: 35px;
}

.dn-media-w.image-type {
    padding: 0;
}

/* ----- ANALYTICS ----- */
.no-data {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    font-style: italic;
    color: #9f9f9c;
    margin: 20px 100px 40px;
}

.chat-preview-w.landbot-preview {
    min-height: 0;
}

/* ----- TARIFFS ----- */
.page-content.normal-content.overflow-visible {
    overflow: visible;
}
.toggle-period-month-animate {
    transform: translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.toggle-period-year-animate {
    transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}
.features-row {
    display: flex;
    flex-direction: row;
}
.features-label {
}
.features-empty {
}
.features-top-block {
    display: flex;
    flex-direction: row;
}
.features-values-block {
    display: flex;
    flex-direction: row;
}
.transparent-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    color: transparent;
    font-size: 15px;
    font-weight: 500;
    cursor: auto;
    height: 38px;
}
@media screen and (max-width: 991px) {
    .close-btn-search {
        justify-content: start;
        top: 14px;
    }
    .close-line-1,
    .close-line-1.rotate {
        width: 15px;
    }

    .features-empty {
        display: none;
    }
    .features-row {
        display: flex;
        flex-direction: column;
    }
    .features-label {
        flex: 1!important;
    }
    .features-values-block {
        flex: 1!important;
    }
    .price-bg-block {
        top: 53px;
    }
    .transparent-button {
        height: 30px;
    }
    .col-4 {
        width: 32%;
    }
    .col-6.half-w {
        position: relative;
        width: 49%;
    }
    
}
/* ----- BILLING ----- */
.arrows-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-right: 5px;
}
.arrow-up {
    margin-bottom: 1px;
    border: 5px solid transparent;
    border-bottom: 5px solid #999999;
}
.arrow-up.active {
    border-bottom: 5px solid #333;
}
.arrow-down {
    margin-top: 1px;
    border: 5px solid transparent;
    border-top: 5px solid #999999;
}
.arrow-down.active {
    border-top: 5px solid #333;
}
.arrows-block:hover.arrow-up {
    border-bottom: 5px solid #333;
}
.arrows-block:hover.arrow-down {
    border-top: 5px solid #333;
}
.white-background {
    background: transparent!important;
}

/* ---- MEETINGS ---- */

.choose-item:first-child {
    margin-left: 0;
}

.operator-avatar-meeting {
    height: inherit;
    margin-top: 0px;
    border-radius: 50%;
}
@media screen and (max-width: 767px) {
    .operator-avatar-meeting {
        margin-top: -2px;
    }
}

.edit-tools-w {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-style: solid solid none;
    border-width: 1px;
    border-color: #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.vert-line {
    width: 1px;
    background-color: #ddd;
}

.control-btn.more-size-2 {
    width: 35px;
    height: 35px;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #333;
    font-size: 14px;
}
.emoji-icon {
    font-size: 17px;
}

.avatar-add-div.open.full-background {
    background-size: cover;
}

.avatar-add-wrap .avatar-control-elements {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: flex-end;
    opacity: 1;
}
.meetings-loader-overlay {
    padding-top: 50px;
}

.toggle-control-btn.black-icon {
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #333;
    font-size: 16px;
}

.control-btn.no-indent {
    width: 35px;
    height: 35px;
    margin-right: 0px;
    margin-left: 0px;
}
.email-template-custom-property {
    height: 200px;
    overflow: auto;
}

@media screen and (max-width: 767px) {
    .emoji-icon {
        font-size: 15px;
    }
    .control-btn.no-indent {
        width: 30px;
        height: 30px;
    }
    .control-btn.more-size-2 {
        width: 30px;
        height: 30px;
        font-size: 13px;
        line-height: 20px;
    }
    .toggle-control-btn.black-icon {
        font-size: 14px;
    }
}

#email-body-template .DraftEditor-root {
    display: block;
    width: 100%;
    vertical-align: middle;

    min-height: 110px;
    height: auto;
    margin-bottom: 10px;
    padding: 6px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f2f6f9;
    color: #333;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;

    overflow: auto;

}

#email-body-template .DraftEditor-editorContainer {
    height: 100%;
}

#email-body-template .public-DraftEditor-content[contenteditable=true] {
    height: 100%;
    padding: 0
}

@media screen and (max-width: 991px) {
    #email-body-template .DraftEditor-root {
        font-size: 13px;
    }
}

@media screen and (max-width: 767px) {
    #email-body-template .DraftEditor-root {
        height: 32px;
        font-size: 13px;
        line-height: 16px;
    }
}

.schedule-select-time-format {
    min-width: 50px;
    margin-left: -2px!important;
    height: 36px;
}

.time-picker.align-center {
    display: flex;
    align-items: center;
}
.add-big-margin {
    margin-right: 13px!important;
}

.time-block.no-left-margin {
    margin-left: 0px;
}
.w-select.time-format.no-left-margin {
    margin-left: 0px;
}

/* ----- 404 ----- */

.page-404 {
    height: 275px;
    align-items: center;
    justify-content: center;
}

.title-404 {
    font-size: 70px;
    line-height: 70px;
    /* margin: 30px 0; */
    margin-bottom: 20px;
}

.subtitle-404 {
    font-size: 29px;
    line-height: 30px;
    /* margin: 30px 0; */
    margin-bottom: 20px;
}

/* ----- PANEL NEW DESIGN ----- */
.main-wrapper {
    /*height: auto;*/
}

.main-navbar {
    min-width: 72px;
    position: fixed;
    left: 0px;
    top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /*height: 100%;*/
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-right: 1px solid #eee;
    background-color: #292f4c;
}

.nav-link {
    padding: 15px;
    border-radius: 2px;
}

.nav-link:hover {
    background-color: #444961;
}

.nav-link.w--current {
    background-color: #0085ff;
    color: #fff;
}

.nav-links-w {
    padding: 10px;
}

.logo-icon {
    width: 40px;
}

.brand {
    margin-top: 9px;
    margin-bottom: 9px;
}

.chat-header-w {
    background-color: #f5f5f5;
}

.left-sidebar-w {
    background-color: #f5f5f5;
}

.right-sidebar-w {
    background-color: #f5f5f5;
}

.chat-item.new-chat,
.chat-item.new-chat:hover {
    background-color: #e6e6e6;
}

.chat-item {
    background-color: #fff;
}

.chat-item:hover {
    background-color: #f5f5f5;
}

.node-settings-input {
    height: 36px;
    margin-bottom: 10px;
    padding: 6px 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.node-settings-input.search-field {
    height: 36px;
    margin-bottom: 0px;
    padding-left: 30px;
    background-color: #fdfdfd;
}

.node-settings-input.search-field:hover {
    border-color: #888888;
    background-color: #fcfcfc;
}

.node-settings-input:hover {
    border-color: #888888;
}

.search-field:hover {
    border-color: #888888;
}

.node-settings-input:focus {
    border-color: #0085ff;
}

.search-field:focus {
    border-color: #0085ff;
}

.node-settings-input.search-field:focus {
    border-color: #0085ff;
}

.filter-drop-tog {
    border: 1px solid #0085ff;
    color: #0085ff;
    border-radius: 3px;
    background-color: #fff;
    font-weight: 500;
}

.button {
    padding: 8px 13px;
    border-radius: 3px;
    background-color: #00c875;
    -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.button:hover {
    background-color: #01a05e;
}

.account-tog {
    display: block;
    padding: 13px;
    border-radius: 3px;
    background-color: #0085ff;
    opacity: 1;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #fff;
    font-size: 20px;
    line-height: 10px;
    cursor: pointer;
    font-weight: 500;
}

.account-tog:hover {
    background-color: #0672d6;
    color: #fff;
}

.account-tog.with-icon {
    padding: 7px;
    border: 1px solid #0085ff;
    background-color: #fff;
}

.account-tog.with-icon:hover {
    background-color: #eef7ff;
}

.send-text-area {
    min-height: 80px;
    margin-bottom: 0px;
    padding-right: 130px;
    border: 1px solid #8a8a8a;
    border-radius: 3px;
    background-color: #fdfdfd;
}

.send-text-area:hover {
    border-color: #161616;
}

.send-text-area:focus {
    border-color: #0085ff;
}

.a-tab-link {
    padding: 11px 25px 10px;
    border: 1px solid #0085ff;
    background-color: #fff;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #0085ff;
    cursor: pointer;
    font-weight: 500;
}

.step-btn {
    border: 1px solid #0085ff;
    color: #0085ff;
    background-color: #fff;
    font-weight: 500;
}

.blue-button {
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
    background-color: #0085ff;
    -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    transition: all 150ms cubic-bezier(.23, 1, .32, 1);
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    font-weight: 500;
}

.blue-button:hover {
    background-color: #0672d6;
}

.step-btn.w--current,
.step-btn.w--current:hover {
    border-color: #0085ff;
    background-color: #0085ff;
    color: #fff;
}

.step-btn:hover {
    background-color: #eef7ff;
}

.a-tab-link.w--current,
.a-tab-link.w--current:hover {
    border-color: #0085ff;
    background-color: #0085ff;
    color: #fff;
}

.a-tab-link:hover {
    background-color: #eef7ff;
}

.switch-wrap {
    padding: 2px;
    border: 0px;
}

.switch-wrap.active-custom-switch {
    background-color: rgb(0, 200, 117);
}

.settings-select {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    background-color: #fff;
}

.settings-select:hover {
    border-color: #161616;
    background-color: #fdfdfd;
}

.filter-drop-tog.like-btn,
.like-btn.w-button {
    background-color: #00c875;
    border-color: #00c875;
    border-radius: 3px;
    font-weight: 500;
}

.filter-drop-tog.like-btn:hover,
.like-btn.w-button:hover {
    background-color: #01a05e;
}

.action-btn {
    margin-right: 5px;
    margin-left: 5px;
    padding: 7px 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 1px solid #fdab3d;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #fdab3d;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.action-btn:hover {
    background-color: #fff3e3;
    border: 1px solid #fdab3d;
    color: #fdab3d;
}

.action-btn.delete-btn {
    width: auto;
    height: auto;
    border-color: #e2445c;
    color: #e2445c;
}

.action-btn.delete-btn:hover {
    border-color: #e33131;
    background-color: #ffecef;
    color: #e33131;
}

.action-btn.edit-btn {
    border-color: #0085ff;
    color: #0085ff;
}

.action-btn.edit-btn:hover {
    background-color: #eef7ff;
}

.table-row.table-header {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #292f4c;
}

.details-header {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.details-header.right-indent {
    color: #292f4c;
}

.pagination-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.pagination-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-btn {
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 1px solid #8a8a8a;
    border-radius: 3px;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.page-btn:hover {
    border-color: #161616;
    color: #0085ff;
}

.page-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 18px;
    margin-right: 2px;
    margin-left: 2px;
    padding: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.page-link:hover {
    color: #0085ff;
}

.filter-drop-tog.gray {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-color: #8a8a8a;
    color: #333;
}

.filter-drop.pages-number {
    margin-right: 0px;
}

.filter-drop-tog.gray:hover {
    border-color: #161616;
    background-color: transparent;
    color: #0085ff;
}

.arrow-line {
    background-color: #0485ff;
}

.top-arr-line {
    background-color: #0485ff;
}

.dropdown-toggle {
    background-color: #fff;
}

.dropdown-toggle.w--open {
    border-color: #0085ff;
}

.dropdown-toggle:hover {
    border-color: #161616;
}

.user-tag-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 10px;
    padding: 4px 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #8a8a8a;
    border-radius: 3px;
    color: #161616;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    background: #fff;
}

.user-tag-w:hover {
    background-color: #f5f5f5;
}

.search-items {
    background-color: #fff;
}

.top-menu-w {
    background-color: #fff;
}

.page-content.normal-content {
    background-color: #f5f5f5;
}

.component-block {
    background-color: #292f4c;
}

.digram-node-header {
    background: #6a3fe6;
}

.dn-button {
    background-color: #0485ff;
}

.dn-drag-point {
    background-color: #02c875;
}

.dn-drag-point:hover {
    background-color: #01a05e;
}

.textarea {
    min-height: 110px;
    margin-bottom: 0px;
    padding: 6px 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

.dn-media-w,
.input-div.like-textarea {
    border: 1px solid #8a8a8a;
    background-color: #fff;
    color: #333;
}

.card-slide {
    background-color: #f5f5f5;
    border: 1px solid #a6a6a6;
}

.badge.badge-btn {
    background-color: #e2445c;
}

.tariff-chose-button-2 {
    background-color: #00c875;
}

.top-plan-w {
    background-color: #f5f5f5;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
}

.visitors-info.current-plan-info {
    margin-bottom: 10px;
    border-color: #8a8a8a;
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
}

.badge.badge-success {
    background-color: #00c875;
}

.badge.badge-danger {
    background-color: #e2445c;
}

.node-settings-input-2 {
    height: 36px;
    margin-bottom: 10px;
    padding: 6px 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    color: #333;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    background: #fff;
}

.node-settings-input-2:hover {
    border-color: #888888;
}

.node-settings-input-2:focus {
    border-color: #0085ff;
}

.details-header.right-indent.grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #161616;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
}

.choose-type-w {
    display: flex;
    margin-right: auto;
    grid-column-gap: 6px;
    grid-template-rows: auto;
    border: 0;
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
}

.choose-item {
    display: block;
    margin-left: 0px;
    padding: 5px;
    border: 1px solid #0085ff;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #0085ff;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
}

.choose-item:hover {
    background-color: rgba(0, 154, 255, 0.1);
}

.choose-item.checked {
    background-color: #009aff;
    color: #fff;
}

.sidebar-tab-cont {
    background: #fff;
}

.hide {
    display: none;
}

.template-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-weight: 400;
}

.choose-typing-emulation {
    display: flex;
}

.choose-typing-emulation .choose-item:first-child {
    margin-right: 10px;
}

.table-padding {
    padding: 20px;
}

.chat-header-w, .left-sidebar-w, .right-sidebar-w {
    background-color: #ffffff;
}

.chat-item.new-chat, .chat-item.new-chat:hover {
    background-color: #f5f5f5;
}

.dropdown-link {
    font-family: Circular,sans-serif;
}

.new-message-wrapper {
    padding: 15px 15px 17px;
}

.node-settings-input {
    border: 1px solid #000;
}

.css-ya7e0s-container {
    border: 1px solid #000 !important;
}

.page-content.normal-content {
    background-color: #ffffff;
}

.content-white-block {
    border: 1px solid #eee;
}

.campaign-preview-item {
    border: 1px solid #eee;
}

.campaign-preview-item .cp-avatar-div .cp-avatar {
    border-radius: 0px;
    width: 45px;
    height: 45px;
}

.left-tab-link {
    border: 1px solid #eee;
}

.a-tab-link:last-child {
    border-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.a-tab-link:first-child {
    border-right: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.сp-info-block-campaigns .cp-info-item {
    margin-left: 0;
    flex: initial;
    flex-direction: row;
    display: flex;
    align-items: end;
    gap: 10px;
}

.сp-info-block-campaigns .cp-info-item:last-child {
    flex-direction: column;
    align-items: center;
}

.plan-label {
    margin-top: 20px;
}

.plan-label .text-link:last-child {
    margin-left: 5px;
}

.page-container {
    margin-left: 72px;
}

.no-chats {
    flex-direction: column;
}

.no-chats img {
    margin-bottom: 10px;
}

.create-campaign-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    gap: 80px;
    padding: 0px 40px;
}

.create-campaign-container .create-campaign-header {
    margin-top: 15px;
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    line-height: 32px;
}

.create-campaign-container .create-campaign-element-wrapper {
    display: flex;
    gap: 120px;
}

.create-campaign-container .create-campaign-element-wrapper .create-campaign-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.create-campaign-container .create-campaign-element-wrapper .create-campaign-element img {
    max-width: 100px;
}

.create-campaign-container .create-campaign-element-wrapper .create-campaign-element a {
    justify-content: center;
    gap: 5px;
}

.multi-dropdown-toggle {
    border: 1px solid #000;
}

.multi-dropdown-toggle:hover {
    border-color: #888;
}

.arrow-block {
    margin-right: 13px;
    margin-left: 13px;
}

.color-item {
    box-shadow: 0px 0px 2px #898989;
}

@media screen and (max-width: 991px) {
    .page-container {
        margin-left: 0px;
    }

    .ava-block {
        bottom: -10px;
        right: -10px;
    }

    .ava-green {
        width: 6px;
        height: 6px;
        margin-top: 0px;    
    }

    .ava-orange {
        margin-top: 0px;
    }

    .ava-grey-right {
        width: 6px;
        height: 6px;     
    }

    .logo-icon {
        width: 30px;
    }

    .nav-link {
        padding: 8px 15px;
    }

    .account-tog.with-icon {
        margin-right: 5px;
        padding: 6px;
    }

    .node-settings-input.search-field {
        height: 33px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 25px;
        background-position: 5px 50%;
        background-size: 14px;
        font-size: 13px;
    }

    .account-tog {
        padding: 10px;
        font-size: 16px;
    }

    .button {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
    }

    .filter-drop-tog {
        padding: 7px 10px;
        font-size: 13px;
        line-height: 16px;
    }

    .position-relative {
        margin-left: 0px;
    }

    .page-title {
        margin-right: 10px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        font-size: 18px;
        line-height: 22px;
    }

    .main-navbar {
        min-height: auto;
        height: auto;
        min-width: 100%;
    }

    .create-campaign-container .create-campaign-element-wrapper {
        gap: 40px;
    }
}

@media screen and (max-width: 767px) {
    .create-campaign-container .create-campaign-element-wrapper {
        flex-direction: column;
    }

    .create-campaign-container .create-campaign-element-wrapper .create-campaign-element {
        flex-direction: row;
    }

    .create-campaign-container .create-campaign-element-wrapper .create-campaign-element img {
        max-width: 80px;
    }

    .ava-grey-right {
        position: relative;
        top: 2px;
    }

    .nav-menu {
        background-color: #292f4c;
    }

    .nav-links-w {
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .nav-links-div {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .nav-link {
        width: 33%;
        padding: 12px 20px;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .nav-link-icon {
        width: 18px;
    }

    .nav-link-name {
        margin-left: 10px;
        font-size: 13px;
    }

    .ava-block {
        position: relative;
        left: 0%;
        right: auto;
        height: 100%;
        margin-left: 10px;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .logo-icon {
        width: 30px;
    }

    .node-settings-input.search-field {
        height: 29px;
        margin-bottom: 5px;
    }

    .filter-drop-tog {
        padding-top: 7px;
        padding-bottom: 6px;
        padding-left: 7px;
        font-size: 12px;
        line-height: 14px;
    }

    .page-title {
        margin-right: 10px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 479px) {
    .create-campaign-container {
        margin-bottom: 40px;
    }

    .no-chats img {
        margin-top: 20px;
    }

    .nav-link {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .logo-icon {
        width: 32px;
    }

    .nav-link-icon {
        width: 20px;
    }

    .page-title {
        margin-right: 10px;
        font-size: 16px;
        line-height: 18px;
    }

    .top-right-menu {
        margin: auto 0px auto auto;
        padding-bottom: 5px;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
}