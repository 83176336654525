
/* ==========================================================================
   Start of custom Webflow CSS
   ========================================================================== */
.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('./images/check.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url(./images/youtube-placeholder.2b05e7d68d.svg);
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}


body {
  font-family: Circular, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

.main-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.main-wrapper.center-align {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.main-navbar {
  min-width: 72px;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*height: 100%;*/
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-right: 1px solid #eee;
  background-color: #292f4c;
}

.nav-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border-radius: 2px;
}

.nav-link:hover {
  background-color: #444961;
}

.nav-link.w--current {
  background-color: #0085ff;
  color: #fff;
}

.nav-links-div {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-links-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 10px;
}

.logo-icon {
  width: 40px;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  margin-bottom: 9px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.nav-link-icon {
  font-family: Fontawesome, sans-serif;
  font-size: 24px;
}

.nav-link-name {
  position: absolute;
  left: 85%;
  top: auto;
  right: auto;
  bottom: auto;
  width: auto;
  padding: 6px 10px 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.15);
  color: #1f1d1d;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  text-transform: none;
}

.left-sidebar-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 280px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #eee;
  background-color: #f8faff;
}

.search-field {
  height: 38px;
  margin-bottom: 0px;
  padding-left: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fdfdfd;
  background-image: url('./images/magnifying-glass-icon.svg');
  background-position: 8px 50%;
  background-size: 18px;
  background-repeat: no-repeat;
  color: #1f1d1d;
}

.search-field:focus {
  border-color: #009aff;
}

.search-field::-webkit-input-placeholder {
  color: #999;
}

.search-field:-ms-input-placeholder {
  color: #999;
}

.search-field::-ms-input-placeholder {
  color: #999;
}

.search-field::placeholder {
  color: #999;
}

.search-form-block {
  margin-right: 30px;
  margin-bottom: 0px;
}

.search-form-w {
  padding: 0px;
}

.page-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.filers-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}

.filers-w.left-indent {
  margin-left: 10px;
}

.sidebar-header {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.filter-drop-tog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 10px 7px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #009aff;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  font-size: 14px;
  cursor: pointer;
}

.filter-drop-tog:hover {
  border-color: #009aff;
  background-color: rgba(0, 154, 255, 0.1);
  color: #009aff;
}

.filter-drop-tog.w--open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: rgba(0, 154, 255, 0.1);
}

.filter-drop-tog.awesome-font {
  width: 40px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Fontawesome, sans-serif;
}

.filter-drop-tog.like-btn {
  border-color: #38c75d;
  background-color: #38c75d;
  color: #fff;
  font-size: 14px;
}

.filter-drop-tog.like-btn:hover {
  background-color: #26b94c;
}

.filter-drop-tog.like-btn.norm-paddings {
  padding-top: 7px;
  padding-left: 10px;
}

.filter-drop-tog.add-padding {
  padding-bottom: 8px;
}

.filter-drop-tog.no-arrow {
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 10px;
}

.filter-drop-tog.no-arrow:hover {
  background-color: #e5f5ff;
}

.filter-drop-tog.gray {
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-color: #ddd;
  color: #333;
}

.filter-drop-tog.gray:hover {
  border-color: #009aff;
  background-color: transparent;
  color: #009aff;
}

.filter-drop-tog.gray.w--open {
  background-color: transparent;
  color: #009aff;
}

.filter-drop-tog.color-toggle {
  width: 100%;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #009aff;
  font-family: Fontawesome, sans-serif;
}

.filter-drop-tog.chat-toggle {
  width: 100%;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0);
  background-color: transparent;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 24px;
}

.filter-drop-tog.chat-toggle:hover {
  border-color: #fff;
}

.filter-drop {
  display: block;
  width: auto;
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
}

.filter-drop.no-indent {
  margin-right: 0px;
}

.filter-drop.left-indent {
  margin-right: 0px;
  margin-left: 20px;
}

.filter-drop.like-btn {
  width: 48%;
  margin-right: 0px;
  margin-left: auto;
}

.filter-drop.no-indent-2 {
  margin-right: 0px;
  margin-left: 15px;
}

.filter-drop.left-indent-2 {
  margin-right: 0px;
  margin-left: 15px;
}

.filter-drop.no-indent-3 {
  margin-right: 0px;
  margin-left: 0px;
}

.filter-drop.pages-number {
  margin-right: 0px;
}

.filter-drop.left-align {
  margin-left: 0px;
}

.filter-drop.more-color {
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: 0px;
}

.filter-drop.chat-dropdown {
  width: 35px;
  height: 35px;
  margin-right: 0px;
  margin-left: 0px;
}

.drop-icon {
  position: static;
  margin: auto 0px auto 10px;
}

.drop-icon.hide-icon {
  display: none;
}

.dropdown-list.w--open {
  position: absolute;
  z-index: 200;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.dropdown-list.right-float-list {
  right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.dropdown-list.move-list {
  position: absolute;
  left: auto;
  top: 25px;
  right: 0%;
  bottom: auto;
  z-index: 1000;
}

.dropdown-list.move-list.w--open {
  z-index: 2000;
}

.dropdown-list.add-padding {
  z-index: 1000;
  padding-right: 15px;
  padding-left: 15px;
}

.dropdown-list.add-padding.add-max-h.w--open {
  overflow: auto;
  max-height: 250px;
  padding: 0px;
}

.dropdown-list.no-style {
  left: auto;
  top: auto;
  right: -65%;
  bottom: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  box-shadow: none;
}

.dropdown-list.add-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.dropdown-link {
  margin-left: 0px;
  padding: 5px 20px 5px 15px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: Proximanova, Roboto, Circular, sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  cursor: pointer;
}

.dropdown-link:hover {
  color: #009aff;
}

.dropdown-link.w--current {
  color: #009aff;
}

.dropdown-link.link-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-width: 165px;
  padding-right: 15px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  text-decoration: none;
}

.dropdown-link.link-block:hover {
  color: #009aff;
}

.dropdown-link.red-link {
  color: #e33131;
}

.dropdown-link.red-link:hover {
  color: #c00d0d;
}

.top-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 59px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-shadow: none;
}

.filter-drop-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.page-title {
  margin-right: 30px;
  color: #1f1d1d;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.page-title.no-indent {
  margin-right: 0px;
}

.page-title.align-center {
  margin-right: 0px;
  margin-bottom: 20px;
  color: #38c75d;
  line-height: 28px;
  text-align: center;
}

.top-menu-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-left: 15px;
  padding-right: 30px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #eee;
  background-color: #f8faff;
}

.top-nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: Fontawesome, sans-serif;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.top-nav-link:hover {
  background-color: #dff1ff;
}

.top-nav-link-icon {
  width: 20px;
  font-size: 18px;
}

.account-tog {
  display: block;
  padding: 13px;
  border-radius: 3px;
  background-color: #009aff;
  opacity: 1;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
  font-size: 20px;
  line-height: 10px;
  cursor: pointer;
}

.account-tog:hover {
  color: #f19e17;
}

.account-tog.w--open {
  opacity: 1;
}

.account-tog.with-icon {
  padding: 8px;
  background-color: transparent;
}

.account-tog.with-icon:hover {
  background-color: #dff1ff;
}

.account-dropdown {
  margin-left: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.dropdown-list-2 {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.dropdown-list-2.w--open {
  top: auto;
  background-color: #eee;
}

.top-control-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.sort-w {
  display: block;
  padding: 10px;
}

.chats-list-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #eee;
}

.page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.page-content.normal-content {
  overflow: auto;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f8faff;
}

.right-sidebar-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #eee;
  background-color: #f8faff;
}

.chat-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-window {
  display: block;
  overflow: auto;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #f8faff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  text-decoration: none;
  cursor: pointer;
}

.chat-item:hover {
  background-color: #fff;
}

.chat-item.active-chat {
  background-color: #fff;
}

.chat-item.new-chat {
  background-color: #c6e8ff;
}

.chat-avatar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #37a7e5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.chat-avatar:hover {
  /*border: 2px solid #3d4a6b;*/
}

.chat-avatar.closed-chat {
  background-color: #f19e17;
}

.chat-avatar.blocked-chat {
  background-color: #e33131;
}

.chat-avatar.pending-chat {
  background-color: #6a3fe7;
}

.chat-avatar.opened-chat {
  background-color: #38c75d;
}

.chat-item-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #999;
  line-height: 16px;
}

.chat-avatar-icon {
  font-family: Fontawesome, sans-serif;
}

.chat-item-check {
  position: absolute;
  opacity: 0;
  color: #3d4a6b;
}

.chat-item-check.visible-icon {
  opacity: 1;
}

.identity-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.user-identity {
  color: #1f1d1d;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
}

.user-identity.user-link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  text-decoration: none;
  cursor: pointer;
}

.user-identity.user-link:hover {
  color: #009aff;
}

.user-location {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
}

.user-location.no-indent {
  margin-bottom: 0px;
}

.chat-last-message-preview {
  overflow: hidden;
  height: 16px;
  font-size: 14px;
}

.chat-status-number {
  font-size: 10px;
}

.status-link-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-status-icon {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: #37a7e5;
}

.chat-status-icon.pending-status {
  background-color: #6a3fe7;
}

.chat-status-icon.opened-status {
  background-color: #38c75d;
}

.chat-status-icon.blocked-status {
  background-color: #e33131;
}

.chat-status-icon.closed-status {
  background-color: #f19e17;
}

.chat-header-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #f8faff;
}

.new-message-wrapper {
  padding: 15px 15px 25px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.new-message-form-block {
  margin-bottom: 0px;
}

.new-message-form {
  position: relative;
}

.send-text-area {
  min-height: 80px;
  margin-bottom: 0px;
  padding-right: 130px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fdfdfd;
}

.send-text-area.min-h {
  min-height: 60px;
  padding: 0px 65px 0px 0px;
  border-style: none;
  background-color: transparent;
}

.text-area-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.typing-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.typing-text {
  color: #999;
}

.typing-text.visitor-typing {
  margin-left: 5px;
  font-style: italic;
  font-weight: 300;
}

.new-message-actions {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 20px;
  min-width: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.new-message-actions.new-position {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.new-message-actions.new-position-bot {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.new-mess-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 20px;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
}

.new-mess-action:hover {
  color: #000;
}

.new-mess-action.min-size {
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 20px;
}

.new-mess-action.position-rel {
  position: relative;
}

.chat-user-name {
  margin-bottom: 5px;
  color: #1f1d1d;
  font-size: 20px;
  font-weight: 500;
}

.chat-campaign-name {
  color: #999;
}

.chat-header-div-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.location-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  padding-top: 3px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.pin-icon {
  font-family: Fontawesome, sans-serif;
}

.link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #999;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

.day-divider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #eee;
}

.divider-text {
  margin-right: 20px;
  margin-left: 20px;
  color: #999;
  font-size: 12px;
}

.chat-message-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.chat-avatar-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #37a7e5;
  color: #fff;
  font-size: 18px;
}

.chat-avatar-2.closed-chat {
  background-color: #f19e17;
}

.chat-avatar-2.blocked-chat {
  background-color: #e33131;
}

.chat-avatar-2.pending-chat {
  background-color: #6a3fe7;
}

.chat-avatar-2.opened-chat {
  background-color: #38c75d;
}

.chat-date {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #999;
  font-size: 12px;
  line-height: 16px;
}

.chat-date.time-text {
  margin-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-message-p {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
}

.visitor-info-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.visitor-info-field {
  height: auto;
  margin-bottom: 0px;
  padding: 5px 0px;
  border: 1px none #000;
  background-color: transparent;
  line-height: 18px;
}

.visitor-info-field::-webkit-input-placeholder {
  color: #000;
}

.visitor-info-field:-ms-input-placeholder {
  color: #000;
}

.visitor-info-field::-ms-input-placeholder {
  color: #000;
}

.visitor-info-field::placeholder {
  color: #000;
}

.visitor-info-field.visitor-name {
  font-size: 20px;
  font-weight: 500;
}

.visitor-info-field.textarea-info {
  min-height: 55px;
}

.visitor-details-item {
  margin-bottom: 5px;
  padding: 5px 0px;
}

.visitor-details-w {
  margin-top: 15px;
  padding: 0px;
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #fff;
}

.visitor-details-header-w {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.visitor-details-label {
  margin-bottom: 3px;
  color: #999;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.visitor-details-text {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
}

.visitor-details-info {
  padding: 10px 15px;
}

.top-nav-left-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.a-tab-link {
  padding: 11px 25px 10px;
  border: 1px solid #009aff;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  cursor: pointer;
}

.a-tab-link:hover {
  background-color: rgba(0, 154, 255, 0.1);
  color: #009aff;
}

.a-tab-link.w--current {
  border-color: #009aff;
  background-color: #009aff;
  color: #fff;
}

.a-tab-link.middle-tab-link {
  margin-right: -1px;
  margin-left: -1px;
}

.a-tab-link.grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.a-tab-link.grow.w--current {
  background-color: #009aff;
}

.a-tabs-menu {
  display: block;
  margin-bottom: 20px;
  border-radius: 3px;
}

.a-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.a-tabs.top-indent {
  margin-top: 30px;
}

.a-tabs-content {
  overflow: visible;
  width: 100%;
}

.campaign-preview-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
}

.campaigns-preview-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cp-avatar-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cp-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.cp-info-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.cp-name-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.cp-name-link {
  margin-bottom: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.cp-name-link:hover {
  text-decoration: underline;
}

.cp-url {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #999;
  text-decoration: underline;
  cursor: pointer;
}

.cp-url:hover {
  text-decoration: none;
}

.cp-control-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.dt-icon {
  width: 18px;
}

.cp-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.cp-info-item {
  margin-left: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cp-info-item.border-left {
  border-left: 1px solid #ddd;
}

.cp-info-count {
  margin-top: 5px;
  font-size: 24px;
  font-weight: 500;
}

.cp-info-count.orange {
  color: #f19e17;
}

.cp-info-count.violet {
  color: #6a3fe7;
}

.cp-info-count.blue {
  color: #006ef1;
}

.cp-info-count.green {
  color: #26b94c;
}

.cp-info-header {
  font-size: 15px;
}

.cp-info-header-icon {
  margin-right: 5px;
  font-family: Fontawesome, sans-serif;
  color: #333;
  font-size: 18px;
}

.cp-info-header-icon._1 {
  margin-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
}

.cp-info {
  font-size: 14px;
  word-break: break-word;
}

.cp-info.bottom-indent {
  margin-bottom: 5px;
}

.switch-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 47px;
  height: 25px;
  padding: 1px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 50px;
  background-color: #eee;
  -webkit-transition: all 100ms cubic-bezier(.175, .885, .32, 1.275);
  transition: all 100ms cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer;
}

.switch-wrap.active-switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-color: #34a250;
  background-color: #38c75d;
}

.switch-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.switch-text-wrap {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 2px;
  padding-left: 2px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.switch-text-wrap.small-text {
  font-size: 12px;
  text-transform: none;
}

.switch-yes {
  opacity: 0;
}

.switch-handle {
  position: relative;
  z-index: 10;
  display: block;
  width: 50%;
  height: 100%;
  margin-bottom: 0px;
  float: left;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.nav-link-icon {
  width: 22px;
  height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.awesome-icon {
  font-family: Fontawesome, sans-serif;
  line-height: 18px;
}

.awesome-icon.big-icon {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.awesome-icon.fix-w {
  display: inline-block;
  width: 15px;
  text-align: center;
}

.awesome-icon.check-icon {
  margin-left: 5px;
  color: #0889dd;
}

.awesome-icon.check-icon.hide {
  display: none;
}

.awesome-icon.big-icon {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.awesome-icon.check-icon {
  margin-left: 5px;
  color: #0889dd;
}

.awesome-icon.check-icon.hide {
  display: none;
}

.step-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #009aff;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.step-btn:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.step-btn:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.step-btn.w--current {
  border-color: #009aff;
  background-color: #009aff;
  color: #fff;
}

.step-btn.add-field {
  width: 48%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.step-btn.add-field-full {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.step-btn.add-row {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: 26px;
}

.step-btn.with-border {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.step-btn.with-border.inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.step-btn.red-btn {
  border-color: #e33131;
  color: #e33131;
}

.step-btn.red-btn:hover {
  background-color: #fceaea;
}

.step-btn.solid-bg {
  background-color: #009aff!important;
  color: #fff;
}

.step-btn.solid-bg:hover {
  background-color: #0889dd!important;
}

.step-btn.add-indent {
  margin-left: 15px;
}

.step-number {
  margin-right: 5px;
  font-weight: 500;
}

.step-btns-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.step-btns-w.no-bottom-indent {
  margin-bottom: 0px;
}

.arrow-line {
  position: relative;
  width: 12px;
  height: 2px;
  border-radius: 2px;
  background-color: #ddd;
}

.arrow-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-arr-line {
  position: absolute;
  left: auto;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 2px;
  border-radius: 2px;
  background-color: #ddd;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.top-arr-line.bottom-line {
  top: 2px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.content-white-block {
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
}

.content-white-block.new-col-4 {
  position: -webkit-sticky;
  position: sticky;
  top: 89px;
  width: 415px;
  max-width: 450px;
  margin-left: 20px;
}

.content-white-block.new-col-4.relative-position {
  position: relative;
  top: auto;
}

.content-white-block.grow-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-white-block.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.content-white-block.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-white-block.table-container {
  min-height: 50px;
  padding: 0px;
}

.content-white-block.no-wide {
  width: 95%;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
}

.content-white-block.table-container-2 {
  min-height: 50px;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.content-white-block.top-indent {
  margin-top: 20px;
  margin-bottom: 0px;
}

.content-white-block.center-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.no-wide-block {
  width: 95%;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  border-radius: 3px;
  background-color: #38c75d;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.button:hover {
  background-color: #26b94c;
}

.button:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.button.add-indent {
  margin-bottom: 15px;
  color: #fff;
}

.button.add-big-indent {
  margin-bottom: 20px;
  color: #fff;
}

.button.left-indent {
  margin-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.button.full-w {
  width: 100%;
  text-align: center;
}

.button.more-h {
  height: 40px;
}

.button.right-float {
  display: block;
  margin-right: 0px;
  margin-left: auto;
}

.button.right-float2 {
  display: block;
  margin-right: 0px;
  margin-left: auto;
  float: right;
}

.button.right-indent {
  margin-right: 15px;
}

.button.bottom-indent {
  margin-bottom: 30px;
}

.block-heading {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.form-block {
  margin-bottom: 0px;
}

.input {
  height: 38px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fdfdfd;
  color: #1f1d1d;
}

.input:focus {
  border-color: #009aff;
}

.input::-webkit-input-placeholder {
  color: #999;
}

.input:-ms-input-placeholder {
  color: #999;
}

.input::-ms-input-placeholder {
  color: #999;
}

.input::placeholder {
  color: #999;
}

.wrapper {
  margin-right: -1%;
}

.template-preview {
  display: inline-block;
  width: 24%;
  min-height: 100px;
  min-width: 300px;
  margin-right: 1%;
  margin-bottom: 1%;
  padding: 15px;
  border-radius: 3px;
  background-color: #c6e8ff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.template-preview:hover {
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.field-draggable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #006ef1;
  border-radius: 3px;
  background-color: #006ef1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 12px;
  cursor: move;
}

.field-draggable:hover {
  background-color: #0066e0;
}

.style-dd-toggle {
  width: 100%;
  padding: 12px 40px 12px 10px;
  border-radius: 3px;
  background-color: #006ef1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.style-dd-toggle.w--open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.minicolors-wrap {
  position: relative;
}

.minicolors-wrap.half-width {
  width: 50%;
}

.style-input {
  width: 40px;
  height: 25px;
  margin-top: 4px;
  margin-right: 5px;
  margin-bottom: 4px;
  padding: 0px;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 16px;
}

.editor-tab-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 20px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid none solid solid;
  border-width: 4px 1px 1px;
  border-color: hsla(0, 0%, 87.8%, 0) #e0e0e0 #e0e0e0;
  background-color: #f9f9f9;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #033156;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.editor-tab-link:hover {
  background-color: #fff;
}

.editor-tab-link.w--current {
  border-top-color: #006ef1;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  background-color: #fff;
  color: #006ef1;
}

.bar-dot-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.popup-container {
  display: block;
  overflow: hidden;
  width: 95%;
  max-width: 900px;
  min-height: 50px;
  margin: 50px auto;
  border-radius: 3px;
  background-color: #eee;
}

.popup-container.popup-small-container {
  max-width: 450px;
}

.workspace-center-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #072d4c;
}

.sidebar-tab-cont {
  overflow: auto;
  max-height: 90vh;
  min-height: 100px;
  padding: 15px 15px 50px;
}

.sidebar-tab-cont.settings-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: block;
  height: 100%;
  max-height: none;
  background-color: #fff;
  -webkit-transform: translate(-100%, 0px);
  -ms-transform: translate(-100%, 0px);
  transform: translate(-100%, 0px);
}

.sidebar-tab-cont.drop {
  overflow: visible;
}

.sidebar-tab-cont.lb {
  padding-right: 10px;
  padding-left: 10px;
}

.switch-div-2 {
  position: relative;
  width: 100%;
  height: 100%;
}

.editor-sidebar-wrap {
  position: relative;
  width: 300px;
  min-width: 100px;
  border-right: 1px solid #eee;
}

.workspace-url-div {
  margin-left: 150px;
  padding: 5px 5px 5px 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.workspace-top-div {
  padding: 6px 7px 6px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #6d7382;
}

.powered-by {
  position: absolute;
  left: 10px;
  bottom: 10px;
  opacity: 1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 12px;
}

.powered-by:hover {
  opacity: 0.7;
}

.item-hover {
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 110, 241, 0);
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.item-hover:hover {
  border-color: #006ef1;
}

.editor-form {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.component-btn-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 3px;
  font-family: Fontawesome, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
}

.styles-dd-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.popup-content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-field-2 {
  height: 40px;
  margin-bottom: 7px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: #f9f9f9;
  font-size: 13px;
}

.text-field-2:active {
  border-color: #007fff;
}

.text-field-2:focus {
  border-color: #007fff;
}

.text-field-2.color-field {
  padding-left: 40px;
}

.text-field-2.color-field.no-margin {
  margin-bottom: 0px;
}

.text-field-2.add-right-indent {
  margin-right: 0px;
  margin-bottom: 0px;
}

.text-field-2.upload-input {
  height: 34px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px 0px 0px 5px;
  font-size: 12px;
}

.text-field-2.upload-input.round {
  border-radius: 5px;
}

.popup-header {
  font-size: 16px;
  font-weight: 700;
}

.style-dropdown {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.style-dropdown:hover {
  border-color: #006ef1;
}

.range-slider-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 10px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sidebar-tab-pane {
  background-color: #fff;
}

.popup-header-div {
  position: relative;
  padding: 20px 25px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
}

.editor-workspace-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: block;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('./images/Frame-5-2.svg');
  background-position: -10px -10px;
  background-size: 26px;
  opacity: 0.08;
}

.positions-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.positions-row.middle-row {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.workspace-top-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.insert-location-element {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #006ef1;
  background-color: rgba(0, 110, 241, 0.4);
  color: #fff;
  list-style-type: none;
}

.range-slider-knob {
  position: absolute;
  top: -3px;
  right: -8px;
  bottom: 0px;
  width: 16px;
  height: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 #333;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.range-slider-knob:hover {
  box-shadow: none;
}

.switch-handle-2 {
  position: relative;
  top: -1px;
  z-index: 10;
  display: block;
  width: 38%;
  height: 20px;
  margin-bottom: 0px;
  float: left;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 2px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  background-image: radial-gradient(circle farthest-corner at 48% 9%, #fff, #f0f0f0);
  box-shadow: 0 0 4px 0 #747474;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.switch-handle-2.small-handle {
  display: block;
  width: 40%;
  height: 18px;
}

.component-name {
  margin-bottom: 15px;
  color: #033156;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.style-input-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.upload-img-popup {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1100;
  display: none;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
}

.close-link {
  position: absolute;
  top: -10px;
  right: -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.5;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #747474;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.close-link:hover {
  opacity: 1;
}

.close-link.no-move {
  top: 10px;
  right: 10px;
}

.range-slider-fill {
  position: relative;
  z-index: 5;
  width: 20%;
  height: 100%;
  border-radius: 5px;
  background-color: #006ef1;
}

.components-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -3%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.style-input-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  min-width: 30px;
  padding: 8px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 12px;
}

.editor-tabs {
  position: static;
  width: 100%;
}

.component-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 47%;
  min-height: 50px;
  min-width: 50px;
  margin-bottom: 10px;
  margin-left: 3%;
  padding: 20px 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #00316b;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: move;
}

.component-block:hover {
  background-color: #6a3fe7;
}

.component-block.w--current {
  background-color: #6a3fe7;
}

.positions-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: auto;
  max-height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.editor-cont-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.style-block {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.style-block.last-block {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-style: none;
}

.positions-col {
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.positions-col:hover {
  background-color: #f9f9f9;
}

.positions-col.current-position {
  background-color: #006ef1;
}

.positions-col.middle-col {
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}

.style-field-label {
  margin-bottom: 5px;
  color: #033156;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.style-field-label.no-indent {
  margin-bottom: 0px;
}

.top-bar-dot {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 10px;
  background-color: #405167;
}

.upload-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 70px;
  padding: 5px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px dashed #dcdcdc;
  border-radius: 5px;
  background-color: #f9f9f9;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #747474;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.upload-link:hover {
  border-color: #007fff;
}

.upload-link.upload-img {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 10px;
}

.or-text-2 {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #747474;
  font-size: 14px;
}

.component-input-div {
  width: 100%;
  height: 85%;
  min-height: 15px;
  padding: 4px;
  border: 2px solid #fff;
  border-radius: 3px;
}

.component-input-div.section-icon {
  height: 100%;
  border-style: dotted;
  border-width: 2px;
}

.editor-workspace-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #525252;
}

.editor-workspace-wrap.landbot-preview {
  padding: 0;
}

.field-required {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #b6b6b6;
  cursor: pointer;
}

.field-required:hover {
  border-color: #1aa310;
}

.item-draggable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #006ef1;
  border-radius: 3px;
  background-color: #006ef1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 12px;
  cursor: move;
}

.item-draggable:hover {
  background-color: #0066e0;
}

.minicolors-swatch {
  position: absolute;
  left: 10px;
  top: 8px;
  bottom: 0px;
  z-index: 10;
  width: 23px;
  height: 23px;
  border-radius: 3px;
  background-color: #006ef1;
  cursor: pointer;
}

.dropdown-list-5 {
  position: static;
}

.dropdown-list-5.w--open {
  padding: 15px;
  border-top: 1px solid #e0e0e0;
  background-color: #fff;
}

.tabs-menu-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-radius: 3px;
}

.tabs-menu-4.no-move {
  position: static;
}

.component-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 26px;
  height: 26px;
  margin-bottom: 5px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 21px;
}

.component-icon.add-space {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.component-input-line {
  width: 2px;
  height: 100%;
  background-color: #fff;
}

.workspace-bottom-div {
  height: 30px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #6d7382;
}

.select-field-2 {
  width: auto;
  height: 40px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f3f3f3));
  background-image: linear-gradient(180deg, #fff, #f3f3f3);
  font-size: 13px;
}

.select-field-2.full-width {
  width: 100%;
}

.select-field-2.half-w {
  min-width: 50%;
  padding-right: 10px;
  padding-left: 7px;
}

.select-field-2.half-w.left-indent {
  width: 50%;
  min-width: 0%;
  margin-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.item-delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #c40a0a;
  border-radius: 3px;
  background-color: #e61515;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.item-delete:hover {
  background-color: #c40a0a;
}

.range-slider-rail {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
}

.field-2-wrap {
  position: relative;
  width: 100%;
  margin-right: 15px;
}

.input-sidebar-settings-wrap {
  position: absolute;
  top: -5px;
  right: -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.range-slider-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.editor-popup-canvas {
  display: inline-block;
  width: 800px;
}

.style-row {
  margin-bottom: 10px;
}

.style-row.left-indent {
  margin-bottom: 0px;
  margin-left: 10px;
}

.style-row.more-indent {
  margin-bottom: 20px;
}

.style-row.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.style-row.hor-flex.more-indent-2 {
  position: relative;
  margin-bottom: 20px;
}

.style-row.make-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px;
}

.content-column {
  width: 60%;
  min-height: 100px;
  margin-right: 25px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-column.no-margin {
  margin-right: 0px;
}

.switch-wrap-2 {
  width: 66px;
  height: 32px;
  padding: 4px;
  border: 3px solid #fff;
  border-radius: 3px;
  background-color: #f9f9f9;
  box-shadow: 0 0 6px 1px #dedede, inset 0 0 6px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.switch-wrap-2.small-switch {
  width: 58px;
  height: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.editor-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.editor-control-w {
  position: absolute;
  left: auto;
  top: 15px;
  right: 15px;
  bottom: auto;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 20px;
  min-width: 50px;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vert-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
  line-height: 10px;
  text-decoration: none;
  cursor: pointer;
}

.vert-link:hover {
  color: #009aff;
}

.vert-link.right-indent {
  margin-right: 30px;
}

.button.right-indent {
  margin-right: 20px;
}

.border-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #009aff;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.border-btn:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.border-btn.w--current {
  border-color: #009aff;
  background-color: #009aff;
  color: #fff;
}

.border-btn.add-margin {
  margin-right: 10px;
  padding-top: 7px;
}

.blue-button {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #009aff;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.blue-button:hover {
  background-color: #0889dd;
}

.blue-button:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.blue-button.add-indent {
  margin-bottom: 15px;
}

.blue-button.left-indent {
  margin-left: 10px;
}

.blue-button.min-h {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 15px;
}

.blue-button.min-h-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 15px;
}

.blue-button.full-w {
  width: 100%;
  text-align: center;
}

.blue-button.right-margin {
  margin-right: 15px;
}

.blue-button.right-margin.first-item {
  margin-bottom: 10px;
}

.blue-button.right-margin.whatsapp-btn {
  background-color: #4ac959;
}

.blue-button.right-margin.whatsapp-btn:hover {
  background-color: #3aaa48;
}

.blue-button.right-margin.viber-btn {
  background-color: #665cac;
}

.blue-button.right-margin.viber-btn:hover {
  background-color: #4c4385;
}

.blue-button.right-margin.messenger-btn {
  background-color: #00a0f3;
}

.blue-button.right-margin.messenger-btn:hover {
  background-color: #048fd8;
}

.blue-button.right-margin.instagram-btn {
  background-color: #d32750;
}

.blue-button.right-margin.instagram-btn:hover {
  background-color: #9f3492;
}

.blue-button.right-margin.email-btn {
  background-color: #0d66d4;
}

.blue-button.right-margin.email-btn:hover {
  background-color: #2d538c;
}

.blue-button.right-margin.call-btn {
  background-color: #ddc60e;
}

.blue-button.right-margin.call-btn:hover {
  background-color: #beaa08;
}

.blue-button.facebook-btn {
  background-color: #0075ee;
}

.blue-button.facebook-btn:hover {
  background-color: #0063c9;
}

.blue-button.facebook-btn.abs-position-btn {
  position: absolute;
  z-index: 10;
}

.blue-button.telegram-btn {
  background-color: #08c;
}

.blue-button.telegram-btn:hover {
  background-color: #0075af;
}

.blue-button.top-indent {
  margin-top: 20px;
}

.blue-button.transparent {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #009aff;
  background-color: transparent;
  color: #009aff;
}

.blue-button.no-active {
  background-color: #ccc;
  cursor: default;
}

.blue-button.no-active:active {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.blue-button.add-indent2 {
  margin-right: 15px;
}

.channel-icon {
  width: 50px;
  margin-right: 10px;
}
.popup-header-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.editor-workspace {
  position: relative;
  z-index: 5;
  pointer-events: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 70px;
  padding-left: 50px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.diagram-node-wrap {
  position: relative;
  width: 250px;
  min-height: 50px;
  /*margin-right: 80px;*/
  /*margin-bottom: 45px;*/
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(31, 29, 29, 0.6);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: move;
}

.diagram-node-wrap:hover {
  box-shadow: 0 0 10px 5px rgba(31, 29, 29, 0.5);
}

.digram-node-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #fff;
  background-image: linear-gradient(180deg, #00316b, #00316b), linear-gradient(225deg, #006ef1, #00316b);
  color: #fff;
}

.diagram-node-content {
  position: relative;
  padding: 10px 10px 1px;
}

.diagram-node-name {
  font-size: 15px;
  font-weight: 500;
}

.diagram-node-name-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.dn-content-item {
  position: relative;
  margin-bottom: 8px;
}

.dn-message-w {
  position: relative;
  overflow: auto;
  max-height: 100px;
  padding: 0px;
  line-height: 18px;
}

.dn-message-text {
  color: #333;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
}

.dn-message-text.link {
  display: inline-block;
  color: #999;
  line-height: 14px;
  font-weight: 300;
  text-decoration: underline;
}

.dn-message-text.link:hover {
  color: #333;
  text-decoration: none;
}

.drag-point-w {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.drag-point-w.for-btn {
  left: auto;
  top: 0%;
  right: -30px;
  bottom: 0%;
}

.dn-drag-point {
  position: relative;
  z-index: 110;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 20px;
  background-color: #86c500;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.dn-drag-point:hover {
  background-color: #26b94c;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
}

.dn-media-w {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 150px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #f2f6f9;
  color: #999;
  line-height: 18px;
  text-align: center;
}

.dn-media-w.img-on-bg {
  border-style: none;
  background-image: url('./images/post-thumb-02.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.dn-media-w.img-on-bg.new-bg1 {
  background-image: url("./images/customer-service-370x240.jpg");
}

.dn-media-w.img-on-bg.new-bg2 {
  background-image: url("./images/_230x147_23-08-2015-yetkilendirilmis-yukumluluk-statusu-yys-iso-27001-bilgi-guvenligi-yonetim-sistemini-zorunlu-kilmaktadir.jpg");
}

.image-loader-icon {
  width: 45px;
  max-height: 40px;
  margin-bottom: 10px;
  opacity: 0.15;
}

.image-loader-icon.wide-logo {
  width: 90px;
}

.dn-button {
  position: relative;
  margin-bottom: 8px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #009aff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-decoration: none;
  cursor: pointer;
}

.dn-button:hover {
  background-color: #006ef1;
}

.dn-button.default-button {
  background-color: #333;
}

.dn-button.in-chat {
  width: auto;
  margin-left: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #0889dd;
  background-color: transparent;
  color: #0889dd;
  margin-top: 10px;
}

.dn-button.in-chat:hover {
  background-color: rgba(8, 137, 221, 0.1);
}

.dn-button-text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.dn-button-text.added {
  color: #fff;
}

.dn-button-text.blue-color {
  color: #0889dd;
}

.node-connector {
  z-index: 98;
  display: block;
  width: 200px;
  max-width: none;
}

.editor-zoom-w {
  position: absolute;
  left: 15px;
  top: auto;
  right: auto;
  bottom: 15px;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.zoom-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.zoom-btn:hover {
  color: #0889dd;
}

.zoom-btn.no-indent {
  margin-right: 0px;
  margin-left: 0px;
}

.control-input {
  height: 34px;
  max-width: 62px;
  margin-bottom: 0px;
  border-radius: 3px;
}

.first-message {
  position: absolute;
  top: 40%;
}

.first-message-w {
  position: absolute;
  left: 0%;
  top: 40%;
  right: 0%;
  bottom: auto;
  max-width: 250px;
  min-height: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(31, 29, 29, 0.6);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  cursor: move;
}

.big-icon {
  margin-bottom: 5px;
  font-family: Fontawesome, sans-serif;
  color: #37a7e5;
  font-size: 30px;
  line-height: 32px;
}

.diagram-node-control {
  position: absolute;
  left: auto;
  top: 0%;
  right: -60px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  border-radius: 3px;
  background-color: #fff;
}

.diagram-node-control.settings-control {
  left: auto;
  top: 1px;
  right: 1px;
  bottom: auto;
}

.connector-line-w {
  position: absolute;
  left: 50%;
  top: 2px;
  right: auto;
  bottom: auto;
}

.connector-line-w.move {
  top: 35%;
}

.delete-connector {
  position: absolute;
  left: auto;
  top: auto;
  /*right: 30%;*/
  /*bottom: 15%;*/
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 26px;
  height: 26px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 15px;
  cursor: pointer;
}

.control-btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
}

.control-btn:hover {
  color: #0889dd;
}

.control-btn.no-indent {
  margin-right: 0px;
}

.control-btn.more-size {
  font-size: 14px;
}

.hover-popup-w {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: rgba(51, 51, 51, 0.88);
  color: #fff;
  text-transform: none;
  cursor: pointer;
}

.close-settings {
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 16px;
}

.close-btn {
  position: absolute;
  left: auto;
  top: 15px;
  right: 15px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.close-line-1 {
  width: 18px;
  height: 2px;
  background-color: #ddd;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-line-1.rotate {
  position: relative;
  left: 0px;
  top: 2px;
  width: 18px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.dn-settings-header {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
}

.settings-btn-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.settings-btn-w.add-space {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.settings-btn-w.add-space-2 {
  margin-top: 0px;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.settings-btn-w.add-space-2.align-center {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-btn-w.space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.settings-btn-w.vertical-dir {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-item {
  position: relative;
  z-index: 110;
  margin-bottom: 8px;
}

.settings-item.no-margin {
  margin-bottom: 0px;
}

.settings-item.add-margin {
  margin-bottom: 10px;
}

.textarea {
  min-height: 110px;
  margin-bottom: 0px;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
  color: #333;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.textarea.small-message {
  min-height: 50px;
  padding: 6px 10px;
}

.textarea.big-message {
  min-height: auto;
  padding: 5px 0px;
  border-style: none;
  border-radius: 0px;
}

.input-control {
  position: absolute;
  left: auto;
  top: auto;
  right: 1px;
  bottom: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  border-radius: 5px 0px 0px;
}

.input-control.settings-control {
  left: auto;
  top: 1px;
  right: 1px;
  bottom: auto;
}

.input-control.top-left {
  position: static;
  left: 1px;
  top: 1px;
  right: auto;
  bottom: auto;
}

.btns-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.component-link {
  text-decoration: none;
}

.transfer-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-item-label {
  font-size: 14px;
  font-weight: 400;
}

.settings-item-label.add-margin {
  margin-bottom: 5px;
  line-height: 18px;
}

.settings-item-label.add-margin.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.settings-item-label.user-link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.settings-item-label.user-link:hover {
  color: #009aff;
}

.settings-item-label.top-indent {
  margin-top: 15px;
}

.settings-item-label.add-margin-2 {
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 18px;
}

.settings-item-label.add-margin-2.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.settings-item-label.center {
  text-align: center;
}

.settings-item-label.bottom-margin {
  margin-bottom: 15px;
}

.transfer-settings-w {
  display: block;
  height: auto;
}

.info-text {
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  line-height: 16px;
}

.info-text.min-indent {
  margin-top: 5px;
}

.info-text.no-indent {
  position: relative;
  z-index: 1;
  margin-top: 0px;
  color: #ddd;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
}

.info-text.avatar-name {
  position: relative;
  z-index: 1;
  display: block;
  margin-top: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #ddd;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  white-space: nowrap;
}

.info-text.tooltip-text {
  position: relative;
  z-index: 1;
  display: block;
  margin-top: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #ddd;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  white-space: normal;
}

.component-card-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 46%;
  height: 75%;
  padding: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 3px;
  font-family: Fontawesome, sans-serif;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
}

.node-settinsg-slider-w {
  height: auto;
  padding-bottom: 34px;
  background-color: transparent;
}

.node-settinsg-slider-w.no-padding {
  padding-bottom: 9px;
}

.node-settinsg-slider-w.no-padding2 {
  padding-bottom: 0px;
}

.slider-arrow {
  left: -12px;
  z-index: 100;
  width: 24px;
  height: 24px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: none;
  color: #999;
  font-size: 17px;
  cursor: pointer;
}

.slider-arrow:hover {
  color: #37a7e5;
}

.slider-arrow.right-arrow {
  right: -12px;
  margin-right: 0px;
}

.slider-arrow.right-arrow.in-diagram {
  /*right: -40px;*/
  right: -30px;
}

.slider-arrow.right-arrow.in-chat {
  right: -22px;
  height: 50px;
}

.slider-arrow.in-diagram {
  /*left: -40px;*/
  left: -30px;
}

.slider-arrow.in-chat {
  left: -22px;
  width: 22px;
  height: 50px;
}

.card-slide {
  width: 98%;
  height: auto;
  margin-right: 1%;
  margin-left: 1%;
  padding: 10px;
  border-radius: 5px;
  background-color: #c6e8ff;
  margin-top: 10px;
}

.cards-node-masc {
  height: auto;
}

.cards-node-slide-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding-top: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cards-node-slide-nav.hide-slide-nav {
  display: none;
}

.node-settings-input {
  height: 36px;
  margin-bottom: 10px;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(204, 204, 204);
  border-radius: 3px;
  background-color: #f2f6f9;
  color: #333;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.node-settings-input.small-message {
  min-height: 65px;
  padding: 6px 10px;
}

.node-settings-input.number-input {
  width: 60px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.node-settings-input.number-input.no-indent {
  margin-right: 0px;
  margin-left: 0px;
}

.node-settings-input.more-h {
  height: 40px;
}

.node-settings-input.search-field {
  height: 38px;
  margin-bottom: 0px;
  padding-left: 30px;
}

.node-settings-input.color-input {
  padding-left: 35px;
}

.node-settings-input.no-round {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.add-card-btn {
  position: absolute;
  left: auto;
  top: 0%;
  right: -12px;
  bottom: 0%;
  z-index: 90;
  width: 24px;
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: none;
  color: #999;
  font-size: 17px;
  cursor: pointer;
}

.add-card-btn.right-arrow {
  right: -12px;
  margin-right: 0px;
}

.plus-icon-w {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.plus-line {
  width: 55%;
  height: 2px;
  background-color: #999;
}

.plus-line.rotate {
  position: relative;
  top: -2px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.card-slide-control {
  position: absolute;
  left: auto;
  top: 1px;
  right: 1px;
  bottom: auto;
  z-index: 111;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  border-radius: 4px;
  background-color: #fff;
}

.card-slide-control.settings-control {
  left: auto;
  top: 1px;
  right: 1px;
  bottom: auto;
}

.upload-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  border-radius: 5px;
  background-image: url("./images/post-thumb-02.jpg");
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.input-div {
  position: relative;
}

.input-div.like-textarea {
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #f2f6f9;
}

.settings-select {
  height: 36px;
  padding-top: 7px;
  padding-left: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
}

.settings-select.narrow-select {
  width: 130px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.settings-select.no-indent {
  margin-bottom: 0px;
}

.settings-select.left-indent {
  margin-left: 10px;
}

.settings-select.left-indent-2 {
  min-width: 180px;
  margin-bottom: 0px;
  margin-left: 15px;
}

.big-textarea-div {
  min-height: 100px;
  padding: 5px 10px 10px;
}

.checkbox {
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
}

.component-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.components-info-w {
  display: none;
  margin-top: 5px;
  margin-left: 3%;
  padding-top: 5px;
  border-top: 1px solid #ddd;
}

.toltip-icon {
  position: absolute;
  left: auto;
  top: 5px;
  right: 5px;
  bottom: auto;
  font-family: Fontawesome, sans-serif;
  color: #7d8fa5;
  font-size: 14px;
  line-height: 14px;
  cursor: default;
}

.toltip-icon:hover {
  color: #ddd;
}

.emoji-wrap {
  position: relative;
  z-index: 100;
  width: 40px;
  border-style: solid solid solid none;
  border-width: 1px;
  border-color: #e0e0e0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #f9f9f9;
  background-image: url('./images/smile-icon.png');
  background-position: 50% 50%;
  background-size: 19px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.emoji-wrap:hover {
  background-color: #e0e0e0;
}

.emoji-items-wrap {
  position: absolute;
  top: 25px;
  right: -1px;
  z-index: 1000;
  display: none;
  overflow: hidden;
  width: 0px;
  height: 0px;
  margin-bottom: -17px;
  padding: 7px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.emoji-menu-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
}

.emoji-menu-tab {
  width: 25px;
  height: 25px;
  margin: 2px;
  background-image: url('./images/IconsetSmiles.png');
  background-position: 50% -339px;
  background-size: 46px;
  background-repeat: no-repeat;
}

.emoji-items-wrap2 {
  overflow: auto;
  max-height: 180px;
  min-height: 100px;
  min-width: 100px;
}

.emoji-item {
  width: 25px;
  height: 25px;
  margin: 3px;
  background-image: url('./images/emoji_spritesheet_0.png');
  background-position: 0px 0px;
  background-size: 640px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.emoji-item:hover {
  border-radius: 3px;
  background-color: #e0e0e0;
}

.toggle-control-btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  cursor: pointer;
}

.toggle-control-btn:hover {
  color: #37a7e5;
}

.dn-title {
  color: #333;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.dn-title.center {
  text-align: center;
}

.upload-file-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.submit-button {
  height: 34px;
  padding-right: 10px;
  padding-left: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 12px;
}

.transfer-settings-w-2 {
  display: block;
  overflow: hidden;
  height: auto;
  margin-top: 10px;
}

.page-content-header-div {
  margin-bottom: 20px;
}

.page-content-header-div.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-content-header-div.hor-flex.justify-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.page-header {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.page-header.add-indent {
  margin-bottom: 10px;
}

.page-header.right-indent {
  margin-bottom: 10px;
}

.page-header.center {
  text-align: center;
}

.page-subheader {
  margin-top: 5px;
}

.page-subheader.add-margin {
  margin-bottom: 5px;
}

.page-subheader.no-margin {
  margin-top: 0px;
}

.page-subheader.add-margin-2 {
  margin-top: 0px;
  margin-bottom: 10px;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.left-float-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 89px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.left-tab-link {
  margin-bottom: 10px;
  padding: 20px 25px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
}

.left-tab-link:hover {
  background-color: #fff;
}

.left-tab-link.w--current {
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #eee;
}

.left-tab-link.widget-tab {
  border-color: #009aff;
  background-color: #f8faff;
  color: #009aff;
}

.left-tab-link.widget-tab:hover {
  background-color: #fff;
}

.pane-content-w.horizontal-flex {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pane-content-w.horizontal-flex.with-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tabs-content {
  overflow: visible;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.group-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}

.group-title._1200 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.group-content-w {
  margin-top: 15px;
  border-top: 1px solid #eee;
}

.group-content-w.min-margin {
  margin-top: 10px;
}

.group-content-w.add-padding {
  padding-top: 15px;
}

.group-content-w.lb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.group-content-w._1200 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.group-content-w.no-top-bord {
  border-top-style: none;
}

.group-content-w.add-bottom-margin {
  margin-bottom: 20px;
}

.group-content-w.no-line {
  border-top-style: none;
}

.group-content-w.no-indent {
  margin-top: 0;
}

.group-w {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.group-w.no-indent {
  margin-top: 0px;
}

.group-w-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.group-w-header.ava {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.group-w-header.ava.mb30 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.group-w-header.add-margin {
  margin-bottom: 10px;
}

.show-group-content {
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.show-group-content.no-indent {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.show-group-content.no-indent-10 {
  margin-top: 10px;
  padding-top: 10px;
  border-top-style: none;
}

.show-group-content.no-top-margin {
  margin-top: 0px;
}

.show-group-content.no-top-margin2 {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 0px;
}

.show-group-content.new-indent {
  margin-top: 0;
  padding-top: 0;
  border-top-style: none;
}

.show-group-col {
  display: flex;
  justify-content: space-between;
}

.group-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.group-row.no-margin {
  margin-bottom: 0px;
}

.group-row.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.group-row.al-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.group-row.vertical-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.group-row.more-margin {
  margin-bottom: 15px;
}

.group-row.vertical-2 {
  margin-top: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.vertical-row {
  flex-direction: column;
  align-items: stretch;
}

.row {
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.col-6 {
  position: relative;
  width: 49%;
}

.col-6.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col-6.right-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 26px;
}

.col-6.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.col-6.hor-flex-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.col-6.fix-w {
  width: auto;
  margin-right: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-6.grow-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col-6.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.delete-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.delete-btn.abs-position {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 10px;
  margin-right: 10px;
}

.delete-btn.no-indent {
  margin-left: 4px;
}

.field-label-2 {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.field-label-2.top-indent {
  margin-top: 15px;
}

.column-text {
  margin-bottom: 30px;
  color: #747474;
  font-size: 14px;
  line-height: 23px;
}

.column-text.min-indent {
  margin-bottom: 5px;
  line-height: 16px;
}

.blue-span {
  color: #006ef1;
}

.list {
  margin-bottom: 0px;
  padding-left: 15px;
  color: #747474;
}

.small-text {
  color: #747474;
  font-size: 12px;
}

.alert-w {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 4px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
}

.alert-w.warning {
  background-color: #f19e17;
}

.awes-icon {
  margin-right: 5px;
  font-family: Fontawesome, sans-serif;
}

.awes-icon.tooltip-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15px;
  height: 15px;
  margin-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  font-size: 10px;
  cursor: default;
}

.awes-icon.tooltip-icon:hover {
  opacity: 0.7;
}

.alert-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.label-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
}

.label-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.error-message {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #e33131;
  font-size: 12px;
  font-weight: 400;
}

.error-message.add-margin {
  margin-bottom: 5px;
  line-height: 18px;
}

.error-message.info-message {
  color: #999;
}

.toggle-w {
  position: relative;
  margin-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.tooltip-div {
  position: absolute;
  left: -117px;
  top: auto;
  right: 0%;
  bottom: 150%;
  z-index: 1000;
  display: none;
  width: 250px;
  max-width: 320px;
  margin-right: auto;
  margin-left: auto;
  padding: 9px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #eee;
  border-radius: 3px;
  background-color: #1f1d1d;
  box-shadow: 0 1px 8px 0 #eee;
  opacity: 0;
  color: #fff;
  text-align: left;
}

.tooltip-div.name-tooltip {
  position: absolute;
  left: 50%;
  top: auto;
  right: auto;
  bottom: 130%;
  display: block;
  width: auto;
  max-width: none;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  opacity: 1;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.tooltip-div.tooltip-block {
  position: absolute;
  left: 50%;
  top: auto;
  right: auto;
  bottom: 170%;
  display: none;
  max-width: 250px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.tooltip-arrow {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -10%;
  width: 16px;
  height: 16px;
  margin-right: auto;
  margin-left: auto;
  background-color: #1f1d1d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.group-title-span {
  font-size: 14px;
  font-weight: 400;
}

.col-12 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col-12.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col-12.right-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 26px;
}

.inputs-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col-4 {
  position: relative;
  width: 32%;
}

.col-4.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col-4.right-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 26px;
}

.col-4.add-indent {
  margin-left: 15px;
}

.content-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.big-link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  padding: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.big-link-block:hover {
  background-color: #fff;
}

.link-block-icon {
  width: 150px;
  margin-right: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.link-block-icon.confirmed-img {
  width: 128px;
  margin-right: 0px;
  margin-bottom: 30px;
}

.link-block-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.avatar-add-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 70px;
  height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 50%;
  background-color: #f2f6f9;
  font-family: Fontawesome, sans-serif;
}

.avatar-add-wrap.square {
  margin-bottom: 10px;
  border-radius: 3px;
}

.avatar-add-div {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('./images/team-10-246x300.jpg');
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
}

.avatar-add-div.open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-image: url('./images/forum-user.svg');
  background-position: 50% 50%;
  background-size: 79%;
  opacity: 1;
}

.control-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: hsla(0, 0%, 93.3%, 0.8);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.control-icon:hover {
  color: #009aff;
}

.control-icon.upload {
  background-color: transparent;
  font-size: 22px;
}

.control-icon.upload-hide {
  display: none;
  background-color: transparent;
  font-size: 22px;
}

.control-icon.delete-icon:hover {
  color: #e33131;
}

.dropdown-like-select {
  z-index: 100;
  width: 100%;
  margin-bottom: 10px;
}

.dropdown-like-select.time-format {
  max-width: 150px;
  margin-left: 10px;
}

.time-format.add-margin {
  margin-right: 5px;
}

.dropdown-toggle {
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px 18px 10px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
}

.icon {
  margin-right: 5px;
  color: #848585;
  font-size: 12px;
}

.user-tag-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding: 4px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f8faff;
}

.tag-icons {
  font-family: Fontawesome, sans-serif;
}

.tag-text {
  margin-right: 5px;
  margin-left: 5px;
}

.search-block {
  margin-top: 5px;
  padding: 5px 15px 10px;
}

.search-block.no-padding {
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.search-items {
  padding: 10px;
  border-radius: 3px;
  background-color: #f2f6f9;
}

.search-items.no-bg {
  padding: 0px;
  background-color: transparent;
}

.logo-img {
  position: relative;
  width: 32px;
  margin-right: 10px;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 13px;
}

.checkbox-2 {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 0px;
}

.text-field {
  height: 42px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #d1d1d1;
}

.text-field:focus {
  border-color: #f22f46;
}

.text-field::-webkit-input-placeholder {
  color: #6e6e6e;
}

.text-field:-ms-input-placeholder {
  color: #6e6e6e;
}

.text-field::-ms-input-placeholder {
  color: #6e6e6e;
}

.text-field::placeholder {
  color: #6e6e6e;
}

.or-text {
  margin-right: 10px;
  margin-left: 10px;
  color: #6e6e6e;
  font-size: 14px;
  line-height: 16px;
}

.sign-header {
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
}
.sign-header.no-margin {
  margin-bottom: 0px;
}
.popup-subheader {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.submit-button-2 {
  padding-top: 11px;
  padding-bottom: 11px;
  background-color: #f22f46;
  box-shadow: 0 3px 10px 0 rgba(242, 47, 70, 0.2);
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.submit-button-2:hover {
  background-color: #d1152b;
  box-shadow: none;
}

.submit-button-2.black-btn {
  background-color: #333;
  box-shadow: 0 3px 10px 0 rgba(51, 51, 51, 0.2);
}

.submit-button-2.black-btn:hover {
  background-color: #01051a;
  box-shadow: none;
}

.sign-small-text {
  margin-bottom: 15px;
  font-size: 13px;
}

.sign-small-text.no-indent {
  margin-bottom: 0px;
}

.sign-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.or-line {
  height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #dfdfdf;
}

.logo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1d262d;
  font-size: 28px;
  font-weight: 700;
  text-decoration: none;
}

.sign-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 420px;
  height: 100%;
  min-height: 200px;
  padding: 150px 30px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
}

.social-sign-up-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #f22f46;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #f22f46;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.social-sign-up-btn:hover {
  background-color: #f22f46;
  color: #fff;
}

.text-link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #0889dd;
  text-decoration: none;
  cursor: pointer;
}

.text-link:hover {
  text-decoration: underline;
}

.sign-form-block {
  margin-bottom: 30px;
}

.social-sign-icon {
  width: 25px;
  height: 100%;
  margin-right: 10px;
  padding: 3px;
  border-radius: 50%;
  background-color: #fff;
}

.form-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 18px;
}

.or-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.checkbox-label {
  text-align: left;
}

.top-nav-right-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-right-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup-w {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup-container-2 {
  position: relative;
  display: block;
  overflow: hidden;
  width: 95%;
  max-width: 500px;
  min-height: 50px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 3px;
  background-color: #fff;
}

.popup-container-2.center-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.popup-container-2.no-max-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 80vh;
  max-width: 1170px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.popup-container-2.max-w-2 {
  overflow: visible;
  max-width: 900px;
}

.abs-position {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 10px;
  margin-right: 10px;
}

.add-website-popup {
  position: relative;
}

.header-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.table-filter-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.table-filter-block.no-indent {
  margin-right: 0px;
}

.daterange-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
}

.daterange-div.no-margin {
  margin-left: 0px;
}

.daterange-div.right-margin {
  margin-right: 10px;
  margin-left: 0px;
}

.calendar-img {
  display: block;
  width: auto;
  height: 260px;
  max-width: none;
}

.filter-icons {
  width: 16px;
}

.filter-icons.close {
  width: 10px;
  cursor: pointer;
}

.filter-icons.right-indent {
  margin-right: 8px;
}

.margins {
  margin-right: 8px;
  margin-left: 8px;
}

.filter-settings {
  width: 180px;
}

.text-block {
  margin-right: 20px;
}

.choice-text {
  margin-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.table-row.inactive .table-col1:not(:last-child) {
  opacity: 0.6;
}

.table-row.table-header {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fafafa;
}

.table-row.add-bg {
  background-color: #fafafa;
}

.table-row.last-row {
  border-bottom-style: none;
}

.table-col1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  min-height: 10px;
  padding-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.table-col1.check-col {
  width: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.table-col1.time-col {
  width: 28%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.check-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.check-div:hover {
  border-color: #009aff;
}

.check-div.checked {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: #009aff;
  background-color: #009aff;
}

.check-div.checked.add-indent {
  margin-right: 10px;
}

.check-div.right-indent {
  margin-right: 10px;
}

.table-settings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.table-settings.table-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fafafa;
}

.table-settings.add-bg {
  background-color: #fafafa;
}

.table-settings.right-float {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom-style: none;
}

.table-w {
  border-top: 1px none #eee;
}

.details-header {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.details-header.right-indent {
  margin-right: 10px;
}

.details-header.right-indent.grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 400;
}

.action-btn {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.action-btn:hover {
  border-color: #009aff;
  color: #009aff;
}

.action-btn.right-indent {
  margin-left: 0px;
}

.action-btn.delete-btn {
  width: auto;
  height: auto;
}

.action-btn.delete-btn:hover {
  border-color: #e33131;
  color: #e33131;
}

.action-btn.delete-btn.no-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.action-btn.no-indent {
  margin-right: 0px;
  margin-left: 0px;
}

.action-btn.calendar-btn {
  border-color: hsla(0, 0%, 100%, 0.5);
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 700;
}

.action-btn.calendar-btn:hover {
  border-color: #fff;
  color: #fff;
}

.action-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.action-w.grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.pagination-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.page-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  margin-right: 2px;
  margin-left: 2px;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  color: #009aff;
}

.page-link.current-page-link {
  color: #009aff;
}

.pagination-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.step-btn-text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.popup2-w {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.78);
  opacity: 0;
}

.div-block {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.radio-button-field {
  width: auto;
  margin-right: 20px;
}

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.radio-button-label {
  font-weight: 300;
}

.input-div-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.input-div-2.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.input-div-2.right-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 26px;
}

.input-div-2.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.input-div-2.fix-w {
  width: auto;
  margin-right: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.input-div-2.grow-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.link-2 {
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.link-2:hover {
  color: #009aff;
  text-decoration: underline;
}

.link-2.small-link {
  font-size: 14px;
  font-weight: 300;
}

.add-manager-popup {
  position: relative;
}

.popup-w-2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup-w-2._2 {
  display: none;
}

.image {
  width: 40px;
  margin-bottom: 20px;
}

.pop-up-text {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}

.day-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.check-btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding: 4px 3px 4px 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #575757;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.check-btn:hover {
  border-color: #f22f46;
}

.check-btn.box-checked {
  border-color: #f22f46;
  background-color: #f22f46;
}

.range-hints-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.checkbox-3 {
  display: none;
}

.btn-add-range {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 6px;
  padding-right: 1px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  -webkit-transition: opacity 150ms ease;
  transition: opacity 150ms ease;
  color: #009aff;
  font-size: 25px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.btn-add-range:hover {
  background-color: #d1152b;
}

.btn-add-range.no-abs-position:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.working-days-block {
  padding: 0px;
}

.input-control-2 {
  display: block;
  width: 70px;
  height: 36px;
  margin-bottom: 0px;
  padding: 5px 5px 5px 7px;
  border-radius: 3px;
  background-color: #f2f6f9;
  color: #333;
}

.input-control-2:focus {
  border: 1px solid #f22f46;
}

.input-control-2::-webkit-input-placeholder {
  color: #f22f46;
}

.input-control-2:-ms-input-placeholder {
  color: #f22f46;
}

.input-control-2::-ms-input-placeholder {
  color: #f22f46;
}

.input-control-2::placeholder {
  color: #f22f46;
}

.input-control-2.min-input {
  width: 80px;
}

.input-control-2.min-input.add-margin {
  width: 70px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
}

.input-control-2.min-input.time-picker {
  width: 85px;
}

.input-control-2.min-input.add-margin::-webkit-input-placeholder {
  color: #333;
}

.input-control-2.min-input.add-margin:-ms-input-placeholder {
  color: #333;
}

.input-control-2.min-input.add-margin::-ms-input-placeholder {
  color: #333;
}

.input-control-2.min-input.add-margin::placeholder {
  color: #333;
}

.range-hint-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rotate-text {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 23px;
  line-height: 20px;
}

.checkbox-field-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #575757;
}

.checkbox-field-2.fix-w {
  min-width: 100px;
  margin-top: 7px;
  font-weight: 400;
}

.chat-and-sidebar-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-3 {
  margin-bottom: 20px;
}

.custom-dropdown-w {
  margin-top: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.custom-dropdown-w.no-indent {
  margin-top: 0px;
}

.custom-dropdown-list {
  margin-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
}

.custom-dropdown-toggle {
  padding: 20px;
  cursor: pointer;
}

.down-arrow {
  width: 20px;
}

.down-arrow._14 {
  width: 14px;
}

.show-group-content-2 {
  display: block;
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
}

.show-group-content-2.no-indent {
  margin-top: 0px;
  padding-top: 0px;
  border-top-style: none;
}

.show-group-content-2.no-top-m {
  margin-top: 0px;
}

.color-themes-w {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.color-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-image: linear-gradient(115deg, #5bdfdf, #45afca);
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.color-item:hover {
  -webkit-filter: saturate(150%);
  filter: saturate(150%);
}

.color-item.color-2 {
  background-image: linear-gradient(115deg, #e9ae4e, #a56d13);
}

.color-item.color-3 {
  background-image: linear-gradient(115deg, #45c767, #008823);
}

.color-item.color-4 {
  background-image: linear-gradient(115deg, #8664e9, #4517ca);
}

.color-item.color-5 {
  background-image: linear-gradient(115deg, #d76dec, #ab1ac2);
}

.color-item.solid-color {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  background-color: #0889dd;
  background-image: none;
}

.color-item.solid-color._2 {
  background-color: #00316b;
}

.color-item.solid-color {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  background-color: #0889dd;
  background-image: none;
}

.color-theme-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 229px;
  padding-top: 7px;
  padding-left: 7px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.choose-type-w {
  display: inline-block;
  margin-right: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border: 1px solid #009aff;
  border-radius: 3px;
}

.choose-type-w.no-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-style: none;
  border-radius: 3px;
}

.choose-item {
  display: inline-block;
  margin-left: -1px;
  padding: 5px 10px;
  float: left;
  clear: none;
  border: 1px solid #009aff;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  font-size: 13px;
  cursor: pointer;
}

.choose-item:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.choose-item.w--current {
  background-color: #009aff;
  color: #fff;
}

.choose-item.checked {
  background-color: #009aff;
  color: #fff;
}

.choose-item.min100 {
  min-width: 100px;
  margin-right: 7px;
  margin-bottom: 7px;
  border-radius: 3px;
  text-align: center;
}

.line {
  width: 1px;
  height: 30px;
  float: left;
  background-color: #009aff;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.more-settings-div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-block-5 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col-4-copy {
  position: relative;
  width: 32%;
}

.col-4-copy.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.col-4-copy.right-padding {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 26px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.chat-preview-w {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 375px;
  min-height: 621px;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat-preview-w.landbot-preview {
  width: 100%;
  height: 100%;
  padding-top: 0;
}

.chat-preview-w.down-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat-preview-w.on-mobile {
  padding-bottom: 0px;
}

.chat-preview-w.no-indent {
  min-height: auto;
  padding-top: 0px;
}

.chat-button {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  display: none;
  width: 50px;
  height: 50px;
  margin-top: 15px;
  margin-left: auto;
  padding: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #36a6e5, #016ff1);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.chat-button:hover {
  box-shadow: none;
}

.chat-button.round-btn {
  border-radius: 50%;
}

.chat-preview {
  position: relative;
  z-index: 90;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 375px;
  max-height: 571px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
}

.chat-preview.hide {
  display: none;
  opacity: 0;
  -webkit-transform: translate(0px, 20px);
  -ms-transform: translate(0px, 20px);
  transform: translate(0px, 20px);
}

.chat-preview.h-auto {
  max-height: none;
}

.chat-preview.add-overflow {
  overflow: hidden;
}

.chat-pre-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
}

.chat-conversation-w {
  overflow: auto;
  max-height: 310px;
  min-height: 310px;
  padding: 20px 25px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.chat-conversation-w.no-max-h {
  max-height: none;
}

.chat-conversation-w.no-max-h.min-h {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-input-group {
  padding: 20px 25px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top: 1px solid #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}

.chat-avatar-img {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.chat-avatar-img.in-offline-popup {
  width: 61px;
  height: auto;
  margin-bottom: 20px;
}

.chat-avatar-img.next-avatar-img {
  left: auto;
  display: block;
  width: 35px;
  height: 35px;
  max-width: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.4);
}

.chat-pre-header-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-pre-info {
  margin-left: 10px;
  color: #fff;
  font-size: 13px;
}

.chat-operator-name {
  font-size: 16px;
  font-weight: 500;
}

.chat-pre-header-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-pre-channels-w {
  display: none;
  margin-top: 10px;
  padding-top: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid hsla(0, 0%, 90%, 0.25);
}

.chat-pre-add-channel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 27px;
  height: 27px;
  margin-right: 8px;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #3a74b9;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.chat-pre-add-channel:hover {
  -webkit-filter: saturate(150%);
  filter: saturate(150%);
}

.chat-button-img {
  position: absolute;
  display: block;
  width: 25px;
  opacity: 1;
}

.chat-button-img.close-chat {
  display: block;
  width: 19px;
  opacity: 1;
}

.chat-message-block {
  margin-bottom: 15px;
}

.chat-message-recip-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.recip-avatar-w {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.recip-chat-message {
  max-width: 75%;
  padding: 10px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 10px 10px 10px 0px;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
  color: #fff;
  font-size: 15px;
}

.chat-message-sender-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat-message-sender-div.with-buttons {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sender-chat-message {
  max-width: 75%;
  padding: 10px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 10px 10px 0px;
  background-color: #f5f5f5;
  font-size: 15px;
  text-align: right;
}

.text-block-2 {
  display: inline;
}

.chat-message-info {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  font-weight: 300;
  text-align: right;
}

.chat-message-info.recip-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.recip-name {
  margin-right: 5px;
  font-weight: 400;
}

.powered-link {
  opacity: 0.5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #999;
  font-size: 11px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.powered-link:hover {
  color: #009aff;
  text-decoration: underline;
}

.chat-input-div {
  position: relative;
  margin-bottom: 3px;
}

.chat-input-div.grow {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-7 {
  max-width: 960px;
  margin-right: 20px;
}

.cp-header-bottom-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid hsla(0, 0%, 90%, 0.25);
  color: #eee;
  font-size: 13px;
  line-height: 16px;
}

.online-dot {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #18d348;
}

.back-chat {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  background-image: url('./images/left-arrow.svg');
  background-position: 50% 49%;
  background-size: 16px;
  background-repeat: no-repeat;
  opacity: 0.7;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.back-chat:hover {
  opacity: 1;
}

.back-chat.close-icon2 {
  margin-right: 0px;
  background-image: url("./images/close-white.svg");
}

.chat-pre-header-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.all-chats-popup {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  opacity: 0;
  -webkit-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
}

.all-chats-block {
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.offline-popup {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  font-size: 15px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
}

.form-div2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.submit-button-3 {
  border-radius: 3px;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
  font-weight: 400;
}

.close-icon {
  position: absolute;
  left: auto;
  top: 15px;
  right: 15px;
  bottom: auto;
  width: 12px;
  opacity: 0.5;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.close-icon:hover {
  opacity: 1;
}

.white-input {
  height: 40px;
  margin-bottom: 10px;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.white-input.small-message {
  min-height: 65px;
  padding: 6px 10px;
}

.white-input.number-input {
  width: 60px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.white-input.number-input.no-indent {
  margin-right: 0px;
  margin-left: 0px;
}

.white-input.more-h {
  height: 40px;
}

.white-input.search-field {
  height: 38px;
  margin-bottom: 0px;
  padding-left: 30px;
}

.white-input.color-input {
  padding-left: 35px;
}

.chat-close-img {
  position: absolute;
  display: block;
  width: 19px;
  opacity: 1;
}

.chat-close-img.close-chat {
  display: block;
  width: 19px;
  opacity: 1;
}

.chat-round-button {
  position: relative;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 15px;
  margin-left: auto;
  padding: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #36a6e5, #016ff1);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.chat-round-button:hover {
  box-shadow: none;
}

.chat-round-button.round-btn {
  border-radius: 50%;
}

.chat-button-img-2 {
  position: absolute;
  display: block;
  width: 25px;
  opacity: 1;
}

.chat-button-img-2.close-chat {
  display: block;
  width: 19px;
  opacity: 1;
}

.chat-button-mobile {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #36a6e5, #016ff1);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.chat-button-mobile:hover {
  box-shadow: none;
}

.chat-button-mobile.round-btn {
  border-radius: 50%;
}

.chat-preview-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 375px;
  max-height: 550px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
}

.chat-preview-2.hide {
  display: none;
  opacity: 0;
  -webkit-transform: translate(0px, 30px);
  -ms-transform: translate(0px, 30px);
  transform: translate(0px, 30px);
}

.chat-preview-2.h-auto {
  max-height: none;
}

.chat-btn-tooltip-w {
  position: absolute;
  left: auto;
  top: 0%;
  right: 100%;
  bottom: auto;
  display: none;
  width: 120px;
  margin-right: 15px;
  padding: 7px 9px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  -webkit-transform: translate(-20px, 0px);
  -ms-transform: translate(-20px, 0px);
  transform: translate(-20px, 0px);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #000;
  font-size: 12px;
  line-height: 16px;
}

.tooltip-corner {
  position: absolute;
  left: auto;
  top: 0%;
  right: -5px;
  bottom: auto;
  width: 8px;
  height: 8px;
  margin-top: 10px;
  background-color: #fff;
  -webkit-transform: rotate(45deg) perspective(1961px);
  transform: rotate(45deg) perspective(1961px);
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
  min-height: 50px;
  margin-right: auto;
  margin-left: auto;
}

.button-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #e33131;
}

.div-block-9 {
  margin-right: -29px;
  padding-right: 0px;
}

.content-white-block-2 {
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
}

.content-white-block-2.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-white-block-2.new-col-4 {
  position: -webkit-sticky;
  position: sticky;
  top: 89px;
  width: 415px;
  max-width: 450px;
  margin-left: 20px;
}

.content-white-block-2.grow-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.blue-button-2 {
  border-radius: 3px;
  background-color: #009aff;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.blue-button-2:hover {
  background-color: #0889dd;
}

.blue-button-2:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.blue-button-2.top-indent {
  margin-top: 20px;
}

.blue-button-2.facebook-btn {
  background-color: #0075ee;
}

.blue-button-2.facebook-btn:hover {
  background-color: #0063c9;
}

.blue-button-2.transparent {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #006ef1;
  background-color: transparent;
  color: #006ef1;
}

.blue-button-2.min-h {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 15px;
}

.blue-button-2.right-margin {
  margin-right: 20px;
}

.blue-button-2.right-margin.whatsapp-btn {
  background-color: #4ac959;
}

.blue-button-2.right-margin.whatsapp-btn:hover {
  background-color: #3aaa48;
}

.blue-button-2.right-margin.viber-btn {
  background-color: #665cac;
}

.blue-button-2.right-margin.viber-btn:hover {
  background-color: #4c4385;
}

.blue-button-2.telegram-btn {
  background-color: #08c;
}

.blue-button-2.telegram-btn:hover {
  background-color: #0075af;
}

.group-w-2 {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.group-w-2.no-indent {
  margin-top: 0px;
}

.group-content-w-2 {
  margin-top: 15px;
  border-top: 1px solid #eee;
}

.group-content-w-2.add-padding {
  padding-top: 15px;
}

.group-content-w-2.no-border-top {
  border-top-style: none;
}

.action-btn-2 {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.action-btn-2:hover {
  border-color: #009aff;
  color: #009aff;
}

.node-settings-input-2 {
  height: 36px;
  margin-bottom: 10px;
  padding: 6px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
  color: #333;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.node-settings-input-2.number-input {
  width: 60px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.node-settings-input-2.number-input.no-indent {
  margin-right: 0px;
  margin-left: 0px;
}

.node-settings-input-2.color-input {
  padding-left: 35px;
}

.node-settings-input-2.search-field {
  height: 38px;
  margin-bottom: 0px;
  padding-left: 30px;
}

.link-3 {
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.link-3:hover {
  color: #009aff;
  text-decoration: underline;
}

.settings-select-2 {
  height: 36px;
  padding-top: 7px;
  padding-left: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
}

.settings-select-2.no-indent {
  margin-bottom: 0px;
}

.settings-select-2.narrow-select {
  width: 100px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  padding-right: 8px;
  padding-left: 8px;
}

.check-code {
  margin-left: 15px;
  padding-left: 20px;
  padding-right: 5px;
  background-position: 0 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 300;
}

.code-installed {
  margin-left: 38px;
  padding-left: 20px;
  background-image: url('./images/check.svg');
  background-position: 0% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 300;
}

.code-not-installed {
  background-image: url("./images/close-button-red.svg");
}

.cheking-code {
  background-image: url("./images/spinner.gif");
  background-size: 25px;
}

.show-group-content-2 {
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.campaign-preview-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
}

.cp-info-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.top-navbar-2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 59px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-shadow: none;
}

.a-tab-link-2 {
  padding: 11px 25px 10px;
  border: 1px solid #009aff;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  cursor: pointer;
}

.a-tab-link-2:hover {
  background-color: rgba(0, 154, 255, 0.1);
  color: #009aff;
}

.a-tab-link-2.w--current {
  border-color: #009aff;
  background-color: #009aff;
  color: #fff;
}

.a-tab-link-2.middle-tab-link {
  margin-right: -1px;
  margin-left: -1px;
}

.account-tog-2 {
  display: block;
  padding: 13px;
  border-radius: 3px;
  background-color: #009aff;
  opacity: 1;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
  font-size: 20px;
  line-height: 10px;
  cursor: pointer;
}

.account-tog-2:hover {
  color: #f19e17;
}

.account-tog-2.w--open {
  opacity: 1;
}

.account-tog-2.with-icon {
  padding: 8px;
  background-color: transparent;
}

.account-tog-2.with-icon:hover {
  background-color: #dff1ff;
}

.button-3 {
  border-radius: 3px;
  background-color: #38c75d;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.button-3:hover {
  background-color: #26b94c;
}

.button-3:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.button-3.left-indent {
  margin-left: 20px;
}

.button-3.right-float {
  display: block;
  margin-right: 0px;
  margin-left: auto;
}

.button-3.right-float2 {
  display: block;
  margin-right: 0px;
  margin-left: auto;
  float: right;
}

.cp-info-header-icon-2 {
  margin-right: 5px;
  font-family: Fontawesome, sans-serif;
  color: #333;
  font-size: 18px;
}

.dropdown-link-2 {
  margin-left: 0px;
  padding: 5px 20px 5px 15px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: Circular, sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  cursor: pointer;
}

.dropdown-link-2:hover {
  color: #009aff;
}

.dropdown-link-2.w--current {
  color: #009aff;
}

.top-menu-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-left: 15px;
  padding-right: 30px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #eee;
  background-color: #f8faff;
}

.step-btn-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #009aff;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.step-btn-2:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.step-btn-2.w--current {
  border-color: #009aff;
  background-color: #009aff;
  color: #fff;
}

.step-btn-2.with-border {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.filter-drop-tog-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 10px 7px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #009aff;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  font-size: 14px;
  cursor: pointer;
}

.filter-drop-tog-2:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.filter-drop-tog-2.w--open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: rgba(0, 154, 255, 0.1);
}

.filter-drop-tog-2.no-arrow {
  padding-left: 10px;
}

.filter-drop-tog-2.no-arrow:hover {
  background-color: #e5f5ff;
}

.filter-drop-tog-2.chat-toggle {
  width: 100%;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0);
  background-color: transparent;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 24px;
}

.filter-drop-tog-2.chat-toggle:hover {
  border-color: #fff;
}

.filter-drop-tog-2.color-toggle {
  width: 100%;
  height: 100%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #009aff;
  font-family: Fontawesome, sans-serif;
}

.filter-drop-tog-2.no-border {
  padding: 4px 0px 3px;
  border-style: none;
}

.filter-drop-tog-2.awesome-font {
  width: 40px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #009aff;
  font-family: Fontawesome, sans-serif;
}

.top-arr-line-2 {
  position: absolute;
  left: auto;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 2px;
  border-radius: 2px;
  background-color: #ddd;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.top-arr-line-2.bottom-line {
  top: 2px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.arrow-line-2 {
  position: relative;
  width: 12px;
  height: 2px;
  border-radius: 2px;
  background-color: #ddd;
}

.cp-name-text {
  margin-bottom: 0px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.page-title-2 {
  margin-right: 30px;
  color: #1f1d1d;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.page-title-2.little-margin-left {
  margin-right: 11px;
  color: #fff;
}

.ava-block {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 50%;
  padding: 10px;
}

.ava-grey {
  width: 10px;
  height: 10px;
  float: left;
  border-radius: 20px;
  background-color: #999;
}

.ava-orange {
  width: 10px;
  height: 10px;
  float: right;
  border-radius: 20px;
  background-color: #f19e17;
}

.switch-wrap-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 47px;
  height: 25px;
  padding: 1px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 50px;
  background-color: #eee;
  -webkit-transition: all 100ms cubic-bezier(.175, .885, .32, 1.275);
  transition: all 100ms cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer;
}

.switch-wrap-3.active-switch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-color: #34a250;
  background-color: #38c75d;
}

.image-2 {
  height: 25px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0px;
}

.avaibility-block2 {
  position: absolute;
  left: 100%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 333px;
  margin-bottom: 10px;
  margin-left: 10px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 13px 0 rgba(51, 51, 51, 0.31);
  color: #333;
}

.popup-container-block2 {
  position: relative;
  display: block;
  overflow: hidden;
  width: 95%;
  max-width: 500px;
  min-height: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;
}

.chat-avatar-2-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #37a7e5;
  color: #fff;
  font-size: 18px;
}

.chat-avatar-2-2.closed-chat {
  background-color: #f19e17;
}

.ava-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}

.popup-w-2-quick {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.close-line-1-2 {
  width: 18px;
  height: 2px;
  background-color: #ddd;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-line-1-2.rotate {
  position: relative;
  left: 0px;
  top: 2px;
  width: 18px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popup-container-2-2 {
  position: relative;
  display: block;
  overflow: hidden;
  width: 95%;
  max-width: 500px;
  min-height: 50px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 3px;
  background-color: #fff;
}

.table-row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.step-2-img {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #37a7e5;
  color: #fff;
  font-size: 18px;
}

.step-2-img.no-left-indent {
  margin-left: 0px;
}

.step-2-img img {
  border-radius: 50%;
}

.popup-landbot-preview {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  padding: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup-container-1200 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 95%;
  height: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 40px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 3px;
  background-color: #fff;
}

.image-3 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.image-4 {
  display: block;
  height: 100%;
  margin: 30px auto;
}

.div-block-10 {
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #999;
}

.div-block-10.smart {
  max-width: 400px;
}

.bottom15 {
  margin-bottom: 15px;
}

.dropdown-block {
  margin-left: 0px;
  padding: 5px 20px 5px 15px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  font-family: Circular, sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  cursor: pointer;
}

.dropdown-block.w--current {
  color: #009aff;
}

.dropdown-block.link-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-width: 165px;
  padding-right: 15px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  text-decoration: none;
}

.dropdown-block.link-block:hover {
  color: #009aff;
}

.dropdown-block.red-link {
  color: #e33131;
}

.dropdown-block.red-link:hover {
  color: #c00d0d;
}

.drop-white-block {
  margin-bottom: 20px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
}

.drop-white-block.new-col-4 {
  position: -webkit-sticky;
  position: sticky;
  top: 89px;
  width: 415px;
  max-width: 450px;
  margin-left: 20px;
}

.drop-white-block.new-col-4.relative-position {
  position: relative;
  top: auto;
}

.drop-white-block.grow-w {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.drop-white-block.hor-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.drop-white-block.hor-flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.drop-white-block.table-container {
  min-height: 50px;
  padding: 0px;
}

.div-block-11 {
  padding-top: 5px;
}

.div-block-11._10 {
  padding-top: 10px;
  padding-bottom: 20px;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  color: #999;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.div-block-12:hover {
  border-color: #26b94c;
}

.div-block-11f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
}

.settings-group-label {
  font-size: 15px;
  font-weight: 400;
}

.settings-group-label.add-margin {
  margin-bottom: 5px;
  line-height: 18px;
}

.settings-group-label.add-margin.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.settings-group-label.user-link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.settings-group-label.user-link:hover {
  color: #009aff;
}

.settings-group-label.top-indent {
  margin-top: 15px;
}

.settings-group-label.add-margin-2 {
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 18px;
}

.settings-group-label.add-margin-2.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.tabs-menu-1200 {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 140px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.tabs-1200 {
  height: 100%;
  margin-top: -20px;
  margin-right: -40px;
  margin-left: -40px;
  padding-top: 35px;
}

.tab-link-1200 {
  width: 70px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.tabs-content-1200 {
  height: 100%;
}

.tab-pane-1200 {
  height: 100%;
}

.main-navbar-2 {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-right: 1px solid #eee;
  background-color: #1d262d;
}

.alert-w-2 {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 4px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
}

.alert-w-2.warning {
  background-color: #f19e17;
}

.nav-link-name-2 {
  position: absolute;
  left: 85%;
  top: auto;
  right: auto;
  bottom: auto;
  display: none;
  width: auto;
  padding: 6px 10px 4px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  -webkit-transform: translate(0px, 5px);
  -ms-transform: translate(0px, 5px);
  transform: translate(0px, 5px);
  color: #1f1d1d;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  text-transform: none;
}

.left-tab-link-2 {
  margin-bottom: 10px;
  padding: 20px 25px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;
}

.left-tab-link-2:hover {
  background-color: #fff;
}

.left-tab-link-2.w--current {
  background-color: #fff;
  box-shadow: 0 1px 8px 0 #eee;
}

.nav-link-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.nav-link-2:hover {
  background-color: rgba(0, 154, 255, 0.33);
}

.nav-link-2.w--current {
  background-color: #009aff;
  color: #fff;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.chat-input-group-2 {
  padding: 20px 25px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-top: 1px solid #eee;
  background-color: #fff;
}

.new-mess-action-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 20px;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
}

.new-mess-action-2:hover {
  color: #000;
}

.new-mess-action-2.min-size {
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 20px;
}

.popup-container-block2 {
  position: relative;
  display: block;
  overflow: hidden;
  width: 95%;
  max-width: 500px;
  min-height: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;
}

.group-w-header-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ava-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}

.cp-header-bottom-div-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid hsla(0, 0%, 90%, 0.25);
  color: #eee;
  font-size: 13px;
  line-height: 16px;
}

.show-group-content-2-2 {
  display: block;
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
}

.show-group-content-2-2.no-border-top {
  border-top-style: none;
}

.chat-message-info-2 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  font-weight: 300;
  text-align: right;
}

.chat-message-info-2.recip-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-message-info-2.recip-info-bot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 55px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.line-2 {
  width: 1px;
  height: 30px;
  float: left;
  background-color: #009aff;
}

.chat-pre-info-2 {
  margin-left: 10px;
  color: #fff;
  font-size: 13px;
}

.send-text-area-2 {
  min-height: 80px;
  margin-bottom: 0px;
  padding-right: 130px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fdfdfd;
  max-height: 82px;
}

.send-text-area-2.min-h {
  min-height: 60px;
  padding: 0px 65px 0px 0px;
  border-style: none;
  background-color: transparent;
}

.send-text-area-2.min-h-bot {
  min-height: 30px;
  padding-right: 109px;
  padding-bottom: 0px;
  padding-left: 0px;
  border-style: none;
  background-color: transparent;
}

.choose-item-2 {
  display: inline-block;
  padding: 5px 15px;
  float: left;
  clear: none;
  border: 1px solid #009aff;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #009aff;
  cursor: pointer;
}

.choose-item-2:hover {
  background-color: rgba(0, 154, 255, 0.1);
}

.choose-item-2.w--current {
  background-color: #009aff;
  color: #fff;
}

.choose-item-2.checked {
  background-color: #009aff;
  color: #fff;
}

.chat-pre-header-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
}

.recip-chat-message-2 {
  max-width: 75%;
  padding: 10px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 10px 10px 10px 0px;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
  color: #fff;
  font-size: 15px;
}

.custom-dropdown-w-2 {
  margin-top: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
}

.custom-dropdown-w-2.no-margin-top {
  margin-top: 0px;
}

.powered-link-2 {
  opacity: 0.5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #999;
  font-size: 11px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.powered-link-2:hover {
  color: #009aff;
  text-decoration: underline;
}

.powered-link-2.white {
  color: #fff;
}

.choose-type-w-2 {
  display: inline-block;
  margin-right: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border: 1px solid #009aff;
  border-radius: 3px;
}

.social-link {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.social-link:hover {
  opacity: 0.8;
}

.social-link.right-margin {
  margin-top: 15px;
  margin-right: 20px;
}

.social-link.right-margin.mail {
  background-color: #036cb5;
}

.social-link.right-margin.mail:hover {
  background-color: #00528b;
}

.image-5 {
  margin: auto;
}

.upload-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 200px;
  margin-top: 15px;
  padding: 9px 11px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #009aff;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0889dd;
}

.upload-button:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.upload-button.add-indent {
  margin-bottom: 15px;
}

.upload-button.left-indent {
  margin-left: 10px;
}

.upload-button.min-h {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 15px;
}

.upload-button.min-h-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 15px;
}

.upload-button.full-w {
  width: 100%;
  text-align: center;
}

.upload-button.right-margin {
  margin-right: 20px;
}

.upload-button.right-margin.whatsapp-btn {
  background-color: #4ac959;
}

.upload-button.right-margin.whatsapp-btn:hover {
  background-color: #3aaa48;
}

.upload-button.right-margin.viber-btn {
  background-color: #665cac;
}

.upload-button.right-margin.viber-btn:hover {
  background-color: #4c4385;
}

.upload-button.facebook-btn {
  background-color: #0075ee;
}

.upload-button.facebook-btn:hover {
  background-color: #0063c9;
}

.upload-button.telegram-btn {
  background-color: #08c;
}

.upload-button.telegram-btn:hover {
  background-color: #0075af;
}

.upload-button.top-indent {
  margin-top: 20px;
}

.upload-button.transparent {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #006ef1;
  background-color: transparent;
  color: #006ef1;
}

.image-6 {
  width: 31px;
  padding-right: 5px;
}

.button-4 {
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 18px 23px;
  border-radius: 3px;
  background-color: #6a3fe7;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.button-4:hover {
  background-color: #4b2ca5;
}

.button-4:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.button-4.left-indent {
  margin-left: 10px;
}

.button-4.right-float {
  display: block;
  margin-right: 0px;
  margin-left: auto;
}

.button-4.no-left-margin {
  margin-left: 0px;
}

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 11px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section {
  padding-top: 40px;
  padding-bottom: 197px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6a3fe7), to(#4b2ca5));
  background-image: linear-gradient(180deg, #6a3fe7, #4b2ca5);
}

.chat-input-wrap {
  padding: 15px;
  border-radius: 10px;
  background-color: #37a7e5;
  background-image: -webkit-gradient(linear, left top, right top, from(#37a7e5), to(#006ef1));
  background-image: linear-gradient(90deg, #37a7e5, #006ef1);
}

.chat-input-wrap.shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.31);
}

.date-picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-block-2 {
  margin-bottom: 0px;
}

.content-gradient-block {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  padding: 22px 15px;
}

.chat-user-name-2 {
  margin-bottom: 5px;
  color: #1f1d1d;
  font-size: 20px;
  font-weight: 500;
}

.user-identity-2 {
  color: #1f1d1d;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
}

.chat-avatar-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #37a7e5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Fontawesome, sans-serif;
  color: #fff;
  font-size: 18px;
}

.chat-avatar-2.closed-chat {
  background-color: #f19e17;
}

.chat-avatar-2.blocked-chat {
  background-color: #e33131;
}

.chat-item-info-2 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #999;
  line-height: 16px;
}

.visitor-details-header-w-2 {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.divider-2 {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #eee;
}

.chat-header-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #f8faff;
}

.right-sidebar-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #eee;
  background-color: #f8faff;
}

.chat-wrap-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  background-color: #f8faff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  text-decoration: none;
  cursor: pointer;
}

.chat-item-2:hover {
  background-color: #fff;
}

.chat-item-2.new-chat {
  background-color: #c6e8ff;
}

.chat-item-2.active-chat {
  background-color: #fff;
}

.visitor-details-label-2 {
  margin-bottom: 3px;
  color: #999;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.link-4 {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #999;
  text-decoration: underline;
  cursor: pointer;
}

.link-4:hover {
  text-decoration: none;
}

.visitor-details-w-2 {
  margin-top: 15px;
  padding: 0px;
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #fff;
}

.left-sidebar-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 280px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #eee;
  background-color: #f8faff;
}

.chat-date-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #999;
  font-size: 12px;
  line-height: 16px;
}

.chat-date-2.time-text {
  margin-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chat-status-icon-2 {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: #37a7e5;
}

.chat-status-icon-2.pending-status {
  background-color: #6a3fe7;
}

.chat-status-icon-2.blocked-status {
  background-color: #e33131;
}

.chat-status-icon-2.opened-status {
  background-color: #38c75d;
}

.chat-status-icon-2.closed-status {
  background-color: #f19e17;
}

.chats-list-w-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #eee;
}

.divider-text-2 {
  margin-right: 20px;
  margin-left: 20px;
  color: #999;
  font-size: 12px;
}

.chat-item-check-2 {
  position: absolute;
  opacity: 0;
  color: #3d4a6b;
}

.main-link {
  max-width: 50px;
  margin-right: 16px;
}

.text-block-3 {
  color: #fff;
  font-size: 18px;
}

.top-navbar-bot {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 59px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #7854e1;
  background-color: #6a3fe7;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.02);
}

.recip-chat-message-3 {
  max-width: 75%;
  padding: 19px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 10px 10px 10px 0px;
  background-image: linear-gradient(135deg, #37a7e5, #006ef1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.31);
  color: #fff;
  font-size: 18px;
}

.recip-bot-avatar-w {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.sender-chat-message-bot {
  max-width: 75%;
  padding: 19px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 10px 10px 0px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.31);
  font-size: 18px;
  text-align: right;
}

.send-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  margin-left: 17px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.8;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.send-icon:hover {
  opacity: 1;
}

.chat-input-send-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
}

.chat-input-wrap-padding {
  padding-right: 15px;
  padding-left: 15px;
}

.new-mess-action-3 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 333px;
  margin-bottom: 42px;
  margin-left: 10px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 13px 0 rgba(51, 51, 51, 0.31);
  font-family: Circular, sans-serif;
  color: #333;
  font-size: 14px;
  cursor: default;
}

.new-mess-action-3.smiles {
  left: auto;
  top: auto;
  right: 81px;
  bottom: 0%;
  max-height: 400px;
}

.group-w-header-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.group-w-header-copy.ava {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.group-w-header-copy.ava.mb30 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.image-7 {
  width: 23px;
  margin-right: 5px;
}

.link-2-campagins {
  margin-left: 23px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #009aff;
  text-decoration: none;
  cursor: pointer;
}

.link-2-campagins:hover {
  color: #009aff;
  text-decoration: underline;
}

.link-2-campagins.small-link {
  font-size: 14px;
  font-weight: 300;
}

.links-campagins {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.stars-block {
  margin-top: 13px;
}

.image-8 {
  width: 20px;
  cursor: pointer;
}

.chat-hint-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-btn-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 29px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.image-9 {
  width: 24px;
  margin: 3px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.image-9:hover {
  border-radius: 2px;
  background-color: #eee;
}

.smileys-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.blue-button-copy {
  padding: 9px 10px;
  border-radius: 3px;
  background-color: #009aff;
  -webkit-transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  transition: all 150ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.blue-button-copy:hover {
  background-color: #0889dd;
}

.blue-button-copy:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.blue-button-copy.add-indent {
  margin-bottom: 15px;
}

.blue-button-copy.left-indent {
  margin-left: 10px;
}

.blue-button-copy.min-h {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 15px;
}

.blue-button-copy.min-h-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 15px;
}

.blue-button-copy.full-w {
  width: 100%;
  text-align: center;
}

.blue-button-copy.right-margin {
  margin-right: 20px;
}

.blue-button-copy.right-margin.whatsapp-btn {
  background-color: #4ac959;
}

.blue-button-copy.right-margin.whatsapp-btn:hover {
  background-color: #3aaa48;
}

.blue-button-copy.right-margin.viber-btn {
  background-color: #665cac;
}

.blue-button-copy.right-margin.viber-btn:hover {
  background-color: #4c4385;
}

.blue-button-copy.facebook-btn {
  background-color: #0075ee;
}

.blue-button-copy.facebook-btn:hover {
  background-color: #0063c9;
}

.blue-button-copy.telegram-btn {
  background-color: #08c;
}

.blue-button-copy.telegram-btn:hover {
  background-color: #0075af;
}

.blue-button-copy.top-indent {
  margin-top: 20px;
}

.blue-button-copy.transparent {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #006ef1;
  background-color: transparent;
  color: #006ef1;
}

.smile-tab-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  padding: 0px 0px 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 3px solid transparent;
  background-color: transparent;
}

.smile-tab-link:hover {
  border-bottom-color: #009aff;
}

.smile-tab-link.w--current {
  border-bottom-color: #009aff;
  background-color: transparent;
}

.tabs-menu-1201 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tabs-content-1201 {
  overflow: auto;
  max-height: 320px;
  padding: 5px 3px;
  border-top: 1px solid #eee;
  border-radius: 3px;
}

.smileys-main-wrap {
  margin-bottom: 10px;
}

.text-block-4 {
  font-size: 16px;
  font-weight: 400;
}

.items-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  max-height: 65vh;
  margin-right: -2%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.bg-preview-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 23%;
  height: 200px;
  min-height: 200px;
  min-width: 200px;
  margin-top: 2%;
  margin-right: 2%;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
}

.bg-preview-wrap:hover {
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.background-video {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 250px;
  height: 300px;
}

.bg-video {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hover-div {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.form-block-2 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row.no-margin {
  margin-bottom: 0px;
}

.row.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.al-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.row.align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.show-group-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chat-avatar-div {
  position: relative;
  margin-left: -10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tooltip-wrap {
  position: absolute;
  left: 50%;
  top: auto;
  right: auto;
  bottom: 130%;
  display: block;
  margin: auto;
  opacity: 1;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.chat-pre-avatars-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.grid {
  position: relative;
  margin-top: 30px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: ".";
  -ms-grid-columns: auto 0px 1fr 0px 1fr 0px 1fr 0px 1fr;
  grid-template-columns: auto 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto 0px auto;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
}

.grid-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #ddd;
}

.tariff-plan-label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
}

.tariff-plan-label.add-margin {
  margin-bottom: 5px;
  line-height: 18px;
}

.tariff-plan-label.add-margin.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.tariff-plan-label.user-link {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.tariff-plan-label.user-link:hover {
  color: #009aff;
}

.tariff-plan-label.top-indent {
  margin-top: 15px;
}

.tariff-plan-label.add-margin-2 {
  margin-right: 10px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 18px;
}

.tariff-plan-label.add-margin-2.empty {
  overflow: hidden;
  width: 0px;
  height: auto;
  max-height: 18px;
}

.tariff-plan-label.center {
  text-align: center;
}

.tariff-plan-label.add-indent {
  margin-left: 10px;
}

.tariff-plan-label.bottom-indent {
  margin-bottom: 15px;
}

.tariff-plan-label.top-margin {
  margin-top: 10px;
}

.price-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 28px;
  line-height: 35px;
  font-weight: 700;
}

.price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.grid-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #ddd;
}

.grid-block.top-block {
  position: -webkit-sticky;
  position: sticky;
  top: 59px;
  z-index: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-style: none;
  background-color: #fff;
}

.grid-block.highlight {
  background-color: #cfffdc;
}

.plan-label {
  font-size: 14px;
  line-height: 23px;
}

.plan-label.min-indent {
  margin-bottom: 5px;
  line-height: 16px;
}

.plan-label.checkmark {
  color: #38c75d;
  font-weight: 900;
}

.plan-label.add-indent {
  margin-left: 10px;
}

.plan-label.center {
  text-align: center;
}

.plan-label.center.add-margin {
  margin-top: 10px;
  margin-bottom: 20px;
}

.plan-label.add-padding {
  padding: 5px 10px;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  line-height: 17px;
  cursor: pointer;
}

.plan-label.add-padding:hover {
  background-color: #f2f6f9;
}

.tariff-name {
  color: #009aff;
  font-size: 18px;
  font-weight: 500;
}

.price-text {
  font-size: 28px;
  line-height: 35px;
  font-weight: 700;
}

.price-label {
  font-size: 14px;
  line-height: 27px;
  font-weight: 300;
}

.price-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.price-bg-block {
  position: -webkit-sticky;
  position: sticky;
  top: 59px;
  z-index: 1;
  margin-top: 0px;
  margin-bottom: -1px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 5px 8px -2px hsla(0, 0%, 60%, 0.1);
}

.tariff-period-choose {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 44px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 11px 21px 11px 37px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd #e1e2eb #e1e2eb;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.tariff-chose-button-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #38c75d;
  box-shadow: none;
  opacity: 1;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.tariff-chose-button-2:hover {
  background-color: #26b94c;
}

.monthly-text {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.annually-text {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.monthly-price {
  display: none;
  opacity: 0;
  -webkit-transform: translate(0px, 20px);
  -ms-transform: translate(0px, 20px);
  transform: translate(0px, 20px);
}

.top-plan-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 70px;
  margin-bottom: 50px;
  padding: 40px 90px 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
}

.top-plan-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 25px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-plan-div.min-indent {
  margin-right: 10px;
}

.top-plan-columns {
  width: 70%;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-group-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-group-row.width-left-margin-2 {
  margin-top: 10px;
  margin-left: -20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.sent-notifications-table-wrap {
  position: relative;
  overflow: auto;
  padding-bottom: 15px;
}

.button-1 {
  padding: 8px 18px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #006ef1;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #006ef1;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
}

.button-1:hover {
  background-color: #006ef1;
  color: #fff;
}

.button-1.small-btn {
  margin-bottom: 5px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
}

.button-1.small-btn.right-indent {
  display: block;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.button-1.small-btn.right-indent.delete-btn {
  border-color: #e61515;
  color: #e61515;
}

.button-1.small-btn.right-indent.delete-btn:hover {
  background-color: #e61515;
  color: #fff;
}

.visitors-info {
  margin-right: 10px;
  margin-bottom: 0px;
  padding: 7px 15px;
  border: 1px solid #135082;
  border-radius: 3px;
  background-color: #044172;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
}

.visitors-info.current-plan-info {
  margin-bottom: 10px;
  border-color: #e0e0e0;
  background-color: #f2f6f9;
  color: #333;
  text-align: center;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  padding: 8px 15px;
  border: 1px solid #1aa310;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  color: #1aa310;
  text-decoration: none;
  cursor: pointer;
}

.button-2:hover {
  background-color: #1aa310;
  color: #fff;
}

.button-2.w--current {
  background-color: #1aa310;
  color: #fff;
}

.button-2.small-btn-2 {
  margin-right: 0px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
}

.table-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  padding: 10px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.table-col.column-1 {
  width: 140px;
}

.table-col.expand-row-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-group-titile-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-group-titile-wrap.add-bottom-margin {
  margin-bottom: 15px;
}

.grey-span {
  color: #9e9e9e;
  font-size: 12px;
}

.table-bold-text {
  font-weight: 700;
}

.plan-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-group-title {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #033156;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.sent-notifications-table-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.sent-notifications-table-row:hover {
  background-color: #f9f9f9;
}

.badge {
  min-width: 25px;
  padding: 1px 7px;
  border-radius: 3px;
  background-color: #033156;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.badge.badge-success {
  background-color: #1fc113;
}

.badge.badge-success.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.badge.badge-warning {
  background-color: #f19e17;
}

.badge.badge-danger {
  background-color: #f55;
}

.badge.badge-danger.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.badge.badge-btn {
  position: absolute;
  left: auto;
  top: -10px;
  right: -10px;
  bottom: auto;
  background-color: #f55;
  font-size: 12px;
  line-height: 18px;
}

.badge.badge-btn.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.badge.badge-default {
  background-color: #eee;
  color: #333;
}

.sent-notifications-table-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  background-color: #eee;
}

.plan-info-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.plan-info-block-2.top-align {
  margin-top: 15px;
  margin-bottom: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.table-header-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  padding: 10px 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #e0e0e0;
  color: #033156;
  font-weight: 500;
}

.table-header-col.h-column-1 {
  width: 140px;
}

.table-header-col.expand-header-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.plan-info-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.btn-awesome-span {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

.account-paragraph.no-indent {
  margin-right: 10px;
  margin-bottom: 0px;
}

.plus-span2 {
  display: inline-block;
  font-size: 12px;
}

.sorting {
  width: 20px;
  margin-left: 5px;
  -webkit-transition: opacity 100ms ease;
  transition: opacity 100ms ease;
  font-family: Fontawesome, sans-serif;
  color: #999;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.sorting:hover {
  color: #333;
}

.form-group-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.333%;
  min-height: 30px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-group-column.half-w {
  width: 50%;
}

.form-group-column.half-w.left-align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.button-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #1aa310;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  text-align: center;
  cursor: pointer;
}

.button-3:hover {
  background-color: #098700;
}

.button-3.small-btn-3 {
  padding: 6px 10px;
  font-size: 12px;
  line-height: 18px;
}

.form-group {
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  background-color: #fff;
}

.sent-notifications-table {
  overflow: visible;
  border-style: solid none none solid;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 3px;
}

.bottom-btns-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.field-label-3 {
  margin-bottom: 5px;
  color: #033156;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.select-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-group-column-2 {
  width: 66.66%;
}

.form-group-column-2.third-width {
  width: 33.33%;
  margin-left: 20px;
}

.card-info-popup {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1100;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.title-span {
  font-weight: 400;
}

.tariff-name-span {
  color: #009aff;
  font-weight: 400;
}

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-plan-features {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}

.div-block-14 {
  margin-top: 20px;
}

.page-subheader-2 {
  margin-top: 10px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}

.page-subheader-2.add-margin {
  margin-bottom: 5px;
}

.div-block-15 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col-3 {
  width: 30%;
}

.col-9 {
  width: 68%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.btns-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-block {
  position: relative;
}

.btns-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.btns-block.left-justify {
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.paragraph-w {
  max-width: 960px;
  margin-left: 20px;
}

.input-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.meeting-url {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  padding: 6px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid none solid solid;
  border-width: 1px;
  border-color: #ddd;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #eee;
  color: #333;
}

.meeting-url-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.regular {
  font-weight: 300;
}

.page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f8faff;
}

.page-wrap.normal-content {
  overflow: visible;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f8faff;
}

.page-wrap.normal-content {
  overflow: visible;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #f8faff;
}

.meeting-avatar {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 100px;
  background-color: #f2f6f9;
  background-image: url('./images/forum-user.svg');
  background-position: 50% 50%;
  background-size: 71%;
  background-repeat: no-repeat;
}

.book-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.calendar-wrap {
  width: auto;
  padding: 30px;
  border-radius: 3px;
  background-color: #009aff;
  color: #fff;
}

.form {
  width: auto;
}

.form-block-3 {
  width: 100%;
  margin-bottom: 0px;
}

.calendar-block {
  max-width: 400px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px;
}

.calendar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.calendar-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.calendar-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 14.28%;
  padding: 10px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  font-family: Gotham, sans-serif;
}

.calendar-item.blocked {
  opacity: 0.5;
  cursor: not-allowed;
}

.calendar-item.available-date {
  width: 14.28%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid transparent;
  background-color: #009aff;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
  cursor: pointer;
}

.calendar-item.available-date:hover {
  border: 1px solid #fff;
}

.calendar-item.available-date:active {
  background-color: rgba(0, 49, 107, 0.5);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.day-of-week {
  font-weight: 500;
  text-transform: uppercase;
}

.time-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-w {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 15px;
  background-color: #fff;
}

.time-btn-field {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
}

.time-btn-label {
  position: relative;
  z-index: 2;
  font-family: Gotham, sans-serif;
  font-weight: 500;
}

.time-radio-btn {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-left: 0px;
  border-style: solid;
  border-color: #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.time-radio-btn:hover {
  border-style: solid;
  border-color: #009aff;
  color: #009aff;
}

.time-radio-btn.w--redirected-checked {
  width: 100%;
  height: 100%;
  border-style: none;
  border-radius: 3px;
  background-color: #009aff;
  box-shadow: 0 0 0 -20px transparent;
  color: #fff;
}

.time-picker {
  display: flex;
  justify-content: space-between;
  min-width: 70px;
}

.time-picker:focus .time-block .active {
  background-color: #ddebf7;
}

.time-block {
  display: flex;
  overflow: auto;
  max-height: 250px;
  margin-left: 10px;
  border-radius: 3px;
  box-shadow: none;
}

.block-with-bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  padding: 30px 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 3px;
  background-color: #f2f6f9;
  text-align: center;
}

.time-zone-settings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.max-w {
  max-width: 300px;
}

.popup5-w-messenger {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup5-w-instagram {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup5-w-email {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup5-w-whatsapp-disc {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.popup5-w-phone {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.cp-info-header-2 {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
}

.cp-info-div {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.cp-info-div-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.description-text {
  max-width: 250px;
  margin-bottom: 10px;
}

.description-text.no-indent {
  margin-bottom: 0px;
}

.info-content-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.chart-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-shape {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #009aff), color-stop(0, #173a63));
  background-image: linear-gradient(180deg, #009aff 50%, #173a63 0);
}

.chart-white-center {
  width: 80%;
  height: 80%;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
}

.chart-main-numbers {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.chart-info-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 8px 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-item-color {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 2px;
  background-color: #009aff;
}

.chart-item-color.color-2 {
  background-color: #173a63;
}

.fanpage-info-w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fanpage-connected {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
}

.header-channel-icon {
  width: 35px;
  margin-right: 10px;
}

@media screen and (max-width: 991px) {
  .main-wrapper {
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-navbar {
    width: 100%;
    min-width: 100%;
    height: auto;
    min-height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .nav-link {
    padding: 8px 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .nav-links-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .nav-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .nav-links-w {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .logo-icon {
    width: 30px;
  }
  .brand {
    margin: 5px 15px;
  }
  .nav-link-name {
    position: static;
    left: 0%;
    display: block;
    margin-top: 0px;
    margin-left: 5px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
  }
  .left-sidebar-w {
    width: 200px;
  }
  .search-form-block {
    margin-right: 15px;
  }
  .page-container {
    max-width: 100vw;
  }
  .filter-drop-tog {
    padding: 6px 5px 5px 10px;
    font-size: 13px;
    line-height: 16px;
  }
  .filter-drop-tog.awesome-font {
    width: 30px;
    padding-left: 5px;
  }
  .filter-drop-tog.like-btn {
    font-size: 13px;
  }
  .filter-drop-tog.like-btn.norm-paddings {
    padding-top: 8px;
    padding-right: 10px;
    padding-bottom: 6px;
  }
  .filter-drop-tog.no-arrow {
    padding-top: 10px;
    padding-right: 8px;
    padding-bottom: 8px;
    font-size: 12px;
  }
  .filter-drop-tog.color-toggle {
    width: 30px;
  }
  .filter-drop-tog.chat-toggle {
    width: 30px;
  }
  .filter-drop.left-indent {
    margin-left: 10px;
  }
  .dropdown-list.w--open {
    right: 0px;
    padding: 10px;
  }
  .dropdown-link {
    font-size: 12px;
    line-height: 15px;
  }
  .top-navbar {
    top: 36px;
    height: 53px;
    min-height: 53px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .page-title {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 22px;
  }
  .page-title.align-center {
    margin-bottom: 15px;
  }
  .top-menu-w {
    margin-left: 10px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .top-nav-link {
    padding: 7px;
  }
  .top-nav-link-icon {
    width: 16px;
  }
  .account-tog {
    padding: 10px;
    font-size: 16px;
  }
  .account-tog.with-icon {
    padding: 7px;
  }
  .account-dropdown {
    margin-left: 0px;
  }
  .page-content.normal-content {
    padding: 15px;
  }
  .page-content.normal-content {
    padding: 15px;
  }
  .right-sidebar-w {
    width: 200px;
    padding: 10px;
  }
  .chat-window {
    padding: 10px 15px;
  }
  .chat-item {
    padding: 8px;
  }
  .chat-avatar {
    width: 25px;
    height: 25px;
  }
  .chat-avatar-icon {
    font-size: 13px;
  }
  .identity-row {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .user-identity {
    font-size: 13px;
    font-weight: 500;
  }
  .user-location.no-indent {
    margin-left: 5px;
  }
  .chat-last-message-preview {
    font-size: 12px;
  }
  .chat-header-w {
    padding: 10px;
  }
  .new-message-wrapper {
    padding-top: 0px;
  }
  .send-text-area {
    font-size: 13px;
  }
  .new-mess-action {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .chat-user-name {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .chat-header-div-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .location-div {
    margin-left: 0px;
  }
  .day-divider {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .divider-text {
    font-size: 11px;
    line-height: 16px;
  }
  .chat-message-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .chat-avatar-2 {
    width: 25px;
    height: 25px;
    padding-top: 1px;
    font-size: 16px;
  }
  .chat-date {
    font-size: 11px;
    line-height: 12px;
  }
  .chat-message-p {
    font-size: 13px;
    line-height: 18px;
  }
  .visitor-info-field {
    font-size: 13px;
    line-height: 14px;
  }
  .visitor-info-field.visitor-name {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 16px;
  }
  .visitor-details-w {
    margin-top: 10px;
  }
  .visitor-details-header-w {
    padding: 10px 10px 8px;
  }
  .visitor-details-text {
    font-size: 12px;
    line-height: 14px;
  }
  .visitor-details-info {
    padding-right: 10px;
    padding-left: 10px;
  }
  .top-nav-left-container {
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .a-tab-link {
    padding: 10px 15px 9px;
    font-size: 13px;
    line-height: 16px;
  }
  .a-tabs-menu {
    margin-bottom: 15px;
  }
  .a-tabs.top-indent {
    margin-top: 25px;
  }
  .campaign-preview-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cp-avatar-div {
    padding: 0px;
  }
  .cp-avatar {
    width: 40px;
    height: 40px;
  }
  .cp-info-w {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .cp-name-div {
    margin-right: 10px;
    margin-left: 10px;
  }
  .cp-name-link {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
  }
  .cp-url {
    font-size: 13px;
  }
  .cp-control-div {
    margin-right: 0px;
    margin-left: auto;
  }
  .dt-icon {
    width: 16px;
  }
  .cp-info-block {
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .cp-info-item {
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .cp-info-item.no-shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-info-count {
    margin-top: 0px;
    font-size: 20px;
    line-height: 18px;
  }
  .cp-info-header {
    margin-bottom: 5px;
    font-size: 13px;
  }
  .cp-info-header-icon {
    margin-right: 3px;
    font-size: 16px;
    line-height: 18px;
  }
  .cp-info {
    font-size: 13px;
    line-height: 18px;
  }
  .switch-wrap {
    width: 42px;
    height: 23px;
  }
  .nav-link-icon {
    width: 16px;
  }
  .awesome-icon.big-icon {
    line-height: 18px;
  }
  .step-btn {
    padding: 5px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 12px;
    line-height: 17px;
  }
  .step-btn.solid-bg {
    border-style: none;
  }
  .arrow-line {
    width: 8px;
  }
  .arrow-block {
    font-size: 10px;
  }
  .top-arr-line {
    width: 6px;
  }
  .content-white-block {
    padding: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .content-white-block.new-col-4 {
    top: 100px;
    width: 370px;
    margin-left: 15px;
  }
  .content-white-block.hor-flex-2 {
    margin-bottom: 15px;
    padding: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .content-white-block.no-wide {
    width: 100%;
    padding: 30px;
  }
  .content-white-block.top-indent {
    margin-top: 15px;
  }
  .content-white-block.center-container {
    width: 100%;
    padding: 30px;
  }
  .button {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
  }
  .button.left-indent {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .button.bottom-indent {
    margin-bottom: 20px;
  }
  .editor-tab-link {
    padding: 15px 12px 12px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 13px;
    line-height: 18px;
  }
  .popup-content-wrap {
    padding: 20px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .text-field-2 {
    height: 38px;
    font-size: 12px;
  }
  .popup-header-div {
    padding-right: 20px;
    padding-left: 20px;
  }
  .style-field-label {
    font-size: 13px;
    line-height: 18px;
  }
  .tabs-menu-4 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .select-field-2 {
    height: 38px;
    font-size: 12px;
  }
  .select-field-2.half-w {
    padding-right: 10px;
    padding-left: 10px;
  }
  .select-field-2.half-w.left-indent {
    margin-left: 15px;
  }
  .content-column {
    width: 100%;
    margin-right: 0px;
  }
  .blue-button {
    padding: 7px 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .blue-button {
    padding: 7px 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .blue-button.right-margin.first-item {
    margin-bottom: 10px;
  }
  .blue-button.right-margin.whatsapp-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.viber-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.messenger-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.telegram-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.instagram-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.email-btn {
    margin-right: 15px;
  }
  .blue-button.right-margin.call-btn {
    margin-right: 15px;
  }
  .blue-button.add-indent2 {
    margin-bottom: 0px;
  }
  .channel-icon {
    width: 40px;
  }
  .settings-item-label {
    line-height: 16px;
  }
  .settings-item-label.user-link {
    line-height: 16px;
  }
  .node-settings-input {
    font-size: 13px;
  }
  .node-settings-input.search-field {
    height: 33px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    background-position: 5px 50%;
    background-size: 14px;
    font-size: 13px;
  }
  .settings-select {
    height: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 13px;
    line-height: 18px;
  }
  .settings-select.narrow-select {
    width: 120px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .settings-select.no-indent {
    font-size: 13px;
  }
  .settings-select.left-indent-2 {
    width: auto;
    min-width: auto;
  }
  .page-content-header-div.hor-flex {
    margin-bottom: 15px;
  }
  .page-header {
    font-size: 18px;
  }
  .page-header.right-indent {
    margin-right: 10px;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .page-subheader {
    line-height: 20px;
  }
  .page-subheader.add-margin-2 {
    margin-bottom: 15px;
  }
  .tabs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .left-float-menu {
    position: relative;
    top: auto;
    margin-right: -1%;
    padding-right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .left-tab-link {
    width: 19%;
    margin-right: 1%;
    margin-bottom: 8px;
    padding: 11px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
  }
  .group-title {
    font-size: 15px;
    line-height: 18px;
  }
  .group-w {
    margin-top: 15px;
    padding: 15px;
  }
  .show-group-content {
    margin-top: 15px;
    padding-top: 15px;
  }
  .show-group-content.no-top-margin {
    padding-top: 15px;
  }
  .show-group-content.no-top-margin2 {
    margin-bottom: 15px;
    padding-top: 0px;
  }
  .group-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .group-row.horizontal-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .group-row.more-margin {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .group-row.vertical-2 {
    margin-top: 10px;
  }
  .col-6 {
    width: auto;
  }
  .col-6.full-w {
    width: 48%;
  }
  .field-label-2 {
    font-size: 13px;
    line-height: 18px;
  }
  .column-text {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 19px;
  }
  .awes-icon.tooltip-icon {
    width: 14px;
    height: 14px;
    border-width: 1px;
    font-size: 9px;
    line-height: 17px;
  }
  .error-message {
    line-height: 14px;
  }
  .tooltip-div.tooltip-block {
    width: 200px;
  }
  .col-12 {
    width: auto;
  }
  .col-4 {
    width: auto;
  }
  .big-link-block {
    padding: 25px;
  }
  .link-block-icon {
    width: 100px;
    margin-right: 20px;
  }
  .link-block-icon.confirmed-img {
    width: 100px;
    margin-bottom: 20px;
  }
  .avatar-add-wrap {
    width: 60px;
    height: 60px;
  }
  .avatar-add-wrap.big-wrap {
    width: 70px;
    height: 70px;
  }
  .control-icon.upload {
    font-size: 20px;
  }
  .control-icon.upload-hide {
    font-size: 20px;
  }
  .text-field {
    margin-bottom: 12px;
  }
  .sign-header {
    font-size: 20px;
  }
  .popup-subheader {
    margin-top: 5px;
    font-size: 16px;
    line-height: 22px;
  }
  .logo-link {
    margin-bottom: 25px;
    font-size: 26px;
  }
  .sign-container {
    width: 100%;
    height: auto;
    padding: 50px 25% 30px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .sign-form-block {
    margin-bottom: 20px;
  }
  .or-block {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .top-nav-right-container {
    display: block;
  }
  .top-right-menu {
    margin-left: 5px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .popup-container-2.no-max-w {
    padding: 30px;
  }
  .table-filter-block {
    margin-right: 15px;
  }
  .table-row {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1000px;
    min-width: 900px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .channels-settings .table-row {
    min-width: auto;
  }
  .table-row.table-header {
    padding: 15px;
  }
  .table-row.in-view {
    max-width: none;
    min-width: auto;
  }
  .table-col1 {
    padding-right: 10px;
  }
  .table-col1.check-col {
    padding-right: 15px;
  }
  .table-settings {
    padding: 15px;
  }
  .table-w {
    overflow: auto;
  }
  .details-header {
    font-size: 10px;
    line-height: 14px;
  }
  .action-btn {
    font-size: 13px;
    line-height: 18px;
  }
  .page-link {
    font-size: 13px;
    line-height: 18px;
  }
  .div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .input-div-2 {
    width: auto;
  }
  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .custom-dropdown-list {
    padding-right: 15px;
    padding-left: 15px;
  }
  .custom-dropdown-toggle {
    padding: 15px;
  }
  .down-arrow {
    width: 16px;
  }
  .show-group-content-2 {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .col-4-copy {
    width: auto;
  }
  .chat-preview-w {
    width: 340px;
    padding-top: 15px;
  }
  .chat-preview {
    width: 100%;
  }
  .chat-pre-header {
    padding-right: 20px;
    padding-left: 20px;
  }
  .chat-conversation-w {
    padding-right: 20px;
    padding-left: 20px;
  }
  .chat-input-group {
    padding-right: 20px;
    padding-left: 20px;
  }
  .white-input {
    font-size: 13px;
  }
  .white-input.search-field {
    height: 33px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    background-position: 5px 50%;
    background-size: 14px;
    font-size: 13px;
  }
  .chat-preview-2 {
    width: 100%;
  }
  .content-white-block-2 {
    padding: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .content-white-block-2.hor-flex-2 {
    margin-bottom: 15px;
    padding: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .content-white-block-2.new-col-4 {
    top: 100px;
    width: 370px;
    margin-left: 15px;
  }
  .blue-button-2 {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .action-btn-2 {
    font-size: 13px;
    line-height: 18px;
  }
  .node-settings-input-2 {
    font-size: 13px;
  }
  .node-settings-input-2.search-field {
    height: 33px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    background-position: 5px 50%;
    background-size: 14px;
    font-size: 13px;
  }
  .settings-select-2 {
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 13px;
    line-height: 18px;
  }
  .settings-select-2.no-indent {
    font-size: 13px;
  }
  .campaign-preview-item-2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cp-info-w-2 {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .top-navbar-2 {
    top: 36px;
    min-height: 53px;
    padding-left: 10px;
  }
  .a-tab-link-2 {
    padding: 10px 15px 9px;
    font-size: 13px;
    line-height: 16px;
  }
  .account-tog-2 {
    padding: 10px;
    font-size: 16px;
  }
  .account-tog-2.with-icon {
    padding: 7px;
  }
  .button-3 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
  }
  .button-3.left-indent {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-info-header-icon-2 {
    margin-right: 3px;
    font-size: 16px;
    line-height: 18px;
  }
  .dropdown-link-2 {
    font-size: 12px;
    line-height: 15px;
  }
  .top-menu-w-2 {
    margin-left: 10px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .step-btn-2 {
    padding: 5px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 12px;
    line-height: 17px;
  }
  .filter-drop-tog-2 {
    padding: 6px 5px 5px 10px;
    font-size: 13px;
    line-height: 16px;
  }
  .filter-drop-tog-2.no-arrow {
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 6px;
    font-size: 12px;
  }
  .filter-drop-tog-2.chat-toggle {
    width: 30px;
  }
  .filter-drop-tog-2.color-toggle {
    width: 30px;
  }
  .filter-drop-tog-2.awesome-font {
    width: 30px;
    padding-left: 5px;
  }
  .top-arr-line-2 {
    width: 6px;
  }
  .arrow-line-2 {
    width: 8px;
  }
  .cp-name-text {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
  }
  .page-title-2 {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 22px;
  }
  .ava-block {
    left: auto;
    top: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ava-grey {
    width: 6px;
    height: 6px;
  }
  .ava-orange {
    width: 6px;
    height: 6px;
  }
  .switch-wrap-3 {
    width: 42px;
    height: 23px;
  }
  .position-relative {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 10px;
  }
  .popup-container-block2.no-margin-top {
    margin-top: 0px;
  }
  .chat-avatar-2-2 {
    width: 25px;
    height: 25px;
    padding-top: 1px;
    font-size: 16px;
  }
  .ava-title {
    font-size: 15px;
    line-height: 18px;
  }
  .table-row-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1000px;
    min-width: 900px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .step-2-img {
    width: 25px;
    height: 25px;
    padding-top: 1px;
    font-size: 16px;
  }
  .dropdown-block {
    font-size: 12px;
    line-height: 15px;
  }
  .drop-white-block {
    padding: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .drop-white-block.new-col-4 {
    top: 100px;
    width: 370px;
    margin-left: 15px;
  }
  .drop-white-block.hor-flex-2 {
    margin-bottom: 15px;
    padding: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .settings-group-label {
    line-height: 16px;
  }
  .settings-group-label.user-link {
    line-height: 16px;
  }
  .main-navbar-2 {
    width: 100%;
    height: auto;
    min-height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .nav-link-name-2 {
    position: static;
    left: 0%;
    display: block;
    margin-top: 0px;
    margin-left: 5px;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
  }
  .left-tab-link-2 {
    width: 19%;
    margin-right: 1%;
    margin-bottom: 8px;
    padding: 11px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
  }
  .nav-link-2 {
    padding: 8px 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .chat-input-group-2 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .new-mess-action-2 {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .show-group-content-2-2 {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  .send-text-area-2 {
    font-size: 13px;
  }
  .chat-pre-header-2 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .upload-button {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .button-4 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
  }
  .button-4.left-indent {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .section {
    padding-right: 15px;
    padding-left: 15px;
  }
  .chat-user-name-2 {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .user-identity-2 {
    font-size: 13px;
    font-weight: 500;
  }
  .chat-avatar-2 {
    width: 25px;
    height: 25px;
  }
  .visitor-details-header-w-2 {
    padding: 10px 10px 8px;
  }
  .divider-2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .chat-header-w-2 {
    padding: 10px;
  }
  .right-sidebar-w-2 {
    width: 200px;
    padding: 10px;
  }
  .chat-item-2 {
    padding: 8px;
  }
  .visitor-details-w-2 {
    margin-top: 10px;
  }
  .left-sidebar-w-2 {
    width: 200px;
  }
  .chat-date-2 {
    font-size: 11px;
    line-height: 12px;
  }
  .divider-text-2 {
    font-size: 11px;
    line-height: 16px;
  }
  .top-navbar-bot {
    min-height: 53px;
    padding-left: 10px;
  }
  .blue-button-copy {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .items-wrapper {
    margin-top: 0px;
    margin-right: -2%;
  }
  .bg-preview-wrap {
    width: 31.33333%;
    height: 170px;
    min-height: auto;
    margin-top: 0%;
    margin-right: 2%;
    margin-bottom: 2%;
  }
  .hover-div {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .row.horizontal-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .show-group-col {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .grid {
    margin-top: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
  }
  .grid-label {
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tariff-plan-label {
    font-size: 13px;
    line-height: 16px;
  }
  .tariff-plan-label.user-link {
    line-height: 16px;
  }
  .price-w {
    font-size: 26px;
    line-height: 31px;
  }
  .grid-block.top-block {
    top: 89px;
  }
  .plan-label {
    font-size: 14px;
    line-height: 19px;
  }
  .plan-label.add-indent {
    margin-left: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .plan-label.center.add-margin {
    margin-bottom: 15px;
  }
  .price-bg-block {
    top: 89px;
  }
  .tariff-period-choose {
    width: 210px;
    height: 38px;
    margin-top: 20px;
    padding-right: 14px;
    padding-left: 25px;
    font-size: 13px;
    line-height: 18px;
  }
  .top-plan-w {
    padding: 30px 30px 20px;
  }
  .top-plan-div {
    margin-right: 20px;
  }
  .form-group-row {
    margin-top: 15px;
  }
  .form-group-row.width-left-margin-2 {
    margin-left: -15px;
  }
  .sent-notifications-table-wrap {
    overflow: auto;
    padding-bottom: 15px;
  }
  .button-1 {
    padding: 7px 10px;
    font-size: 12px;
    line-height: 18px;
  }
  .visitors-info {
    margin-left: 10px;
    padding: 5px 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 13px;
  }
  .visitors-info.current-plan-info {
    margin-right: 8px;
    margin-left: 0px;
    padding-right: 6px;
    padding-left: 6px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .button-2 {
    margin-right: 10px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 18px;
  }
  .table-col {
    width: 100px;
    font-size: 11px;
    line-height: 16px;
  }
  .table-col.column-1 {
    width: 130px;
  }
  .table-col.expand-row-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .grey-span {
    font-size: 10px;
  }
  .plan-info-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form-group-title {
    font-size: 14px;
    line-height: 18px;
  }
  .badge {
    min-width: 20px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
    line-height: 18px;
  }
  .table-header-col {
    width: 100px;
    font-size: 12px;
  }
  .table-header-col.h-column-1 {
    width: 130px;
  }
  .plan-info-div {
    margin-top: 10px;
  }
  .account-paragraph {
    line-height: 18px;
  }
  .plus-span2 {
    top: 0px;
    font-size: 10px;
  }
  .sorting {
    font-size: 13px;
  }
  .form-group-column {
    padding-left: 15px;
  }
  .button-3 {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 18px;
  }
  .form-group {
    margin-bottom: 15px;
    padding: 15px;
  }
  .sent-notifications-table {
    position: static;
    overflow: visible;
  }
  .bottom-btns-wrap {
    margin-bottom: 40px;
  }
  .field-label-3 {
    font-size: 13px;
    line-height: 18px;
  }
  .form-group-column-2.third-width {
    margin-left: 15px;
  }
  .top-plan-features {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .div-block-14 {
    margin-top: 10px;
  }
  .page-subheader-2 {
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
  }
  .column-2 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .column-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .col-3 {
    width: 25%;
  }
  .col-9 {
    width: 73%;
  }
  .paragraph-w {
    margin-right: 0px;
  }
  .page-wrap.normal-content {
    padding: 15px;
  }
  .page-wrap.normal-content {
    padding: 15px;
  }
  .block-with-bg {
    padding: 20px;
  }
  .popup-subheader {
    margin-top: 5px;
    font-size: 16px;
    line-height: 22px;
  }
  .cp-info-header-2 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .cp-info-div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .cp-info-div-2 {
    padding: 15px 10px;
  }
  .description-text {
    font-size: 12px;
    line-height: 16px;
  }
  .info-content-w {
    padding-right: 10px;
    padding-left: 10px;
  }
  .chart-block {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
  .chart-info-item {
    margin-right: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .header-channel-icon {
    width: 30px;
    margin-right: 5px;
  }
}


@media screen and (max-width: 767px) {
  .nav-link {
    padding: 10px 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .nav-links-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .nav-menu {
    background-color: #1d262d;
  }
  .nav-links-w {
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .logo-icon {
    width: 30px;
  }
  .brand {
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 0px;
  }
  .nav-link-name {
    margin-left: 10px;
    font-size: 12px;
  }
  .left-sidebar-w {
    width: 200px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .search-field {
    height: 34px;
    font-size: 13px;
  }
  .search-form-block {
    margin-right: 5px;
  }
  .filter-drop-tog {
    padding-top: 5px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 12px;
    line-height: 14px;
  }
  .filter-drop-tog.like-btn {
    padding-top: 7px;
    padding-bottom: 6px;
  }
  .filter-drop-tog.like-btn.norm-paddings {
    font-size: 12px;
    line-height: 14px;
  }
  .filter-drop-tog.no-arrow {
    padding-top: 8px;
    padding-bottom: 6px;
    line-height: 16px;
  }
  .filter-drop {
    margin-right: 5px;
  }
  .filter-drop.no-indent-2 {
    margin-right: 10px;
    margin-left: 0px;
  }
  .filter-drop.left-indent-2 {
    margin-left: 0px;
  }
  .dropdown-link {
    padding-right: 15px;
  }
  .top-navbar {
    top: 35px;
    height: auto;
    padding-left: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .top-navbar.no-wrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .filter-drop-w {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .page-title {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 20px;
  }
  .page-title.wrap-title {
    margin-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .page-title.breaking {
    margin-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 15px;
    line-height: 18px;
    white-space: normal;
  }
  .page-title.align-center {
    line-height: 24px;
  }
  .top-menu-w {
    margin-left: 0px;
    padding-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-left-style: none;
    background-color: transparent;
  }
  .top-control-w {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .right-sidebar-w {
    width: auto;
    border-top: 1px solid #eee;
    border-left-style: none;
  }
  .chat-avatar-2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding-top: 2px;
    font-size: 14px;
  }
  .avaibility-block2 .chat-avatar-2 {
    width: 25px;
    height: 25px;
  }
  .top-nav-left-container {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .a-tab-link {
    padding: 8px 10px;
  }
  .a-tabs.top-indent {
    margin-top: 20px;
  }
  .campaign-preview-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cp-avatar {
    width: 40px;
    height: 40px;
  }
  .cp-info-w {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  .cp-info-w._1200 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .cp-name-link {
    font-size: 16px;
    line-height: 16px;
  }
  .cp-url {
    line-height: 16px;
  }
  .dt-icon {
    width: 15px;
  }
  .cp-info-block {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .cp-info-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .cp-info-item.no-shrink {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-info-count {
    font-size: 18px;
  }
  .cp-info-header {
    line-height: 18px;
  }
  .cp-info {
    font-size: 12px;
    white-space: pre-wrap;
  }
  .switch-text-wrap {
    font-size: 11px;
  }
  .nav-link-icon {
    width: 16px;
  }
  .awesome-icon.big-icon {
    font-size: 11px;
    line-height: 14px;
  }
  .step-btn.add-indent {
    margin-left: 10px;
  }
  .step-btns-w.no-bottom-indent {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .content-white-block.new-col-4 {
    position: relative;
    top: auto;
    display: block;
    width: 100%;
    max-width: none;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content-white-block.no-wide {
    padding: 20px;
  }
  .content-white-block.center-container {
    padding: 25px 20px;
  }
  .button {
    padding: 7px 12px;
    line-height: 14px;
  }
  .button.more-h {
    height: 36px;
  }
  .button.min-indent {
    padding-right: 10px;
    padding-left: 10px;
    line-height: 16px;
  }
  .button.right-indent {
    margin-right: 10px;
  }
  .button.bottom-indent {
    margin-bottom: 20px;
  }
  .editor-tab-link {
    padding: 13px 10px 10px;
    border-bottom-width: 3px;
    font-size: 11px;
    line-height: 16px;
    text-decoration: none;
  }
  .popup-container {
    margin-top: 30px;
  }
  .popup-content-wrap {
    padding: 15px;
  }
  .text-field-2 {
    margin-bottom: 5px;
  }
  .popup-header {
    font-size: 14px;
  }
  .popup-header-div {
    padding: 15px;
  }
  .switch-handle-2 {
    width: 39%;
    height: 18px;
  }
  .close-link.no-move {
    top: 5px;
    right: 5px;
    width: 18px;
    height: 18px;
    font-size: 14px;
  }
  .style-field-label {
    font-size: 13px;
  }
  .tabs-menu-4 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .switch-wrap-2 {
    width: 60px;
    height: 30px;
  }
  .blue-button {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
  }
  .blue-button.right-margin.whatsapp-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.viber-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.messenger-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.telegram-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.instagram-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.email-btn {
    margin-right: 10px;
  }
  .blue-button.right-margin.call-btn {
    margin-right: 10px;
  }
  .blue-button.add-indent2 {
    margin-right: 10px;
  }
  .channel-icon {
    width: 40px;
  }
  .popup-header-block {
    margin-bottom: 15px;
  }
  .close-line-1 {
    width: 16px;
  }
  .close-line-1.rotate {
    width: 16px;
  }
  .settings-btn-w.space-between {
    margin-top: 10px;
  }
  .settings-item-label {
    font-size: 13px;
  }
  .settings-item-label.user-link {
    font-size: 12px;
  }
  .node-settings-input {
    height: 32px;
    font-size: 13px;
  }
  .node-settings-input.textarea {
    font-size: 13px;
    line-height: 16px;
  }
  .node-settings-input.more-h {
    height: 36px;
  }
  .node-settings-input.search-field {
    height: 29px;
    margin-bottom: 5px;
  }
  .settings-select {
    height: 32px;
  }
  .settings-select.no-indent {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .settings-select.no-indent.max-w {
    max-width: 120px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .settings-select.left-indent-2 {
    width: auto;
    margin-left: 10px;
  }
  .page-content-header-div {
    margin-bottom: 15px;
  }
  .page-header {
    font-size: 16px;
    line-height: 18px;
  }
  .page-header.center {
    font-size: 15px;
  }
  .page-subheader {
    font-size: 13px;
    line-height: 18px;
  }
  .page-subheader.add-margin-2 {
    margin-bottom: 10px;
  }
  .left-float-menu {
    margin-right: -1%;
  }
  .left-tab-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24%;
    margin-right: 1%;
    padding: 10px 4px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
  }
  .pane-content-w.horizontal-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .group-title {
    font-size: 14px;
  }
  .show-group-content {
    margin-top: 15px;
    padding-top: 15px;
  }
  .show-group-content.new-indent {
    margin-top: 0px;
    padding-top: 5px;
    border-top-style: none;
  }
  .group-row.horizontal-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .col-6.full-w {
    width: 100%;
  }
  .col-6.full-w.add-line {
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }
  .field-label-2 {
    font-size: 13px;
  }
  .column-text {
    margin-bottom: 15px;
  }
  .column-text.min-indent {
    font-size: 11px;
    line-height: 17px;
  }
  .awes-icon.tooltip-icon {
    width: 13px;
    height: 13px;
    border-width: 1px;
    font-size: 9px;
    line-height: 15px;
  }
  .label-div {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .toggle-w {
    margin-left: 5px;
  }
  .tooltip-div {
    left: -93px;
    display: none;
    width: 200px;
    opacity: 0;
  }
  .tooltip-div.tooltip-block {
    padding: 6px;
  }
  .content-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .big-link-block {
    width: 100%;
    margin-bottom: 15px;
    padding: 20px;
  }
  .link-block-icon {
    width: 80px;
    margin-right: 15px;
  }
  .link-block-icon.confirmed-img {
    width: 80px;
    margin-bottom: 20px;
  }
  .dropdown-toggle {
    height: 36px;
    font-size: 13px;
    line-height: 18px;
  }
  .tag-text {
    font-size: 13px;
    line-height: 18px;
  }
  .search-items {
    font-size: 13px;
  }
  .logo-img {
    width: 28px;
  }
  .checkbox-field {
    font-size: 12px;
  }
  .text-field {
    height: 40px;
    margin-bottom: 10px;
  }
  .or-text {
    font-size: 11px;
    line-height: 13px;
  }
  .sign-header {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 20px;
  }
  .popup-subheader {
    font-size: 13px;
    line-height: 18px;
  }
  .sign-small-text {
    font-size: 12px;
    line-height: 18px;
  }
  .logo-link {
    margin-bottom: 20px;
    font-size: 22px;
  }
  .sign-container {
    padding: 30px 20% 15px;
  }
  .social-sign-up-btn {
    height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .sign-form-block {
    margin-bottom: 15px;
  }
  .or-block {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .top-right-menu {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .popup-container-2 {
    margin-top: 30px;
  }
  .popup-container-2.no-max-w {
    max-height: 90vh;
    padding: 25px 25px 20px;
  }
  .table-filter-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .daterange-div {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .daterange-div.no-margin {
    margin-right: 0px;
  }
  .daterange-div.right-margin {
    margin-right: 5px;
  }
  .filter-icons {
    width: 14px;
  }
  .table-row {
    max-width: 946px;
    min-width: 900px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .channels-settings .table-row {
    min-width: auto;
  }
  .table-row.table-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .table-col1 {
    padding-right: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .check-div {
    width: 16px;
    height: 16px;
  }
  .action-btn {
    font-size: 12px;
  }
  .action-btn.no-indent {
    margin-left: 0px;
  }
  .action-btn.calendar-btn {
    padding: 4px 8px;
  }
  .page-link {
    font-size: 12px;
  }
  .pagination-div {
    margin-right: 10px;
  }
  .menu-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    padding: 11px 10px 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 25px;
  }
  .menu-button.w--open {
    background-color: #009aff;
  }
  .image {
    width: 30px;
    margin-bottom: 15px;
  }
  .pop-up-text {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .btn-add-range {
    position: relative;
    width: 22px;
    height: 22px;
    padding-right: 0px;
    line-height: 18px;
  }
  .input-control-2 {
    width: 65px;
    padding-right: 7px;
    font-size: 14px;
  }
  .input-control-2.min-input {
    width: 75px;
  }
  .input-control-2.min-input.add-margin {
    width: 75px;
  }
  .rotate-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 14px;
    height: 14px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
  }
  .chat-and-sidebar-w {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choose-item {
    font-size: 12px;
    line-height: 19px;
  }
  .div-block-5 {
    margin-bottom: 25px;
  }
  .chat-preview-w {
    margin-right: auto;
    margin-left: auto;
  }
  .div-block-7 {
    margin-right: 10px;
  }
  .white-input {
    font-size: 13px;
  }
  .white-input.textarea {
    font-size: 13px;
    line-height: 16px;
  }
  .white-input.more-h {
    height: 36px;
  }
  .white-input.search-field {
    height: 29px;
    margin-bottom: 5px;
  }
  .content-white-block-2.new-col-4 {
    position: relative;
    top: auto;
    display: block;
    width: 100%;
    max-width: none;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .blue-button-2 {
    font-size: 13px;
    font-weight: 400;
  }
  .action-btn-2 {
    font-size: 12px;
  }
  .node-settings-input-2 {
    font-size: 13px;
  }
  .node-settings-input-2.textarea {
    font-size: 13px;
    line-height: 16px;
  }
  .node-settings-input-2.search-field {
    height: 29px;
    margin-bottom: 5px;
  }
  .settings-select-2 {
    height: 32px;
  }
  .settings-select-2.no-indent {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .settings-select-2.no-indent.max-w {
    max-width: 120px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .campaign-preview-item-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cp-info-w-2 {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  .top-navbar-2 {
    padding-left: 10px;
  }
  .a-tab-link-2 {
    padding: 8px 10px;
  }
  .button-3 {
    padding: 8px 12px;
    line-height: 14px;
  }
  .dropdown-link-2 {
    padding-right: 15px;
  }
  .top-menu-w-2 {
    padding-right: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .filter-drop-tog-2 {
    padding-top: 5px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 12px;
    line-height: 14px;
  }
  .filter-drop-tog-2.no-arrow {
    line-height: 16px;
  }
  .cp-name-text {
    font-size: 16px;
    line-height: 16px;
  }
  .page-title-2 {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 17px;
    line-height: 20px;
  }
  .ava-block {
    left: 0%;
    right: auto;
  }
  .popup-container-block2 {
    margin-top: 30px;
  }
  .avaibility-block2 .popup-container-block2 {
    margin-top: 0;
  }
  .chat-avatar-2-2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding-top: 2px;
    font-size: 14px;
  }
  .popup-container-2-2 {
    margin-top: 30px;
  }
  .table-row-2 {
    max-width: 946px;
    min-width: 900px;
  }
  .step-2-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    padding-top: 2px;
    font-size: 14px;
  }
  .popup-landbot-preview {
    display: none;
    padding-top: 0%;
    padding-right: 0%;
    padding-left: 0%;
    opacity: 0;
  }
  .popup-container-1200 {
    margin-top: 30px;
  }
  .dropdown-block {
    padding-right: 15px;
  }
  .drop-white-block.new-col-4 {
    position: relative;
    top: auto;
    display: block;
    width: 100%;
    max-width: none;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .settings-group-label {
    font-size: 14px;
  }
  .settings-group-label.user-link {
    font-size: 12px;
  }
  .menu-button-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    padding: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 19px;
  }
  .menu-button-2.w--open {
    background-color: #009aff;
  }
  .nav-link-name-2 {
    margin-left: 10px;
    font-size: 12px;
  }
  .left-tab-link-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 24%;
    margin-right: 1%;
    padding: 10px 4px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
  }
  .nav-link-2 {
    padding: 10px 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .send-text-area-2.min-h-bot {
    padding-right: 8px;
  }
  .choose-item-2 {
    font-size: 12px;
    line-height: 19px;
  }
  .upload-button {
    font-size: 13px;
    font-weight: 400;
  }
  .button-4 {
    padding: 8px 12px;
    line-height: 14px;
  }
  .right-sidebar-w-2 {
    width: auto;
    border-top: 1px solid #eee;
    border-left-style: none;
  }
  .left-sidebar-w-2 {
    width: 200px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .top-navbar-bot {
    padding-left: 10px;
  }
  .blue-button-copy {
    font-size: 13px;
    font-weight: 400;
  }
  .items-wrapper {
    max-height: 80vh;
    margin-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .bg-preview-wrap {
    width: 48%;
    min-width: auto;
  }
  .row.horizontal-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .show-group-col {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .grid-label {
    padding-top: 13px;
    padding-bottom: 13px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .tariff-plan-label {
    font-size: 12px;
    line-height: 13px;
  }
  .tariff-plan-label.user-link {
    font-size: 12px;
  }
  .tariff-plan-label.bottom-indent {
    margin-bottom: 10px;
  }
  .price-w {
    font-size: 22px;
    line-height: 27px;
  }
  .grid-block {
    padding-right: 10px;
    padding-left: 10px;
  }
  .grid-block.top-block {
    position: static;
  }
  .plan-label {
    font-size: 12px;
    line-height: 15px;
  }
  .plan-label.min-indent {
    font-size: 11px;
    line-height: 17px;
  }
  .plan-label.center.add-margin {
    margin-bottom: 15px;
  }
  .tariff-name {
    font-size: 16px;
  }
  .price-label {
    font-size: 12px;
    line-height: 20px;
  }
  .price-bg-block {
    position: static;
  }
  .tariff-period-choose {
    width: 190px;
    height: 36px;
    margin-top: 15px;
    padding: 10px 12px 10px 27px;
    font-size: 12px;
    line-height: 12px;
  }
  .top-plan-w {
    margin-top: 50px;
    margin-bottom: 40px;
    padding: 20px 20px 10px;
  }
  .form-group-row.width-left-margin-2 {
    margin-left: -15px;
  }
  .sent-notifications-table-wrap {
    overflow: auto;
  }
  .button-1 {
    padding: 6px 10px;
    font-size: 12px;
  }
  .visitors-info {
    margin-left: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 12px;
    line-height: 14px;
  }
  .visitors-info.current-plan-info {
    margin-right: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .button-2 {
    margin-right: 5px;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .table-col {
    width: 95px;
    font-size: 11px;
    line-height: 14px;
  }
  .table-col.column-1 {
    width: 130px;
  }
  .table-col.expand-row-col {
    min-width: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .form-group-title {
    font-size: 14px;
    line-height: 20px;
  }
  .sent-notifications-table-row {
    width: 100%;
  }
  .badge {
    font-size: 12px;
  }
  .badge.badge-btn {
    font-size: 10px;
    line-height: 16px;
  }
  .plan-info-block-2.top-align {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .table-header-col {
    width: 95px;
    font-size: 11px;
    line-height: 18px;
  }
  .table-header-col.expand-header-col {
    min-width: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .plan-info-div {
    margin-top: 5px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .btn-awesome-span {
    line-height: 17px;
  }
  .account-paragraph {
    font-size: 12px;
    line-height: 16px;
  }
  .form-group-column.half-w {
    padding-left: 15px;
  }
  .button-3 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .sent-notifications-table {
    width: auto;
  }
  .bottom-btns-wrap {
    margin-bottom: 30px;
  }
  .field-label-3 {
    font-size: 13px;
  }
  .div-block-13 {
    margin-bottom: 10px;
  }
  .top-plan-features {
    margin-top: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .div-block-14 {
    margin-top: 15px;
  }
  .page-subheader-2 {
    font-size: 13px;
    line-height: 18px;
  }
  .btns-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .paragraph-w {
    margin-left: 15px;
  }
  .meeting-url {
    height: 32px;
    font-size: 13px;
    line-height: 18px;
  }
  .page-wrap {
    padding: 30px;
  }
  .meeting-avatar {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
  .book-header {
    margin-bottom: 20px;
  }
  .calendar-header {
    margin-bottom: 10px;
  }
  .calendar-item {
    padding: 8px 4px;
    font-size: 13px;
    line-height: 18px;
  }
  .day-of-week {
    font-size: 12px;
    line-height: 18px;
  }
  .time-btn-label {
    font-size: 13px;
    line-height: 18px;
  }
  .time-block {
    max-height: 200px;
  }
  .block-with-bg {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 20px 20px 10px;
  }
  .popup-subheader {
    font-size: 13px;
    line-height: 18px;
  }
  .cp-info-header-2 {
    line-height: 18px;
  }
  .cp-info-div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cp-info-div-2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .description-text {
    font-size: 11px;
    line-height: 14px;
  }
  .description-text.no-indent {
    line-height: 13px;
  }
  .chart-block {
    width: 100px;
    height: 100px;
  }
  .chart-wrapper {
    margin-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .chart-item-color {
    width: 8px;
    height: 8px;
    margin-right: 3px;
  }
  .fanpage-connected {
    font-size: 12px;
  }
  .header-channel-icon {
    width: 25px;
  }
}

@media screen and (max-width: 479px) {
  .main-wrapper {
    height: auto;
  }

  .chat-wrap.custom {
    max-height: 100vh;
  }

  .left-sidebar-w {
    width: 100%;
    max-height: 200px;
    border-right-style: none;
  }

  .top-nav-left-container .dropdown-list.w-dropdown-list {
    z-index: 901;
  }

  .close-btn-search {
    justify-content: start;
    top: 11px;
  }

  .search-field {
    font-size: 12px;
    line-height: 18px;
  }
  .search-form-block {
    margin-right: 0px;
  }
  .filers-w {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .filter-drop-tog {
    padding-right: 3px;
    padding-left: 5px;
  }
  .filter-drop-tog.like-btn {
    padding-right: 5px;
    font-size: 12px;
    line-height: 12px;
  }
  .filter-drop {
    margin-right: 5px;
    margin-left: 0px;
  }
  .drop-icon {
    margin-left: 5px;
  }
  .dropdown-list.w--open {
    right: 0px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
  .dropdown-list.add-padding.add-max-h.w--open {
    position: absolute;
    z-index: 1004;
  }
  .dropdown-list.no-style.bot {
    right: auto;
  }
  .top-navbar {
    top: 35px;
    width: 100%;
    height: auto;
    min-height: 40px;
    padding-right: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .top-navbar.horizontal-wrap {
    min-height: 45px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .filter-drop-w {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .filter-drop-w.right-align {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .page-title {
    margin-right: 10px;
    font-size: 16px;
    line-height: 18px;
  }
  .page-title.breaking {
    font-size: 14px;
    line-height: 16px;
  }
  .page-title.align-center {
    line-height: 20px;
  }
  .top-menu-w {
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    border-left-style: none;
    background-color: transparent;
  }
  .top-nav-link-icon {
    width: 14px;
  }
  .account-tog {
    padding: 9px;
  }
  .account-dropdown {
    margin-left: 10px;
  }
  .top-control-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .page-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .page-content.normal-content {
    padding: 10px;
  }
  .page-content.normal-content {
    padding: 10px;
  }
  .right-sidebar-w {
    width: 100%;
    padding-top: 20px;
  }
  .chat-wrap {
    border-top: 1px solid #eee;
  }
  .new-message-actions.new-position-bot {
    position: static;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .chat-message-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .chat-message-p {
    font-size: 12px;
    line-height: 17px;
  }
  .top-nav-left-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .a-tab-link {
    padding: 7px 9px;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }
  .a-tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .campaign-preview-item {
    padding: 10px;
  }
  .cp-avatar-div {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-avatar {
    width: 30px;
    height: 30px;
  }
  .cp-name-link {
    font-size: 14px;
  }
  .cp-url {
    font-size: 12px;
    line-height: 14px;
  }
  .cp-info-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cp-info-block.vertical-align {
    margin-bottom: -10px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cp-info-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-info-item.no-shrink {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .cp-info-item.border-left {
    border-left-style: none;
  }
  .cp-info-count {
    font-size: 16px;
    line-height: 17px;
  }
  .cp-info-header {
    margin-right: 5px;
    margin-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 12px;
  }
  .cp-info-header-icon {
    display: inline;
    margin-right: 2px;
    font-size: 14px;
    line-height: 16px;
  }
  .cp-info {
    font-size: 12px;
    line-height: 18px;
  }
  .switch-wrap {
    width: 37px;
    height: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .switch-text-wrap {
    font-size: 10px;
  }
  .awesome-icon.big-icon {
    font-size: 11px;
    line-height: 12px;
  }
  .step-btn {
    margin-bottom: 5px;
  }
  .step-btn.with-border {
    line-height: 13px;
  }
  .step-btns-w {
    margin-bottom: 15px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .step-btns-w.no-bottom-indent {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .arrow-block {
    margin-bottom: 5px;
  }
  .content-white-block {
    padding: 10px;
  }
  .content-white-block.new-col-4 {
    width: 100%;
  }
  .content-white-block.grow-w {
    padding: 10px;
  }
  .content-white-block.hor-flex-2 {
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .content-white-block.no-wide {
    width: 95%;
    padding: 15px 10px;
  }
  .content-white-block.center-container {
    width: 95%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .button {
    padding: 7px 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .button.min-indent {
    padding-right: 5px;
    padding-left: 5px;
    line-height: 12px;
  }
  .editor-tab-link {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 14px;
    text-align: center;
  }
  .popup-content-wrap {
    padding: 10px;
  }
  .text-field-2 {
    height: 35px;
    padding-left: 10px;
    font-size: 11px;
    line-height: 18px;
  }
  .popup-header {
    font-size: 13px;
    line-height: 18px;
  }
  .popup-header-div {
    padding: 15px 10px;
  }
  .switch-handle-2 {
    top: -1px;
    height: 16px;
  }
  .close-link {
    top: -5px;
    width: 18px;
    height: 18px;
    font-size: 13px;
  }
  .close-link.no-move {
    font-size: 13px;
  }
  .style-field-label {
    font-size: 12px;
    line-height: 16px;
  }
  .upload-link {
    width: 60px;
    height: 60px;
    font-size: 11px;
  }
  .minicolors-swatch {
    left: 8px;
    top: 6px;
  }
  .tabs-menu-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .select-field-2 {
    height: 35px;
    padding-left: 7px;
    font-size: 11px;
    line-height: 18px;
  }
  .select-field-2.half-w.left-indent {
    margin-left: 10px;
  }
  .switch-wrap-2 {
    width: 55px;
    height: 28px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-width: 3px;
  }
  .blue-button {
    font-size: 12px;
    line-height: 12px;
  }
  .blue-button.right-margin.first-item {
    margin-bottom: 5px;
  }
  .blue-button.right-margin.whatsapp-btn {
    margin-bottom: 5px;
  }
  .blue-button.right-margin.viber-btn {
    margin-bottom: 5px;
  }
  .blue-button.right-margin.messenger-btn {
    margin-bottom: 5px;
  }
  .blue-button.right-margin.telegram-btn {
    margin-bottom: 5px;
  }
  .blue-button.right-margin.instagram-btn {
    margin-bottom: 5px;
  }
  .blue-button.min-indent {
    padding: 7px 5px;
    line-height: 12px;
  }
  .blue-button.add-indent2 {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .channel-icon {
    width: 30px;
  }
  .sign-header {
    font-size: 16px;
    line-height: 24px;
  }
  .popup-subheader {
    font-size: 12px;
    line-height: 16px;
  }
  .settings-btn-w.vertical-dir {
    font-size: 12px;
  }
  .settings-item-label {
    font-size: 12px;
  }
  .info-text.no-indent {
    font-size: 10px;
    line-height: 13px;
  }
  .info-text.avatar-name {
    font-size: 10px;
    line-height: 13px;
  }
  .info-text.tooltip-text {
    font-size: 10px;
    line-height: 11px;
  }
  .node-settings-input {
    height: 32px;
    font-size: 12px;
  }
  .node-settings-input.textarea {
    font-size: 12px;
  }
  .node-settings-input.no-round {
    border-radius: 3px;
  }
  .settings-select {
    height: 32px;
    font-size: 12px;
  }
  .settings-select.narrow-select {
    width: 110px;
  }
  .settings-select.left-indent-2 {
    margin-top: 10px;
    margin-left: 0px;
  }
  .emoji-wrap {
    background-size: 16px;
  }
  .page-content-header-div.hor-flex {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .page-content-header-div.hor-flex.justify-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .page-content-header-div.hor-flex.space-between {
    margin-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .page-header {
    font-size: 15px;
    line-height: 17px;
  }
  .page-header.center {
    font-size: 14px;
  }
  .page-subheader {
    font-size: 11px;
    line-height: 15px;
  }
  .left-float-menu {
    margin-right: -1%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left-tab-link {
    width: 32.333%;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
  }
  .group-title {
    font-size: 13px;
    line-height: 16px;
  }
  .group-content-w.add-padding {
    margin-top: 10px;
    padding-top: 10px;
  }
  .group-content-w.add-bottom-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .group-w {
    margin-top: 10px;
    padding: 10px;
  }
  .show-group-content {
    margin-top: 10px;
    padding-top: 10px;
  }
  .show-group-content.no-top-margin {
    padding-top: 10px;
  }
  .show-group-content.no-top-margin2 {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  .group-row.more-margin {
    margin-bottom: 5px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .col-6.full-w.add-line {
    margin-top: 5px;
    padding-top: 10px;
  }
  .field-label-2 {
    font-size: 12px;
    line-height: 16px;
  }
  .column-text.min-indent {
    font-size: 10px;
    line-height: 15px;
  }
  .label-div.fix-w {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .error-message {
    font-size: 10px;
    line-height: 12px;
  }
  .tooltip-div {
    left: -83px;
    width: 180px;
  }
  .tooltip-div.tooltip-block {
    width: 170px;
  }
  .big-link-block {
    margin-bottom: 10px;
    padding: 15px;
  }
  .link-block-icon {
    width: 70px;
  }
  .avatar-add-wrap {
    width: 50px;
    height: 50px;
  }
  .avatar-add-wrap.big-wrap {
    margin-bottom: 15px;
  }
  .control-icon.upload {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  .control-icon.upload-hide {
    width: 18px;
    height: 18px;
    font-size: 18px;
  }
  .dropdown-like-select {
    position: relative;
    z-index: 100;
  }
  .dropdown-like-select.time-format {
    max-width: none;
    margin-left: 0px;
  }
  .dropdown-toggle {
    font-size: 12px;
  }
  .tag-text {
    font-size: 12px;
  }
  .search-block {
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  .search-items {
    font-size: 12px;
    line-height: 18px;
  }
  .logo-img {
    width: 26px;
  }
  .sign-header {
    font-size: 16px;
    line-height: 24px;
  }
  .sign-small-text {
    line-height: 17px;
  }
  .logo-link {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .sign-container {
    padding: 50px 30px 30px;
  }
  .form-div {
    margin-bottom: 5px;
  }
  .top-nav-right-container {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .top-right-menu {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-bottom: 5px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .popup-container-2 {
    padding: 20px;
  }
  .popup-container-2.no-max-w {
    max-height: 95vh;
    margin-bottom: 30px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
  }
  .table-filter-block {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .daterange-div.no-margin {
    margin-bottom: 0px;
  }
  .daterange-div.right-margin {
    margin-bottom: 5px;
  }
  .table-row {
    max-width: 950px;
    min-width: 850px;
  }
  .channels-settings .table-row {
    min-width: auto;
  }
  .table-row.in-view {
    max-width: none;
    min-width: auto;
  }
  .check-div.checked.add-indent {
    margin-right: 5px;
  }
  .table-w {
    overflow: auto;
  }
  .action-btn {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .action-btn.calendar-btn {
    font-size: 11px;
    line-height: 14px;
  }
  .pop-up-text {
    font-size: 14px;
  }
  .day-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .check-btn {
    width: 18px;
    height: 18px;
    margin-right: 7px;
    font-size: 13px;
  }
  .range-hints-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .btn-add-range {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .btn-add-range.no-abs-position {
    position: static;
    width: 24px;
    height: 24px;
  }
  .working-days-block {
    padding: 0px 0px 10px;
  }
  .input-control-2.min-input.add-margin {
    width: 65px;
    height: 32px;
    margin-right: 5px;
    margin-left: 5px;
    padding-right: 3px;
    padding-left: 5px;
    font-size: 13px;
    line-height: 20px;
  }
  .range-hint-item {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 12px;
  }
  .checkbox-field-2.fix-w {
    min-width: 60px;
    margin-top: 10px;
    margin-right: 5px;
    margin-bottom: 8px;
    font-size: 12px;
  }
  .div-block-3 {
    margin-bottom: 15px;
  }
  .custom-dropdown-w {
    margin-top: 15px;
  }
  .custom-dropdown-list {
    padding-right: 10px;
    padding-left: 10px;
  }
  .custom-dropdown-toggle {
    padding: 10px;
  }
  .down-arrow {
    width: 14px;
  }
  .show-group-content-2 {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .color-item.solid-color {
    width: 22px;
    height: 22px;
  }
  .choose-type-w.no-border {
    margin-bottom: 7px;
  }
  .choose-item {
    margin-right: 3px;
    margin-bottom: 3px;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
  }
  .chat-preview-w {
    width: 100%;
  }
  .div-block-7 {
    margin-bottom: 5px;
  }
  .white-input {
    height: 32px;
    font-size: 12px;
  }
  .white-input.textarea {
    font-size: 12px;
  }
  .content-white-block-2 {
    padding: 10px;
  }
  .content-white-block-2.hor-flex-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .content-white-block-2.new-col-4 {
    width: 100%;
  }
  .content-white-block-2.grow-w {
    padding: 10px;
  }
  .blue-button-2 {
    font-size: 12px;
  }
  .node-settings-input-2 {
    height: 32px;
    font-size: 12px;
  }
  .node-settings-input-2.textarea {
    font-size: 12px;
  }
  .settings-select-2 {
    height: 32px;
    font-size: 12px;
  }
  .show-group-content-2 {
    margin-top: 15px;
    padding-top: 15px;
  }
  .campaign-preview-item-2 {
    padding: 10px;
  }
  .top-navbar-2 {
    width: 100%;
    padding-right: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .a-tab-link-2 {
    padding: 7px 10px;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }
  .account-tog-2 {
    padding: 9px;
  }
  .button-3 {
    padding: 7px 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .cp-info-header-icon-2 {
    display: inline;
    margin-right: 2px;
    font-size: 14px;
    line-height: 16px;
  }
  .top-menu-w-2 {
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    border-left-style: none;
    background-color: transparent;
  }
  .step-btn-2 {
    margin-bottom: 5px;
  }
  .step-btn-2.with-border {
    line-height: 13px;
  }
  .filter-drop-tog-2 {
    padding-right: 3px;
    padding-left: 5px;
  }
  .cp-name-text {
    font-size: 14px;
  }
  .page-title-2 {
    margin-right: 10px;
    font-size: 16px;
    line-height: 18px;
  }
  .switch-wrap-3 {
    width: 37px;
    height: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .popup-container-block2 {
    padding: 20px;
  }
  .ava-title {
    font-size: 14px;
  }
  .popup-container-2-2 {
    padding: 20px;
  }
  .table-row-2 {
    max-width: 950px;
    min-width: 850px;
  }
  .popup-container-1200 {
    padding: 20px;
  }
  .drop-white-block {
    padding: 10px;
  }
  .drop-white-block.new-col-4 {
    width: 100%;
  }
  .drop-white-block.grow-w {
    padding: 10px;
  }
  .drop-white-block.hor-flex-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .settings-group-label {
    font-size: 13px;
  }
  .left-tab-link-2 {
    width: 32.333%;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
  }
  .show-group-content-2-2 {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .send-text-area-2.min-h-bot {
    padding-right: 0px;
  }
  .choose-item-2 {
    padding-right: 10px;
    padding-left: 10px;
  }
  .upload-button {
    font-size: 12px;
  }
  .button-4 {
    padding: 9px 13px;
    font-size: 12px;
    line-height: 12px;
  }
  .right-sidebar-w-2 {
    width: 100%;
    padding-top: 20px;
  }
  .chat-wrap-2 {
    border-top: 1px solid #eee;
  }
  .left-sidebar-w-2 {
    width: 100%;
    max-height: 200px;
    border-right-style: none;
  }
  .top-navbar-bot {
    width: 100%;
    padding-right: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .new-mess-action-3 {
    width: 280px;
  }
  .blue-button-copy {
    font-size: 12px;
  }
  .items-wrapper {
    margin-top: 0px;
    margin-right: 0%;
  }
  .bg-preview-wrap {
    width: 100%;
    margin-top: 0px;
    margin-right: 0%;
    margin-bottom: 15px;
  }
  .grid {
    margin-top: 15px;
  }
  .tariff-plan-label {
    font-size: 12px;
  }
  .tariff-plan-label.top-margin {
    margin-top: 5px;
  }
  .price-w {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    font-size: 20px;
    line-height: 25px;
  }
  .grid-block {
    padding-right: 2px;
    padding-left: 2px;
  }
  .grid-block.top-block {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center;
  }
  .plan-label {
    text-align: center;
  }
  .plan-label.min-indent {
    font-size: 10px;
    line-height: 15px;
  }
  .plan-label.add-padding {
    text-align: left;
  }
  .price-label {
    font-size: 11px;
    line-height: 20px;
  }
  .tariff-period-choose {
    margin-top: 10px;
    padding-right: 14px;
    padding-left: 28px;
    font-size: 11px;
    font-weight: 500;
  }
  .top-plan-w {
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .top-plan-div {
    margin-right: 10px;
    margin-left: 10px;
  }
  .form-group-row {
    margin-top: 10px;
  }
  .form-group-row.width-left-margin-2 {
    margin-left: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .form-group-row.width-left-margin-2.add-left-indent {
    margin-top: 0px;
    margin-left: -15px;
  }
  .button-1 {
    padding: 6px 10px;
    font-size: 11px;
    line-height: 14px;
  }
  .button-1.small-btn {
    font-size: 11px;
    line-height: 16px;
  }
  .button-1.small-btn.right-indent {
    font-size: 11px;
    line-height: 16px;
  }
  .visitors-info {
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 9px;
    text-align: center;
  }
  .visitors-info.current-plan-info {
    font-size: 12px;
  }
  .button-2 {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 11px;
  }
  .button-2.small-btn-2 {
    font-size: 11px;
    line-height: 16px;
  }
  .table-col {
    width: 95px;
  }
  .form-group-titile-wrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .form-group-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 13px;
    line-height: 18px;
  }
  .badge {
    line-height: 17px;
  }
  .badge.badge-btn {
    top: -8px;
    right: -8px;
  }
  .plan-info-block-2 {
    margin-bottom: 0px;
  }
  .table-header-col {
    width: 95px;
  }
  .btn-awesome-span {
    height: 16px;
    font-size: 14px;
    line-height: 17px;
  }
  .plan-info-wrap {
    margin-bottom: 5px;
  }
  .account-paragraph {
    margin-bottom: 5px;
  }
  .form-group-column {
    width: 100%;
    margin-bottom: 5px;
    padding-left: 0px;
  }
  .form-group-column.half-w {
    width: 100%;
    padding-left: 15px;
  }
  .button-3 {
    font-size: 11px;
    line-height: 14px;
  }
  .button-3.small-btn-3 {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 11px;
    line-height: 18px;
  }
  .form-group {
    margin-bottom: 10px;
    padding: 10px;
  }
  .bottom-btns-wrap {
    margin-bottom: 35px;
  }
  .field-label-3 {
    font-size: 12px;
    line-height: 16px;
  }
  .div-block-13 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .form-group-column-2 {
    width: 100%;
  }
  .form-group-column-2.third-width {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .top-plan-features {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .div-block-14 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .page-subheader-2 {
    font-size: 12px;
    line-height: 16px;
  }
  .col-3 {
    width: 100%;
    margin-top: 10px;
  }
  .col-9 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .btns-wrap {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .btns-block.left-justify {
    margin-bottom: 5px;
  }
  .paragraph-w {
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .meeting-url {
    margin-bottom: 5px;
    border-right-style: solid;
    border-radius: 3px;
  }
  .meeting-url-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .page-wrap {
    padding: 20px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .page-wrap.normal-content {
    padding: 10px;
  }
  .page-wrap.normal-content {
    padding: 10px;
  }
  .calendar-wrap {
    padding: 20px 15px 15px;
  }
  .calendar-block {
    max-width: 450px;
    margin-top: 20px;
  }
  .calendar-item {
    font-size: 12px;
    line-height: 14px;
  }
  .day-of-week {
    font-size: 11px;
    line-height: 16px;
  }
  .time-wrap {
    margin-top: 30px;
  }
  .search-w {
    padding: 10px;
  }
  .time-btn-label {
    font-size: 12px;
    line-height: 14px;
  }
  .time-block {
    max-height: 200px;
  }
  .block-with-bg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .time-zone-settings {
    position: relative;
    z-index: 1;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .popup-subheader {
    font-size: 12px;
    line-height: 16px;
  }
  .cp-info-header-2 {
    margin-right: 0px;
    margin-bottom: 5px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 15px;
  }
  .cp-info-div {
    width: 50%;
    padding-bottom: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-bottom-style: none;
  }
  .cp-info-div-2 {
    width: 50%;
    padding-bottom: 0px;
  }
  .chart-block {
    width: 150px;
    height: 150px;
  }
  .chart-item-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  .fanpage-connected {
    font-size: 11px;
  }
  .header-channel-icon {
    width: 23px;
  }
}

.grid-th-row {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-224ea95f777e-3b9be9ed {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 5;
  grid-column-end: 6;
}

#w-node-f94a46c42631-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-6f404077c322-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-287c3a9c606d-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-757bbbf899a1-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-1f9b6cb09df6-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-16ee9495a380-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
}

#w-node-c84622f74648-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-60c0c70e4faf-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-7491dabbc990-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-08af1078b513-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 10;
  grid-row-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 11;
}

#w-node-15296ef3c466-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-6a5a51ec3d99-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-7788175d021b-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-fdf06d1ccf6c-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-efbbc86bf699-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-15fbf29449bd-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-bb3f7fb31bae-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-fe71f126ba70-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-edf62aed071f-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-fb5b470db546-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 10;
  grid-row-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 11;
}

#w-node-5e743955c5b8-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-a7f7bb38edec-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-7f837a66352a-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-7c1955492a0e-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
}

#w-node-b2e05eff9203-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-a35bc2dd231c-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-dc1de428c34e-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-4fc2491c502b-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-e5f53fcc5c75-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
}

#w-node-bbaa0f7f23f3-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
}

#w-node-06c916eec54d-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 1;
  grid-row-end: 12;
}

#w-node-9f24677530bb-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 9;
  grid-row-start: 9;
  -ms-grid-row-span: 1;
  grid-row-end: 10;
}

#w-node-1277352050d0-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 1;
  grid-row-end: 12;
}

#w-node-70cb353d48cc-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 9;
  grid-row-start: 9;
  -ms-grid-row-span: 1;
  grid-row-end: 10;
}

#w-node-814068eb0016-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 9;
  grid-row-start: 9;
  -ms-grid-row-span: 1;
  grid-row-end: 10;
}

#w-node-d65537a7c660-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 12;
  grid-row-start: 12;
  -ms-grid-row-span: 1;
  grid-row-end: 13;
}

#w-node-13b9ad262060-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 1;
  grid-row-end: 14;
}

#w-node-8198f4459ba2-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
}

#w-node-a652c563b672-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
}

#w-node-17dc8e847da2-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 1;
  grid-row-end: 15;
}

#w-node-89f2afc52aac-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
}

#w-node-8b0eea15a95a-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
}

#w-node-e66b66211357-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 15;
  grid-row-start: 15;
  -ms-grid-row-span: 1;
  grid-row-end: 16;
}

#w-node-ed4508c53616-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
}

#w-node-56fcfede4836-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 8;
  grid-row-start: 8;
  -ms-grid-row-span: 1;
  grid-row-end: 9;
}

#w-node-2f92f2c1ce82-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 16;
  grid-row-start: 16;
  -ms-grid-row-span: 1;
  grid-row-end: 17;
}

#w-node-0cba0f6dc75c-3b9be9ed {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 9;
  grid-row-start: 9;
  -ms-grid-row-span: 1;
  grid-row-end: 10;
}

#w-node-bae91b832d83-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
}

#w-node-a1af4df3c6da-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
}

#w-node-f518b0f91b99-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 9;
  grid-row-start: 9;
  -ms-grid-row-span: 1;
  grid-row-end: 10;
}

#w-node-6d68aeee8d6e-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 6;
  grid-row-start: 6;
  -ms-grid-row-span: 1;
  grid-row-end: 7;
}

#w-node-501f8e3cbab2-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 12;
  grid-row-start: 12;
  -ms-grid-row-span: 1;
  grid-row-end: 13;
}

#w-node-0e7f5f7bc2f8-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 1;
  grid-row-end: 14;
}

#w-node-a9fa5f09afd0-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 1;
  grid-row-end: 12;
}

#w-node-77f0699a62b9-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 10;
  grid-row-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 11;
}

#w-node-1db0ad28b7da-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 1;
  grid-row-end: 12;
}

#w-node-ed9467f52814-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 10;
  grid-row-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 11;
}

#w-node-134643a3c237-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 10;
  grid-row-start: 10;
  -ms-grid-row-span: 1;
  grid-row-end: 11;
}

#w-node-c8cd25072bd6-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 1;
  grid-row-end: 15;
}

#w-node-9b6b3043da92-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 15;
  grid-row-start: 15;
  -ms-grid-row-span: 1;
  grid-row-end: 16;
}

#w-node-109b51a09559-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
}

#w-node-102c3e82a8a8-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 7;
  grid-row-start: 7;
  -ms-grid-row-span: 1;
  grid-row-end: 8;
}

#w-node-03a659f87b85-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 12;
  grid-row-start: 12;
  -ms-grid-row-span: 1;
  grid-row-end: 13;
}

#w-node-ffd5c9d89afe-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 12;
  grid-row-start: 12;
  -ms-grid-row-span: 1;
  grid-row-end: 13;
}

#w-node-6d24bf3e8fba-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 11;
  grid-row-start: 11;
  -ms-grid-row-span: 1;
  grid-row-end: 12;
}

#w-node-53c6443506d7-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 12;
  grid-row-start: 12;
  -ms-grid-row-span: 1;
  grid-row-end: 13;
}

#w-node-d16aeb712dee-3b9be9ed {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 16;
  grid-row-start: 16;
  -ms-grid-row-span: 1;
  grid-row-end: 17;
}

#w-node-15aa1d29aae9-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 1;
  grid-row-end: 14;
}

#w-node-4e97da3cc000-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 16;
  grid-row-start: 16;
  -ms-grid-row-span: 1;
  grid-row-end: 17;
}

#w-node-d8bb6ca96422-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 1;
  grid-row-end: 14;
}

#w-node-968b2814f38c-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 13;
  grid-row-start: 13;
  -ms-grid-row-span: 1;
  grid-row-end: 14;
}

#w-node-4750f5d605d4-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 1;
  grid-row-end: 15;
}

#w-node-2f473e859bd4-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 1;
  grid-row-end: 15;
}

#w-node-2f71611fa878-3b9be9ed {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 15;
  grid-row-start: 15;
  -ms-grid-row-span: 1;
  grid-row-end: 16;
}

#w-node-40c1196755d8-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 14;
  grid-row-start: 14;
  -ms-grid-row-span: 1;
  grid-row-end: 15;
}

#w-node-8ee5c9ce1523-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 15;
  grid-row-start: 15;
  -ms-grid-row-span: 1;
  grid-row-end: 16;
}

#w-node-ea928f0e889b-3b9be9ed {
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 16;
  grid-row-start: 16;
  -ms-grid-row-span: 1;
  grid-row-end: 17;
}

#w-node-7f8c5f9e0293-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 15;
  grid-row-start: 15;
  -ms-grid-row-span: 1;
  grid-row-end: 16;
}

#w-node-22bbda9ba6c7-3b9be9ed {
  -ms-grid-column: 5;
  grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row: 16;
  grid-row-start: 16;
  -ms-grid-row-span: 1;
  grid-row-end: 17;
}

@media screen and (max-width: 991px) {
  #w-node-224ea95f777e-3b9be9ed {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
  }
  #w-node-f94a46c42631-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  #w-node-6f404077c322-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-287c3a9c606d-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-757bbbf899a1-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-1f9b6cb09df6-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  #w-node-16ee9495a380-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 10;
    grid-row-start: 10;
    -ms-grid-row-span: 1;
    grid-row-end: 11;
  }
  #w-node-c84622f74648-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 6;
    grid-row-start: 6;
    -ms-grid-row-span: 1;
    grid-row-end: 7;
  }
  #w-node-60c0c70e4faf-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 8;
    grid-row-start: 8;
    -ms-grid-row-span: 1;
    grid-row-end: 9;
  }
  #w-node-7491dabbc990-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-row-span: 1;
    grid-row-end: 12;
  }
  #w-node-08af1078b513-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 18;
    grid-row-start: 18;
    -ms-grid-row-span: 1;
    grid-row-end: 19;
  }
  #w-node-15296ef3c466-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  #w-node-6a5a51ec3d99-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-7788175d021b-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-fdf06d1ccf6c-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-efbbc86bf699-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 15;
    grid-row-start: 15;
    -ms-grid-row-span: 1;
    grid-row-end: 16;
  }
  #w-node-15fbf29449bd-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
  #w-node-bb3f7fb31bae-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 9;
    grid-row-start: 9;
    -ms-grid-row-span: 1;
    grid-row-end: 10;
  }
  #w-node-fe71f126ba70-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
  #w-node-edf62aed071f-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
  #w-node-fb5b470db546-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  #w-node-5e743955c5b8-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 15;
    grid-row-start: 15;
    -ms-grid-row-span: 1;
    grid-row-end: 16;
  }
  #w-node-a7f7bb38edec-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 9;
    grid-row-start: 9;
    -ms-grid-row-span: 1;
    grid-row-end: 10;
  }
  #w-node-7f837a66352a-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 9;
    grid-row-start: 9;
    -ms-grid-row-span: 1;
    grid-row-end: 10;
  }
  #w-node-7c1955492a0e-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-row-span: 1;
    grid-row-end: 12;
  }
  #w-node-b2e05eff9203-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 7;
    grid-row-start: 7;
    -ms-grid-row-span: 1;
    grid-row-end: 8;
  }
  #w-node-a35bc2dd231c-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 7;
    grid-row-start: 7;
    -ms-grid-row-span: 1;
    grid-row-end: 8;
  }
  #w-node-dc1de428c34e-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 15;
    grid-row-start: 15;
    -ms-grid-row-span: 1;
    grid-row-end: 16;
  }
  #w-node-4fc2491c502b-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 7;
    grid-row-start: 7;
    -ms-grid-row-span: 1;
    grid-row-end: 8;
  }
  #w-node-e5f53fcc5c75-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 17;
    grid-row-start: 17;
    -ms-grid-row-span: 1;
    grid-row-end: 18;
  }
  #w-node-bbaa0f7f23f3-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 9;
    grid-row-start: 9;
    -ms-grid-row-span: 1;
    grid-row-end: 10;
  }
  #w-node-06c916eec54d-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 21;
    grid-row-start: 21;
    -ms-grid-row-span: 1;
    grid-row-end: 22;
  }
  #w-node-9f24677530bb-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
  #w-node-1277352050d0-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 20;
    grid-row-start: 20;
    -ms-grid-row-span: 1;
    grid-row-end: 21;
  }
  #w-node-70cb353d48cc-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 7;
    grid-row-start: 7;
    -ms-grid-row-span: 1;
    grid-row-end: 8;
  }
  #w-node-814068eb0016-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 13;
    grid-row-start: 13;
    -ms-grid-row-span: 1;
    grid-row-end: 14;
  }
  #w-node-d65537a7c660-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 22;
    grid-row-start: 22;
    -ms-grid-row-span: 1;
    grid-row-end: 23;
  }
  #w-node-13b9ad262060-3b9be9ed {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
    -ms-grid-row: 24;
    grid-row-start: 24;
    -ms-grid-row-span: 1;
    grid-row-end: 25;
  }
  #w-node-8198f4459ba2-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 12;
    grid-row-start: 12;
    -ms-grid-row-span: 1;
    grid-row-end: 13;
  }
  #w-node-a652c563b672-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 13;
    grid-row-start: 13;
    -ms-grid-row-span: 1;
    grid-row-end: 14;
  }
  #w-node-17dc8e847da2-3b9be9ed {
    -ms-grid-column-span: 5;
    grid-column-end: 5;
    -ms-grid-row: 26;
    grid-row-start: 26;
    -ms-grid-row-span: 1;
    grid-row-end: 27;
  }
  #w-node-89f2afc52aac-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-row-span: 1;
    grid-row-end: 12;
  }
  #w-node-8b0eea15a95a-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 13;
    grid-row-start: 13;
    -ms-grid-row-span: 1;
    grid-row-end: 14;
  }
  #w-node-e66b66211357-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 28;
    grid-row-start: 28;
    -ms-grid-row-span: 1;
    grid-row-end: 29;
  }
  #w-node-ed4508c53616-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 14;
    grid-row-start: 14;
    -ms-grid-row-span: 1;
    grid-row-end: 15;
  }
  #w-node-56fcfede4836-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 17;
    grid-row-start: 17;
    -ms-grid-row-span: 1;
    grid-row-end: 18;
  }
  #w-node-2f92f2c1ce82-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 30;
    grid-row-start: 30;
    -ms-grid-row-span: 1;
    grid-row-end: 31;
  }
  #w-node-0cba0f6dc75c-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 4;
    grid-column-end: 5;
    -ms-grid-row: 16;
    grid-row-start: 16;
    -ms-grid-row-span: 1;
    grid-row-end: 17;
  }
  #w-node-bae91b832d83-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 19;
    grid-row-start: 19;
    -ms-grid-row-span: 1;
    grid-row-end: 20;
  }
  #w-node-a1af4df3c6da-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 11;
    grid-row-start: 11;
    -ms-grid-row-span: 1;
    grid-row-end: 12;
  }
  #w-node-f518b0f91b99-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 17;
    grid-row-start: 17;
    -ms-grid-row-span: 1;
    grid-row-end: 18;
  }
  #w-node-6d68aeee8d6e-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 17;
    grid-row-start: 17;
    -ms-grid-row-span: 1;
    grid-row-end: 18;
  }
  #w-node-501f8e3cbab2-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 23;
    grid-row-start: 23;
    -ms-grid-row-span: 1;
    grid-row-end: 24;
  }
  #w-node-0e7f5f7bc2f8-3b9be9ed {
    -ms-grid-row: 25;
    grid-row-start: 25;
    -ms-grid-row-span: 1;
    grid-row-end: 26;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
  }
  #w-node-a9fa5f09afd0-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 21;
    grid-row-start: 21;
    -ms-grid-row-span: 1;
    grid-row-end: 22;
  }
  #w-node-77f0699a62b9-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 19;
    grid-row-start: 19;
    -ms-grid-row-span: 1;
    grid-row-end: 20;
  }
  #w-node-1db0ad28b7da-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 21;
    grid-row-start: 21;
    -ms-grid-row-span: 1;
    grid-row-end: 22;
  }
  #w-node-ed9467f52814-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 13;
    grid-row-start: 13;
    -ms-grid-row-span: 1;
    grid-row-end: 14;
  }
  #w-node-134643a3c237-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 15;
    grid-row-start: 15;
    -ms-grid-row-span: 1;
    grid-row-end: 16;
  }
  #w-node-c8cd25072bd6-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 27;
    grid-row-start: 27;
    -ms-grid-row-span: 1;
    grid-row-end: 28;
  }
  #w-node-9b6b3043da92-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 29;
    grid-row-start: 29;
    -ms-grid-row-span: 1;
    grid-row-end: 30;
  }
  #w-node-109b51a09559-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 19;
    grid-row-start: 19;
    -ms-grid-row-span: 1;
    grid-row-end: 20;
  }
  #w-node-102c3e82a8a8-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 19;
    grid-row-start: 19;
    -ms-grid-row-span: 1;
    grid-row-end: 20;
  }
  #w-node-03a659f87b85-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 23;
    grid-row-start: 23;
    -ms-grid-row-span: 1;
    grid-row-end: 24;
  }
  #w-node-ffd5c9d89afe-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 23;
    grid-row-start: 23;
    -ms-grid-row-span: 1;
    grid-row-end: 24;
  }
  #w-node-6d24bf3e8fba-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 21;
    grid-row-start: 21;
    -ms-grid-row-span: 1;
    grid-row-end: 22;
  }
  #w-node-53c6443506d7-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 23;
    grid-row-start: 23;
    -ms-grid-row-span: 1;
    grid-row-end: 24;
  }
  #w-node-d16aeb712dee-3b9be9ed {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 31;
    grid-row-start: 31;
    -ms-grid-row-span: 1;
    grid-row-end: 32;
  }
  #w-node-15aa1d29aae9-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 25;
    grid-row-start: 25;
    -ms-grid-row-span: 1;
    grid-row-end: 26;
  }
  #w-node-4e97da3cc000-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 31;
    grid-row-start: 31;
    -ms-grid-row-span: 1;
    grid-row-end: 32;
  }
  #w-node-d8bb6ca96422-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 25;
    grid-row-start: 25;
    -ms-grid-row-span: 1;
    grid-row-end: 26;
  }
  #w-node-968b2814f38c-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 25;
    grid-row-start: 25;
    -ms-grid-row-span: 1;
    grid-row-end: 26;
  }
  #w-node-4750f5d605d4-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 27;
    grid-row-start: 27;
    -ms-grid-row-span: 1;
    grid-row-end: 28;
  }
  #w-node-2f473e859bd4-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 27;
    grid-row-start: 27;
    -ms-grid-row-span: 1;
    grid-row-end: 28;
  }
  #w-node-2f71611fa878-3b9be9ed {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 29;
    grid-row-start: 29;
    -ms-grid-row-span: 1;
    grid-row-end: 30;
  }
  #w-node-40c1196755d8-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 27;
    grid-row-start: 27;
    -ms-grid-row-span: 1;
    grid-row-end: 28;
  }
  #w-node-8ee5c9ce1523-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 29;
    grid-row-start: 29;
    -ms-grid-row-span: 1;
    grid-row-end: 30;
  }
  #w-node-ea928f0e889b-3b9be9ed {
    -ms-grid-column: 3;
    grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4;
    -ms-grid-row: 31;
    grid-row-start: 31;
    -ms-grid-row-span: 1;
    grid-row-end: 32;
  }
  #w-node-7f8c5f9e0293-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 29;
    grid-row-start: 29;
    -ms-grid-row-span: 1;
    grid-row-end: 30;
  }
  #w-node-22bbda9ba6c7-3b9be9ed {
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-row: 31;
    grid-row-start: 31;
    -ms-grid-row-span: 1;
    grid-row-end: 32;
  }
}

@font-face {
  font-family: 'Fontawesome';
  src: url('./fonts/fontawesome-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNovaT-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Extrabld.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Proximanova';
  src: url('./fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Robotoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesanspro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesansproblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesansprobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesansprolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesansprosemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Sourcesanspro';
  src: url('./fonts/sourcesansproextralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gothamrounded-light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('./fonts/gothamrounded-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gothapro';
  src: url('./fonts/GothaProBol.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gothapro';
  src: url('./fonts/GothaProLig.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gothapro';
  src: url('./fonts/GothaProMed.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gothapro';
  src: url('./fonts/GothaProReg.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gothapro';
  src: url('./fonts/GothaProBla.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}